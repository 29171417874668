import EventEmitter from "eventemitter3";
import ServerSettings from "../config/ServerSettings";
import LoadingScreen from "../menu/screens/LoadingScreen";
import UserScreen from "../menu/screens/UserScreen";
import APIService from "./service/APIService";
import ServiceEvent from "./service/events/ServiceEvent";
import UserService from "./service/user/UserService";
import RoomEvent from "./service/room/events/RoomEvent";
import RoomService from "./service/room/RoomService";
import GameService from "./service/game/GameService";
import RoomState from "./model/RoomState";
import GameModel from "./model/GameModel";
import IORoomScreen from "./interfaces/IORoomScreen";

let _roomService;
let _gameService;
let _userService;
let _offline = false;

/**
 * IO manager for se_games to use
 *
 * @extends {EventEmitter}
 */
export default class IO extends EventEmitter
{
    /**
     *
     * @returns {boolean}
     */
    get offline()
    {
        return _offline;
    }

    /**
     *
     * @param {boolean} val
     */
    set offline(val)
    {
        _offline = val;
    }

    /**
     *
     *
     * @type {GameService}
     */
    get GameService()
    {
        if (!_gameService)
        {
            _gameService = new GameService();
        }

        return _gameService;
    }

    /**
     *
     * @type {RoomService}
     */
    get RoomService()
    {
        if (!_roomService)
        {
            _roomService = new RoomService(ServerSettings.url);
        }

        return _roomService;
    }

    /**
     *
     * @type {UserService}
     */
    get UserService()
    {
        if (!_roomService)
        {
            _userService = new UserService();
        }

        return _userService;
    }

    get RoomEvent()
    {
        return RoomEvent;
    }

    get IORoomScreen()
    {
        return IORoomScreen;
    }

    get ServiceEvent()
    {
        return ServiceEvent;
    }

    get RoomEvent()
    {
        return RoomEvent;
    }

    get RoomState()
    {
        return RoomState;
    }

    get GameModel()
    {
        return GameModel;
    }

    /**
     *
     */
    checkUserExists()
    {
        if (!_offline)
        {
            this.UserService.getExistingUser(se_platform.User._id, this.userFound, this, this.serverError, this.badUser);
        }
    }

    userFound(user)
    {
        console.log("server user found, onboarded = ", user.onboarded); // , user);

        if (se_platform.User.username === "NewUser")
        { se_platform.User.copy(user); }

        se_platform.Navigation.init();

        // user is logged in call setGame (reads current hash or goes to main menu)
        se_platform.Navigation.navigate();
        se_platform.Navigation.navActive = true;

        // restore volume settings
        earthpixi.Audio.muteMusic(se_platform.User.musicMuted);
        // TODO earthpixi.Audio.muteSfx(se_platform.User.sfxMuted);
    }

    userInit(){
        se_platform.Navigation.init();
        // user is logged in call setGame (reads current hash or goes to main menu)
        se_platform.Navigation.navigate();
        se_platform.Navigation.navActive = true;
        // restore volume settings
        earthpixi.Audio.muteMusic(se_platform.User.musicMuted);
    }

    badUser(message)
    {
        console.log("!bad user");
        // se_platform.User.reset();
        // user not logged in so go to user screen
        earthpixi.setScreen(
            new LoadingScreen(UserScreen.assetList, UserScreen)
        );
    }

    serverError(err)
    {
        console.log("server error", err);

        se_platform.IO.offline = true;
        console.log("api test fail, offline");
        // se_platform.User.reset();
        // user not logged in so go to user screen
        earthpixi.setScreen(
            new LoadingScreen(UserScreen.assetList, UserScreen)
        );
    }

    /**
     *
     * @param evt
     */
    onRoomServiceConnect(evt)
    {
        this._roomServiceConnected = true;
        console.log("connected!");
    }

    /**
     *
     * @param evt
     */
    onRoomServiceDisconnect(evt)
    {
        console.log("disconnected!");
    }

    /**
     *
     * @param onSuccess
     * @param onError
     * @param scope
     */
    checkServer(onSuccess, onError, scope)
    {
        if (!_offline)
        { APIService.test(onSuccess, scope, onError); }
    }

    /**
     *
     * @param onSuccess
     * @param onError
     * @param scope
     */
    saveSettings(onSuccess, onError, scope)
    {
        se_platform.LocalStorage.save();
        if (!_offline)
        { this.UserService.updateUserSettings(se_platform.User._id, onSuccess, scope); }
    }

    /**
     *
     * @param newName
     * @param onSuccess
     * @param onError
     * @param scope
     */
    saveUserName(newName, onSuccess, onError, scope)
    {
        se_platform.LocalStorage.save();

        if (!_offline)
        { this.UserService.updateUserName(se_platform.User._id, newName, onSuccess, onError, scope); }
    }
}
