// @ts-ignore
import Text from "../../config/text";
// @ts-ignore
import MessageOverlay from "../../menu_game/MessageOverlay";
import RoomModel from "../model/RoomModel";
/**
 *
 *  Base for a Screen that will use Room service
 *
 */
abstract class IORoomScreen extends earthpixi.Screen
{
    public initIORoomGame(gameId: number): void
    {
        console.log("init io room game", gameId, "here", se_platform.Navigation.query(se_platform.QueryName.ROOM_ID));

        if (se_platform.IO.offline)
        {
            se_platform.Navigation.home(false);

            return;
        }

        se_platform.GameMenu.showRoomServiceDisplay(true);

        const noRoom = () =>
        {
            console.log("no room");
            se_platform.GameMenu.showOnlineGameOptions(gameId);
        };

        if (se_platform.Navigation.query(se_platform.QueryName.ROOM_ID))
        {
            se_platform.IO.RoomService.joinRoom(
                se_platform.Navigation.query(se_platform.QueryName.ROOM_ID),
                gameId,
                null,
                noRoom,
                noRoom,
                this,
            );
        }
        else
        {
            noRoom();
        }
    }

    /**
     * received room deleted form server
     */
    public roomDeleted(username): void
    {
        console.log("game deleted");

        const messageoverlay = new MessageOverlay(
            Text.strings.deletedGame.replace("$user", username),
            () =>
            {
                se_platform.GameMenu.backWarning = false;
                se_platform.GameMenu.onBack();
            },
            this,
            se_platform.GameMenu.dark,
            Text.strings.ok,
        );

        earthpixi.stageOverlay.addChild(messageoverlay);
    }

    /* LIST OF Methods Room Service will try to call on a Network Game Screen during a game*/

    /**
     *
     * @param connected
     */
    public abstract onSocketServiceConnectionChange(connected: boolean): void;
    /**
     *
     * @param connected
     */
    public abstract onRoomServiceConnectionChange(connected: boolean): void;
    /**
     * Received room update from socket,  set the board, turn etc
     */
    public abstract roomJoined(room: RoomModel): void;

    public abstract opponentLeft(playerName: string): void;
    /**
     * Received room update from socket,  set the board, turn etc
     */
    public abstract opponentResigned(playerName: string): void;
    /**
     * Received room update from socket
     */
    public abstract opponentJoined(playerName: string): void;
    /**
     * received room update form server
     */
    public abstract roomUpdated(room: RoomModel, opponentJoined: boolean, opponentUsername: string): void;
    /**
     *
     * @param {string} reason
     */
    public abstract gameEnded(reason: string): void;
}

export default IORoomScreen;
