import Text from "../../config/text";
import UserModel from "../../io/model/UserModel";
import APIService from "../../io/service/APIService";
import UserService from "../../io/service/user/UserService";
import OptionButton from "../onboarding/OptionButton";
import LoadingScreen from "./LoadingScreen";
import MenuScreen from "./MenuScreen";

/**
 * @class
 */
export default class UserScreen extends earthpixi.Screen
{
    private readonly contentCardY: number;

    private _header: PIXI.Container;
    private _ggLogo: PIXI.Sprite;

    private _introContainer: PIXI.Container;
    private _newUserWelcomeContainer: PIXI.Container;
    private _returningUserContainer: PIXI.Container;
    private _returningUserWelcome: PIXI.Container;
    private _userPasswordInput: earthpixi.Input.InputField;
    private _notFoundWarning: PIXI.extras.BitmapText;
    private _backButton: any;
    private _copyButton: PIXI.Sprite;
    private _bgCard: PIXI.Sprite;
    private _cardsMask: PIXI.Sprite | PIXI.Graphics;
    private _blueSlide: PIXI.Sprite;
    private _titleText: PIXI.extras.BitmapText;
    private _yellowCard: PIXI.Sprite;

    constructor()
    {
        super();

        // earthpixi.clearMask();
        earthpixi.setStageColor(0x222133);

        this.contentCardY = 198;

        this.createHeader();
        this.createCards();
        // this.createIntroPanel();
    }

    static get assetList(): any[]
    {
        return [
            // added to global
            // {name: 'menu0', url: earthpixi.config.IMG_ROOT + 'menu/menu0@' + earthpixi.resolution + "x.json"},
            // {name: 'menu1', url: earthpixi.config.IMG_ROOT + 'menu/menu1@' + earthpixi.resolution + "x.json"},
            // {name: 'menu2', url: earthpixi.config.IMG_ROOT + 'menu/menu2@' + earthpixi.resolution + "x.json"},
            // {name: 'menu3', url: earthpixi.config.IMG_ROOT + 'menu/menu3@' + earthpixi.resolution + "x.json"},
        ];
    }

    public run(): void
    {
        earthpixi.showCW = true;
        earthpixi.resizePreFix();
        this.introAnim();
        this.checkServer();

        earthpixi.Access.settings.switchConfig.directMode = false;
        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;

        // default eyegaze on to start
        earthpixi.Access.eyeGaze(true);

        // disable switch for text input
        // earthpixi.Access.switchAccess(earthpixi.Access.settings.switchActive);
        earthpixi.Access.switchAccess(earthpixi.Access.settings.switchActive);
    }

    public checkServer(): void
    {
        APIService.test(this.onServerFound, this, this.onServerError);
    }

    public onServerFound(data: any): void
    {
        this.createIntroPanel();
    }

    public onServerError(res: Response): void
    {
        this.createErrorPanel();
    }

    public createCards()
    {
        // Cards
        this._bgCard = new PIXI.Sprite(PIXI.utils.TextureCache.blue_bg);
        earthpixi.utils.Display.position.center(this._bgCard);
        this._bgCard.x += 10;
        this._bgCard.y = 140;

        this._cardsMask = new PIXI.Sprite(PIXI.utils.TextureCache.card_mask);
        this._cardsMask.x = this._bgCard.x + 21;
        this._cardsMask.y = 155;
        this.addChild(this._cardsMask);

        this._blueSlide = new PIXI.Sprite(PIXI.utils.TextureCache.blue_slide);
        this._blueSlide.x = 500;
        this._blueSlide.y = 140;
        this._blueSlide.mask = this._cardsMask;
        // this.addChild(this._blueSlide);

        this._titleText = new PIXI.extras.BitmapText(Text.strings.hello, { font: "50px baloo", align: "left" });
        this._titleText.position.set(1200, 175);
        this._titleText.skew.x = -0.25;
        this._titleText.mask = this._cardsMask;

        this._yellowCard = new PIXI.Sprite(PIXI.utils.TextureCache.yellow_card);
        this._yellowCard.x = this._bgCard.x + 21;
        this._yellowCard.y = 198;
        this._yellowCard.mask = this._cardsMask;
        // this.addChild(this._yellowCard);

        earthpixi.maskUpdateItems.push(this._cardsMask);

        if (earthpixi.renderer instanceof PIXI.CanvasRenderer)
        {
            const rect = new PIXI.Rectangle(0, 0, this._cardsMask.width, this._cardsMask.height);

            this.removeChild(this._cardsMask);

            this._cardsMask = new PIXI.Graphics();
            this._cardsMask.beginFill(0xFFFFFF, 1);
            this._cardsMask.drawRect(rect.x, rect.y, rect.width, rect.height);
            this._cardsMask.x = this._bgCard.x + 21;
            this._cardsMask.y = 155;
            this._cardsMask.drawRect(rect.x, rect.y, rect.width, rect.height);
            this.addChild(this._cardsMask);

            this._blueSlide.mask = this._cardsMask;
            this._titleText.mask = this._cardsMask;
            this._yellowCard.mask = this._cardsMask;
        }
    }

    private createIntroPanel(): void
    {
        this._introContainer = new PIXI.Container();
        this._yellowCard.addChild(this._introContainer);

        const question = new PIXI.extras.BitmapText(Text.strings.areYouNew, {
            font: "50px baloo",
            tint: 0x222133,
            align: "centre",
        });

        question.position.set(this._yellowCard.width / 2 - question.width / 2, 55);
        question.skew.x = -0.25;

        const cookieStatement = new PIXI.extras.BitmapText(
            Text.strings.cookieStatement
            , { font: "20px concert_one", align: "left", tint: 0x000000 });

        cookieStatement.maxWidth = 700;
        cookieStatement.x = this._yellowCard.width / 2 - cookieStatement.width / 2;
        cookieStatement.y = 140;

        const yesBtn = this.yesButton = new OptionButton(Text.strings.new, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, "Yes Button");
        const yb = yesBtn as any;

        yb.x = this._yellowCard.width / 2 - yb.width / 2 - 180;
        yb.y = 270;
        yb.addEvent("select", this.onNewYes, this);

        const noBtn = this.loginButton = new OptionButton(Text.strings.login, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, "No Button");
        const n = noBtn as any;

        n.x = this._yellowCard.width / 2 - n.width / 2 + 180;
        n.y = 270;
        n.addEvent("select", this.onNewNo, this);

        this._introContainer.addChild(question);
        this._introContainer.addChild(cookieStatement);
        this._introContainer.addChild(yb);
        this._introContainer.addChild(n);
    }

    private createErrorPanel(): void
    {
        if (this._introContainer)
        {
            this._introContainer.destroy({ children: true });
        }

        this._introContainer = new PIXI.Container();
        this._yellowCard.addChild(this._introContainer);

        const question = new PIXI.extras.BitmapText(Text.strings.serverError, {
            font: "35px baloo",
            tint: 0x222133,
            align: "centre",
        });

        question.maxWidth = 500;
        question.position.set(this._yellowCard.width / 2 - question.width / 2, 80);

        const yesBtn = new OptionButton(Text.strings.continue, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, "Yes Button");
        const yb = yesBtn as any;

        yb.x = this._yellowCard.width / 2 - yb.width / 2;
        yb.y = 260;
        yb.addEvent("select", se_platform.Navigation.navigate, se_platform.Navigation);

        this._introContainer.addChild(question);
        this._introContainer.addChild(yb);
    }

    private onNewYes(): void
    {
        this.yesButton.interactive = false;
        se_platform.IO.UserService.createNewUser(this.onUserCreated, this, this.onServerError);
    }

    private onUserCreated(user: UserModel): void
    {
        // new user, so retain current settings and update server id's
        se_platform.User.username = user.username;
        se_platform.User._id = user._id;

        se_platform.LocalStorage.save();

        this._introContainer.visible = false;
        this._backButton.visible = false;

        this._newUserWelcomeContainer = new PIXI.Container();

        let message = new PIXI.extras.BitmapText(Text.strings.helloUser.replace("$username", user.username),
            { font: "40px baloo", align: "center", tint: 0x222133 });

        message.position.set(this._yellowCard.width / 2 - message.width / 2, 50);
        this._newUserWelcomeContainer.addChild(message);

        message = new PIXI.extras.BitmapText(Text.strings.yourSecretWord,
            { font: "35px baloo", align: "center", tint: 0x222133 });
        message.position.set(this._yellowCard.width / 2 - message.width / 2, 120);
        this._newUserWelcomeContainer.addChild(message);

        const secretWord = new PIXI.extras.BitmapText(user._id,
            { font: "40px baloo", align: "center", tint: 0x02513C });

        secretWord.position.set(this._yellowCard.width / 2 - secretWord.width / 2, 180);
        this._newUserWelcomeContainer.addChild(secretWord);

        const info = new PIXI.extras.BitmapText(Text.strings.secretWordInfo,
            { font: "18px montserrat", align: "center", tint: 0x222133 });

        info.position.set(this._yellowCard.width / 2, 250);
        info.anchor = new PIXI.Point(0.5, 0);
        this._newUserWelcomeContainer.addChild(info);

        const startBtn = new OptionButton(Text.strings.start, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, "No Button") as any;

        startBtn.x = this._yellowCard.width / 2 - startBtn.width / 2;
        startBtn.y = 290;
        startBtn.addEvent("select", this.onStart, this);
        this._newUserWelcomeContainer.addChild(startBtn);

        this._copyButton = new PIXI.Sprite(PIXI.utils.TextureCache.copy_clipboard_icon);
        this._copyButton.y = 170;
        this._copyButton.x = 580;
        this._copyButton.interactive = true;
        this._copyButton.buttonMode = true;
        this._copyButton.on("pointerup", this.onCopyToClipboard, this);
        this._newUserWelcomeContainer.addChild(this._copyButton);

        this._yellowCard.addChild(this._newUserWelcomeContainer);
    }

    private onCopyToClipboard()
    {
        earthpixi.utils.CopyToClipboard(se_platform.User._id);

        this._copyButton.alpha = 0.5;
        earthpixi.Tween.kill(this._copyButton);
        earthpixi.Tween.to(this._copyButton, 0.5, { alpha: 1 });
    }

    private onUserFetched(user: UserModel): void
    {
        // console.log("on user fetched", user, se_platform.User);

        se_platform.User.copy(user);
        earthpixi.Access.settings.copy(user.settings);
        se_platform.LocalStorage.save();

        earthpixi.Access.eyeGaze(earthpixi.Access.settings.eyeGazeActive);
        earthpixi.Access.switchAccess(earthpixi.Access.settings.switchActive);

        this._backButton.visible = false;
        this._returningUserContainer.visible = false;

        this._returningUserWelcome = new PIXI.Container();
        this._yellowCard.addChild(this._returningUserWelcome);

        const message = new PIXI.extras.BitmapText(Text.strings.welcomeBackUser.replace("$username", se_platform.User.username),
            { font: "40px baloo", align: "center", tint: 0x222133 });

        message.position.set(this._yellowCard.width / 2 - message.width / 2, 100);
        this._returningUserWelcome.addChild(message);
        // message.skew.x = -0.25;
        message.accessible = true;

        const startBtn = new OptionButton(Text.strings.continue, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, Text.strings.continue) as any;

        startBtn.x = this._yellowCard.width / 2 - startBtn.width / 2;
        startBtn.y = 250;
        startBtn.addEvent("select", se_platform.Navigation.navigate, se_platform.Navigation);
        this._returningUserWelcome.addChild(startBtn);
    }

    private onStart(): void
    {
        earthpixi.setScreen(
            new LoadingScreen(MenuScreen.assetList, MenuScreen),
        );
    }

    private onNewNo(): void
    {
        this._introContainer.visible = false;
        this._backButton.visible = true;

        this.loginButton.interactive = false;

        if (!this._returningUserContainer)
        {
            this._returningUserContainer = new PIXI.Container();
            this._yellowCard.addChild(this._returningUserContainer);

            const message = new PIXI.extras.BitmapText(Text.strings.enterPassword,
                { font: "35px baloo", align: "center", tint: 0x222133 });

            message.position.set(this._yellowCard.width / 2 - message.width / 2, 110);
            this._returningUserContainer.addChild(message);

            // add input ui
            earthpixi.Access.switchAccess(false);

            const input = earthpixi.Input.createInput({ font: "36px baloo", align: "centre" }, "",
                0x222133, 400, false, false, true);

            input.position.set(this._yellowCard.width / 2 - 200, 170);
            this._returningUserContainer.addChild(input);

            earthpixi.Input.onSubmit = this.onPasswordSubmit.bind(this);

            earthpixi.Input.activateInput(input);

            const submitBtn = this.submitButton = new OptionButton(Text.strings.submit, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, "No Button");
            const sb = submitBtn as any;

            sb.x = this._yellowCard.width / 2 - sb.width / 2;
            sb.y = 260;
            sb.addEvent("select", this.onPasswordSubmit, this);
            this._returningUserContainer.addChild(sb);

            this._notFoundWarning = new PIXI.extras.BitmapText(Text.strings.userNotFound, {
                font: "36px baloo", align: "centre",
                tint: 0xff0000,
            });
            this._notFoundWarning.position.set(this._yellowCard.width / 2 - this._notFoundWarning.width / 2, 70);
            this._returningUserContainer.addChild(this._notFoundWarning);
            this._notFoundWarning.visible = false;

            this._userPasswordInput = input;
        }
        else
        {
            // this._userPasswordInput.text = Text.strings.enterPassword;
            this._returningUserContainer.visible = true;
            this._notFoundWarning.visible = false;
        }
    }

    private onPasswordSubmit(): void
    {
        if (this._userPasswordInput.text === " ")
        {
            this.onUserNotFound();

            return;
        }

        this.submitButton.interactive = false;

        se_platform.IO.UserService.getExistingUser(this._userPasswordInput.text, this.onUserFetched, this, this.onUserFetchError, this.onUserNotFound);
    }

    private onUserNotFound(): void
    {
        this._notFoundWarning.visible = true;
        this.submitButton.interactive = true;
    }

    private onUserFetchError(response: Response): void
    {
        // console.log(response);
        this.onServerError(response);
    }

    private createHeader(): void
    {
        const _header = this._header = new PIXI.Container();

        _header.x = 10;
        const seLogo = new PIXI.Sprite(PIXI.utils.TextureCache.se_logo);

        seLogo.x = 260;
        seLogo.y = 10;
        seLogo.scale.set(0.6);
        seLogo.buttonMode = true;
        seLogo.interactive = true;
        seLogo.on("pointerup", this.goToSEWebsite);

        _header.addChild(seLogo);

        const ggLogo = this._ggLogo = new PIXI.Sprite(PIXI.utils.TextureCache.ggr_logo);

        ggLogo.x = 290;
        ggLogo.y = 50;
        ggLogo.scale.set(1);
        _header.addChild(ggLogo);

        this._backButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.back_button, PIXI.utils.TextureCache.back_button, "Back", false, false, 20);
        this._backButton.x = 1000;
        this._backButton.y = 60;
        this._backButton.visible = false;
        this._backButton.addEvent("select", this.onBack, this);
        this._header.addChild(this._backButton);

        this.addChild(_header);
    }

    private onBack(): void
    {
        this._returningUserContainer.visible = false;
        this._introContainer.visible = true;
        this._backButton.visible = false;
        this.loginButton.interactive = true;
        earthpixi.Access.switchAccess(true);
    }

    private introAnim(): void
    {
        this._bgCard.alpha = 0;
        earthpixi.Tween.to(this._bgCard, 1, { alpha: 1 });
        this.addChild(this._bgCard);

        this._header.y = -200;
        earthpixi.Tween.to(this._header, 1, { y: 0, ease: Expo.easeOut });
        this.addChild(this._header);

        this._blueSlide.x = 1024;
        earthpixi.Tween.to(this._blueSlide, 1, { x: 600, ease: Expo.easeOut });
        this.addChild(this._blueSlide);

        this._yellowCard.y = 700;
        this._yellowCard.visible = true;
        this._cardsMask.visible = true;
        this._yellowCard.mask = this._cardsMask;

        earthpixi.Tween.to(this._yellowCard, 1, {
            y: this.contentCardY, delay: 0, ease: Expo.easeInOut,
            onComplete: this.unmask, onCompleteParams: [[this._yellowCard], true], onCompleteScope: this,
        });

        const txtX = 1100 - Math.floor(this._titleText.width);

        earthpixi.Tween.to(this._titleText, 1, {
            x: txtX,
            delay: 0.2, ease: Expo.easeInOut,
        });

        this.addChild(this._titleText);
        this.addChild(this._yellowCard);
    }

    private unmask(objects: PIXI.Sprite[], remainVisible: boolean): void
    {
        for (let i = 0; i < objects.length; i++)
        {
            objects[i].mask = null;
            objects[i].visible = remainVisible;
        }
    }

    private goToSEWebsite(): void
    {
        // window.top.open("http://www.specialeffect.org.uk/", "blank");
    }
}
