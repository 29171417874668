export default class LevelProgress
{
    /**
     * @type {string}
     */
    public id: string = "";
    /**
     * @type {number}
     */
    public level: number = 0;
    /**
     * @type {number}
     */
    public score: number = 0;
    /**
     * @type {number}
     */
    public highScore: number = 0;
    /**
     * @type {number}
     */
    public highScores: number[] = [];
    /**
     * @type {number}
     */
    public completed: boolean = false;
    /**
     * @type {number}
     */
    public zone: number = 0;

    /**
     * @type {string}
     */
    public state: string = "";

    /**
     *
     * @param {string} id
     * @param {number} level
     * @param {number} zone
     * @param {number} score
     * @param {number} highScore
     * @param {number[]} highScores
     * @param {boolean} completed
     * @param {string} state
     */
    constructor(id: string, level: number, zone: number, score: number, highScore: number, highScores: number[], completed: boolean, state: string)
    {
        this.id = id;
        this.level = level;
        this.zone = zone;
        this.score = score;
        this.highScore = highScore;
        this.highScores = highScores;
        this.completed = completed;
        this.state = state;
    }

    public toJSON()
    {
        return { ...this };
    }
}
