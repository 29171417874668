import Text from "../../config/text";

export default class InfoBox extends earthpixi.GameObject
{
    constructor(screen)
    {
        super(screen);

        // let spr = new PIXI.Sprite(PIXI.Texture.fromImage(earthpixi.config.FW_ROOT + "loading/Default@" + earthpixi.resolution + "x.png"));
        // this.addChild(spr);

        const settingsLinkTex = earthpixi.utils.Draw.DrawRoundedRect({
            x: 0,
            y: 0,
            width: 90,
            height: 25
        }, 10, "#28ace3").texture;
        const settingsLink = new earthpixi.Access.Button(settingsLinkTex, settingsLinkTex, Text.strings.settings, false, false, 20);

        settingsLink.alpha = 0.8;
        settingsLink.x = 671;
        settingsLink.y = 243;
        settingsLink.alpha = 0.7;
        settingsLink.buttonMode = true;
        settingsLink.interactive = true;
        settingsLink.addEvent("select", this.onSettings, this);

        const emailLinkTex = earthpixi.utils.Draw.DrawRoundedRect({
            x: 0,
            y: 0,
            width: 269,
            height: 25
        }, 10, "#28ace3").texture;
        const emailLink = new earthpixi.Access.Button(emailLinkTex, emailLinkTex, Text.strings.email, false, false, 20);

        emailLink.alpha = 0.8;
        emailLink.x = 550;
        emailLink.y = 505;
        emailLink.alpha = 0.7;
        emailLink.buttonMode = true;
        emailLink.interactive = true;
        emailLink.addEvent("select", this.onEmail, this);

        const feedbackLinkTex = earthpixi.utils.Draw.DrawRoundedRect({
            x: 0,
            y: 0,
            width: 100,
            height: 25
        }, 10, "#28ace3").texture;
        const feedbackLink = new earthpixi.Access.Button(feedbackLinkTex, feedbackLinkTex, Text.strings.feedback, false, false, 20);

        feedbackLink.alpha = 0.8;
        feedbackLink.x = 600;
        feedbackLink.y = 406;
        feedbackLink.alpha = 0.7;
        feedbackLink.buttonMode = true;
        feedbackLink.interactive = true;
        feedbackLink.addEvent("select", this.onFeedback, this);

        const photo = PIXI.Sprite.fromImage(`${earthpixi.config.IMG_ROOT}menu/jameshoward.jpg`);

        photo.scale.set(0.4);
        photo.position.set(950, 320);

        // this.addChild(settingsLink);
        // this.addChild(emailLink);
        // this.addChild(feedbackLink);
        this.addChild(photo);

        let txt = new PIXI.extras.BitmapText(
            "Welcome to the site!\n"
            + "Our free and accessible web games are designed to be playable with just your eyes, using your eye gaze camera and its mouse control software."
            , { font: "23px concert_one", align: "center" });

        txt.maxWidth = 750;
        this.addChild(txt);
        earthpixi.utils.Display.position.center(txt);
        txt.y = 170;

        txt = new PIXI.extras.BitmapText(
            "Eye Gaze Games is dedicated to the memory of Google Executive James Howard, a passionate supporter and the inspiration for this global project. Our heartfelt thanks go to his devoted family and friends whose support was invaluable in laying its foundations.\n"
            + "\nDr Mick Donegan, Founder & CEO SpecialEffect"
            , { font: "20px concert_one", align: "left" });

        txt.maxWidth = 620;
        this.addChild(txt);
        // earthpixi.utils.Display.position.center(txt);
        txt.x = 320;
        txt.y = 320;

        txt = new PIXI.extras.BitmapText(
            "Photosensitive seizure warning:"
            , { font: "22px concert_one", align: "center", tint: 0xF6FF00 });

        this.addChild(txt);
        // earthpixi.utils.Display.position.center(txt);
        txt.x = 320;
        txt.y = 490;

        txt = new PIXI.extras.BitmapText(
            "A very small percentage of people may experience a seizure when exposed to certain visual images, including flashing lights or patterns that may appear in video games. Even people who have no history of seizures or epilepsy may have an undiagnosed condition that can cause these “photosensitive epileptic seizures” while  playing video games.\n\n"
            + "Immediately stop playing and consult a doctor if you experience any symptoms."
            , { font: "16px montserrat", align: "left" });

        txt.maxWidth = 760;
        this.addChild(txt);
        // earthpixi.utils.Display.position.center(txt);
        txt.x = 320;
        txt.y = 530;

        // settingsLink.beginFill(0xFFFFFF,0.5);
        // settingsLink.drawRect(0,0,110,30);
    }

    onSettings(evt)
    {
        // this.screen.onBack();
        this.screen.onSettings();
    }

    onEmail(evt)
    {
        // window.top.open("mailto:info@specialeffect.org.uk", "emailWindow");
    }

    onFeedback()
    {
        // this.screen.onBack();
        this.screen.showFeedBack(false);
    }
}
