export default class PageDots extends earthpixi.GameObject
{
    constructor(screen, numDots)
    {
        super(screen);

        const dotGrp = new PIXI.Graphics();

        dotGrp.beginFill(0xFFFFFF, 1);
        dotGrp.drawCircle(0, 0, 5);

        const dotTex = earthpixi.renderer.generateTexture(dotGrp, PIXI.settings.SCALE_MODE, earthpixi.resolution);

        this.dots = [];

        for (let i = 0; i < numDots; i++)
        {
            const dot = new PIXI.Sprite(dotTex);

            dot.alpha = 0.5;
            dot.position.set(i * 20, 0);
            this.addChild(dot);
            this.dots.push(dot);
        }

        this.setDot(0);
    }

    setDot(index)
    {
        for (let i = 0; i < this.dots.length; i++)
        {
            this.dots[i].alpha = 0.5;
        }
        this.dots[index].alpha = 1;
    }
}
