import ServerSettings from "../../config/ServerSettings";
export default class APIService
{
    public static fetchResponded(res: Response, callback: Function, callbackContext: any, errorCallback?: any, notFoundCallBack?: any): void
    {
        console.log('fetch responded: ', res)
        
        res.json().then((data) =>
        {
            console.log('fetchResponded().then -> data is: ', data)
            
            if (data.notFound && notFoundCallBack)
            {
                console.log('calling notfound callback')
                notFoundCallBack.apply(callbackContext, []);

                return;
            }
            callback.apply(callbackContext, [data]);
        }).catch((err) =>
        {
            console.log('error fetching: ', err)
            console.log('keys: ', Object.keys(err))
            console.log(err);

            if (errorCallback)
            {
                errorCallback.apply(callbackContext, [res]);
            }
        });

    }

    public static fetchRejected(err: Error, errorCallback?: any, callbackContext?: any): void
    {
        console.log('fetch rejected!')
        console.error(err);

        se_platform.IO.offline = true;

        console.log("offline");

        if (errorCallback)
        {
            if (callbackContext)
            {
                errorCallback.apply(callbackContext, [err]);
            }
            else
            {
                errorCallback(err);
            }
        }
        else
        {
            throw Error(err.message);
        }
    }

    public static test(callback: (data: any) => void, callbackContext?: any, error?: (response: Response) => void): void
    {
        fetch(`${ServerSettings.url}user/abcdefg`, {
            method: "GET",
        })
            .then((res) =>
            {
                if (callbackContext)
                {
                    callback.apply(callbackContext, [res]);
                }
                else
                {
                    callback(res);
                }
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    protected static fetchErrorCheck(response: Response): Response
    {
        if (!response.ok)
        {
            throw Error(response.statusText);
        }

        return response;
    }

    public fetches: any = {};

    public fetchAPI(url: string, opts: any): Promise<any>
    {
        if (this.fetches[url + opts.method])
        {
            this.fetches[url + opts.method].abort();
        }

        const fetchReq = this.abortableFetch(url, opts);

        this.fetches[url + opts.method] = fetchReq;

        return fetchReq.ready;
    }

    public abortableFetch(url:string, opts:any)
    {
        if (window.AbortController)
        {
            console.log('opts: ', opts)
            const controller = new AbortController();
            const signal = controller.signal;

            return {
                abort: () => controller.abort(),
                ready: fetch(url, { ...opts, signal }),
            };
        }

        return {
            abort: () => {},
            ready: fetch(url, { ...opts }),
        };
    }
}
