/**
 * Created by earth on 21/11/2017.
 */

import Text from "../../config/text";
import OptionButton from "./OptionButton";

export default class OnBoardingOverlay extends earthpixi.GameObject
{
    constructor()
    {
        super();

        // earthpixi.Access.resetSettings();
        earthpixi.Access.settings.switchConfig.switchMode = 2;
        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;
        earthpixi.Access.settings.switchConfig.scanTime = 2;

        earthpixi.Access.eyeGaze(true);
        earthpixi.Access.switchAccess(true);

        this.cover = new PIXI.Graphics();
        this.cover.beginFill(0x000000, 0.7);
        this.cover.drawRect(0, 0, 100, 100);
        this.cover.interactive = true;
        this.cover.alpha = 0;
        this.cover.width = earthpixi.config.STAGE_WIDTH;
        this.cover.height = earthpixi.config.STAGE_HEIGHT;

        this.addChild(this.cover);

        this.blueBox = new PIXI.Sprite(PIXI.utils.TextureCache.blue_bg);
        this.blueBox.alpha = 0;
        earthpixi.utils.Display.position.center(this.blueBox);
        this.blueBox.x += 10;
        this.blueBox.y = 140;
        this.addChild(this.blueBox);

        this.fadeIn();

        this.introTextOptions = { font: "22px montserrat", tint: 0xFFFFFF, align: "center" };
        this.titleTextOptions = { font: "22px montserrat", tint: 0xFFFFFF, align: "left" };
        this.titleTextOptionsCenter = { font: "25px montserrat", tint: 0xFFFFFF, align: "center" };
        this.optionTextOptions = { font: "40px concert_one", tint: 0xFFFFFF, align: "left" };
    }

    fadeIn()
    {
        earthpixi.Tween.to([this.cover, this.blueBox], 0.5, {
            delay: 1,
            alpha: 1,
            onCompleteScope: this,
            onComplete: this.start
        });
    }

    start()
    {
        const setupTxt = new PIXI.extras.BitmapText(Text.strings.ob_setup, {
            font: "40px concert_one",
            tint: 0xFFFFFF,
            align: "left"
        });

        se_platform.fitInRect(setupTxt, 310, 50, 770, 120);
        this.addChild(setupTxt);

        this.skipButton = new PIXI.extras.BitmapText(Text.strings.ob_skip, {
            font: "40px concert_one",
            tint: 0xFFFFFF,
            align: "left"
        });
        this.skipTex = earthpixi.renderer.generateTexture(this.skipButton, PIXI.settings.SCALE_MODE, earthpixi.resolution);

        this.skipButton = new earthpixi.Access.Button(this.skipTex, this.skipTex, Text.strings.ob_skip);
        // this.skipButton.customDwellTime = 2;
        this.skipButton.switchable = false;
        this.skipButton.addEvent("select", this.onSkip, this);
        this.skipButton.position.set(1010, 100);
        this.addChild(this.skipButton);

        this.step1();
        // this.selectDwell();

        // this.selectSwitch();
    }

    step1()
    {
        this.hideCurrentSlide();

        // screen 1
        const slide = this.createSlide();

        slide.y = 150;

        let txt = new PIXI.extras.BitmapText(Text.strings.ob_intro, this.introTextOptions);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 310, 45, 750, 320, true);
        slide.addChild(txt);

        const settingsButton = this.createButton(Text.strings.ob_settings);

        settingsButton.eyeGazeAble = true;
        settingsButton.switchable = true;
        settingsButton.scale.set(0.9);
        settingsButton.position.set(320, 230);
        settingsButton.addEvent("select", this.step2, this);
        slide.addChild(settingsButton);

        txt = new PIXI.extras.BitmapText(
            "Photosensitive seizure warning:"
            , { font: "22px concert_one", align: "center", tint: 0xF6FF00 });

        txt.maxWidth = 620;
        slide.addChild(txt);
        // earthpixi.utils.Display.position.center(txt);
        txt.x = 530;
        txt.y = 240;

        txt = new PIXI.extras.BitmapText(
            "A very small percentage of people may experience a seizure when exposed to certain visual images, including flashing lights or patterns that may appear in video games. Even people who have no history of seizures or epilepsy may have an undiagnosed condition that can cause these “photosensitive epileptic seizures”  while playing video games.\n\n"
            + "Immediately stop playing and consult a doctor if you experience any symptoms."
            , { font: "16px montserrat", align: "left" });

        txt.maxWidth = 540;
        slide.addChild(txt);
        // earthpixi.utils.Display.position.center(txt);
        txt.x = 530;
        txt.y = 300;

        earthpixi.Access.soloButtons([settingsButton, this.skipButton]);
    }

    step2()
    {
        this.hideCurrentSlide();

        const slide = this.createSlide();

        slide.y = 150;

        let txt = new PIXI.extras.BitmapText(Text.strings.ob_newUser, this.titleTextOptions);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 280, 25, 750, 100, true);
        slide.addChild(txt);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseControl, this.optionTextOptions);
        se_platform.fitInRect(txt, 300, 90, 750, 100);
        slide.addChild(txt);

        const mouseButton = this.createButton(Text.strings.ob_Option1);

        mouseButton.eyeGazeAble = false;
        mouseButton.switchable = false;
        mouseButton.scale.set(0.9);
        mouseButton.position.set(330, 180);
        mouseButton.addEvent("select", this.selectMouse, this);
        slide.addChild(mouseButton);

        const touchButton = this.createButton(Text.strings.ob_Option2);

        touchButton.eyeGazeAble = false;
        touchButton.switchable = false;
        touchButton.scale.set(0.9);
        touchButton.position.set(330, 350);
        touchButton.addEvent("select", this.selectTouch, this);
        slide.addChild(touchButton);

        const eGButton = this.createButton(Text.strings.ob_Option3);

        eGButton.position.set(590, 260);
        // eGButton.scale.set(0.9);
        eGButton.switchable = false;
        eGButton.addEvent("select", this.selectEyeGaze, this);
        slide.addChild(eGButton);

        const switchButton = this.createButton(Text.strings.ob_Option4);

        switchButton.eyeGazeAble = false;
        // switchButton.scale.set(0.9);
        switchButton.position.set(880, 260);
        switchButton.addEvent("select", this.selectSwitch, this);
        slide.addChild(switchButton);

        earthpixi.Access.soloButtons([eGButton, switchButton, this.skipButton]);
    }

    createSlide()
    {
        // console.log("new slide");
        // screen 1
        this.slide = new PIXI.Container();
        this.slide.y = 150;
        this.slide.alpha = 0;
        this.slide.interactiveChildren = false;
        earthpixi.Tween.to(this.slide, 0.5, { alpha: 1, delay: 0.5, onComplete: () =>
        {
            this.slide.interactiveChildren = true;
        } });
        this.addChild(this.slide);

        return this.slide;
    }

    createButton(title)
    {
        return new OptionButton(title, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, title);
    }

    onSkip(evt)
    {
        if (evt) evt.stopPropagation();

        // earthpixi.Access.settings.switchActive = true;
        // earthpixi.Access.settings.eyeGazeActive = true;
        //
        // earthpixi.Access.eyeGaze(true);
        // earthpixi.Access.switchAccess(true);
        // this.save();

        earthpixi.Access.settings.eyeGazeActive = false;
        earthpixi.Access.settings.switchActive = false;

        se_platform.User.onboarded = true;
        se_platform.IO.saveSettings();

        se_platform.Navigation.navigate();

        // this.closeOB();
    }

    selectMouse(evt)
    {
        evt.stopPropagation();

        earthpixi.Access.eyeGaze(false);
        earthpixi.Access.switchAccess(false);
        this.save();

        this.onFinished();
    }

    selectTouch(evt)
    {
        evt.stopPropagation();

        earthpixi.Access.eyeGaze(true);
        earthpixi.Access.settings.eyeGazeConfig.dwellClickActive = false;

        earthpixi.Access.switchAccess(false);
        this.save();

        this.onFinished();
    }

    selectEyeGaze(evt)
    {
        evt.stopPropagation();

        earthpixi.Access.eyeGaze(true);
        earthpixi.Access.switchAccess(false);
        this.save();

        this.hideCurrentSlide();

        const slide = this.createSlide();

        let txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseEGOption1, this.titleTextOptions);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 300, 0, 750, 230);
        slide.addChild(txt);

        const dwellOnButton = this.createButton(Text.strings.ob_EGOption1);

        dwellOnButton.position.set(400, 230);
        dwellOnButton.addEvent("select", this.showDwellWarning, this);
        slide.addChild(dwellOnButton);

        const dwellOffButton = this.createButton(Text.strings.ob_EGOption2);

        dwellOffButton.position.set(750, 230);
        dwellOffButton.eyeGazeAble = false;
        dwellOffButton.addEvent("select", this.selectDwellOff, this);
        slide.addChild(dwellOffButton);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_EGOption2a, this.titleTextOptions);
        // txt.maxWidth = 750;
        se_platform.fitInRect(txt, 120, 420, 750, 100);
        slide.addChild(txt);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_EGOption2b, this.titleTextOptions);
        txt.maxWidth = 300;
        se_platform.fitInRect(txt, 480, 420, 750, 100);
        slide.addChild(txt);

        earthpixi.Access.soloButtons([dwellOnButton, dwellOffButton, this.skipButton]);
    }

    selectSwitch()
    {
        // console.log("select switch");

        earthpixi.Access.eyeGaze(false);
        earthpixi.Access.settings.eyeGazeConfig.dwellClickActive = true;
        this.save();

        this.hideCurrentSlide();

        const slide = this.createSlide();

        let txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseSwitchOption, this.titleTextOptions);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 310, 20, 750, 50);
        slide.addChild(txt);

        const switch1Button = this.switch1Button = this.createButton(Text.strings.ob_switchOption1);

        switch1Button.togglable = true;
        switch1Button.scale.set(0.8);
        switch1Button.toggle();
        switch1Button.position.set(420, 80);

        switch1Button.addEvent("select", this.select1Switch, this);
        slide.addChild(switch1Button);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_switchOption1b, this.titleTextOptions);
        txt.maxWidth = 350;
        se_platform.fitInRect(txt, 390, 170, 250, 220);
        txt.y = 240;
        slide.addChild(txt);

        const switch2Button = this.switch2Button = this.createButton(Text.strings.ob_switchOption2);

        switch2Button.togglable = true;
        switch2Button.position.set(760, 80);
        switch2Button.scale.set(0.8);
        switch2Button.addEvent("select", this.select2Switch, this);
        slide.addChild(switch2Button);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_switchOption2b, this.titleTextOptions);
        txt.maxWidth = 350;
        se_platform.fitInRect(txt, 750, 170, 250, 220);
        txt.y = 240;
        slide.addChild(txt);

        // scan time options

        this.scanTimeContainer = new PIXI.Container();

        this.scanTimeContainer.position.set(-50, 280);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_switchOption3, this.titleTextOptions);
        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 200, 40, 750, 100);
        this.scanTimeContainer.addChild(txt);

        const dwellMinus = new earthpixi.Access.Button(PIXI.utils.TextureCache.minus_up, PIXI.utils.TextureCache.minus_down, Text.strings.ob_decrease, true);

        dwellMinus.scale.set(0.7);
        dwellMinus.addEvent("select", this.onScanTimeDown, this);
        dwellMinus.position.set(350, 110);
        this.scanTimeContainer.addChild(dwellMinus);

        const dwellPlus = new earthpixi.Access.Button(PIXI.utils.TextureCache.plus_up, PIXI.utils.TextureCache.plus_down, Text.strings.ob_increase, true);

        dwellPlus.position.set(650, 110);
        dwellPlus.scale.set(0.7);
        dwellPlus.addEvent("select", this.onScanTimeUp, this);
        this.scanTimeContainer.addChild(dwellPlus);

        const numTextOptions = { font: "80px baloo", tint: 0xFFFFFF, align: "left" };
        const dwellTimeText = this.dwellTimeText = new PIXI.extras.BitmapText(`${earthpixi.Access.settings.switchConfig.scanTime.toString()}s`, numTextOptions);

        this.scanTimeContainer.addChild(dwellTimeText);
        dwellTimeText.tint = 0x222133;
        // levelNum.position.set(150,10);
        se_platform.fitInRect(dwellTimeText, 520, 100, 100, 80);

        slide.addChild(this.scanTimeContainer);

        const playButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.play_up, PIXI.utils.TextureCache.play_down, Text.strings.ob_next);

        playButton.position.set(910, 340);
        playButton.addEvent("select", this.onFinished, this);
        slide.addChild(playButton);

        earthpixi.Access.soloButtons([this.skipButton, switch1Button, switch2Button, dwellPlus, dwellMinus, playButton]);
    }

    select1Switch()
    {
        earthpixi.Access.resetSwitchScanning();
        earthpixi.Access.settings.switchConfig.switchMode = 1;

        if (this.switch2Button.toggled)
        {
            this.switch2Button.toggle();
        }

        if (!this.switch1Button.toggled)
        {
            this.switch1Button.toggle();
        }

        // earthpixi.Access.resetSwitchScanning();

        this.scanTimeContainer.alpha = 1;
        this.scanTimeContainer.interactiveChildren = true;
    }

    select2Switch()
    {
        // console.log("select 2");

        earthpixi.Access.resetSwitchScanning();
        earthpixi.Access.settings.switchConfig.switchMode = 2;

        if (this.switch1Button.toggled)
        {
            this.switch1Button.toggle();
        }

        if (!this.switch2Button.toggled)
        {
            this.switch2Button.toggle();
        }

        this.scanTimeContainer.alpha = 0.5;
        this.scanTimeContainer.interactiveChildren = false;
    }

    selectDwell()
    {
        earthpixi.Access.settings.eyeGazeConfig.dwellClickActive = true;
        earthpixi.Access.switchAccess(false);
        this.save();

        this.hideCurrentSlide();
        const slide = this.createSlide();

        let txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseEGOption2, this.titleTextOptions);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 200, 10, 750, 100);
        slide.addChild(txt);

        const dwellMinus = new earthpixi.Access.Button(PIXI.utils.TextureCache.minus_up, PIXI.utils.TextureCache.minus_down, Text.strings.ob_decrease, true);

        dwellMinus.setScale(0.8);
        dwellMinus.addEvent("select", this.onDwellTimeDown, this);
        dwellMinus.position.set(350, 100);
        slide.addChild(dwellMinus);

        const dwellPlus = new earthpixi.Access.Button(PIXI.utils.TextureCache.plus_up, PIXI.utils.TextureCache.plus_down, Text.strings.ob_increase, true);

        dwellPlus.position.set(650, 100);
        dwellPlus.setScale(0.8);
        dwellPlus.addEvent("select", this.onDwellTimeUp, this);
        slide.addChild(dwellPlus);

        const numTextOptions = { font: "80px baloo", tint: 0xFFFFFF, align: "left" };
        const dwellTimeText = this.dwellTimeText = new PIXI.extras.BitmapText(`${earthpixi.Access.settings.eyeGazeConfig.dwellTime.toString()}s`, numTextOptions);

        slide.addChild(dwellTimeText);
        dwellTimeText.tint = 0x222133;
        // levelNum.position.set(150,10);
        se_platform.fitInRect(dwellTimeText, 520, 100, 100, 80);

        // dwel anim options

        txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseEGOption3, this.titleTextOptions);
        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 200, 250, 750, 100);
        slide.addChild(txt);

        const pieButton = this.pieButton = this.createButton(Text.strings.ob_chooseEGOptionPie);

        pieButton.togglable = true;
        // pieButton.repeat = true;
        pieButton.customDwellAnim = earthpixi.Access._egPieClass;
        pieButton.position.set(350, 350);
        pieButton.off("over", pieButton.onOver);
        pieButton.off("out", pieButton.onOut);
        pieButton.addEvent("select", this.setPie, this);
        pieButton.addEvent("toggleOff", this.setShrink, this);
        pieButton.scale.set(0.8, 0.8);
        slide.addChild(pieButton);

        const shrinkButton = this.shrinkButton = this.createButton(Text.strings.ob_chooseEGOptionShrink);

        shrinkButton.togglable = true;
        // shrinkButton.repeat = true;
        shrinkButton.customDwellAnim = earthpixi.Access._egTargetClass;
        shrinkButton.position.set(650, 350);
        shrinkButton.off("over", shrinkButton.onOver);
        shrinkButton.off("out", shrinkButton.onOut);
        shrinkButton.addEvent("select", this.setShrink, this);
        shrinkButton.addEvent("toggleOff", this.setPie, this);
        shrinkButton.scale.set(0.8, 0.8);
        slide.addChild(shrinkButton);

        const playButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.play_up, PIXI.utils.TextureCache.play_down, Text.strings.ob_next);

        playButton.position.set(910, 340);
        playButton.addEvent("select", this.selectEyeGazeMode, this);
        slide.addChild(playButton);

        earthpixi.Access.soloButtons([this.skipButton, pieButton, shrinkButton, dwellMinus, dwellPlus, playButton]);
    }

    selectEyeGazeMode()
    {
        this.save();
        this.hideCurrentSlide();
        const slide = this.createSlide();

        let txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseEGOption4, this.titleTextOptionsCenter);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 300, 20, 750, 50);
        slide.addChild(txt);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_EGOption3, this.titleTextOptionsCenter);
        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 300, 60, 750, 130);
        slide.addChild(txt);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_EGOption4, this.titleTextOptionsCenter);
        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 300, 150, 750, 150);
        slide.addChild(txt);

        const nModeButton = this.createButton(Text.strings.bigButtons_normal);

        nModeButton.position.set(430, 320);
        nModeButton.addEvent("select", this.selectNormalEGMode, this);
        slide.addChild(nModeButton);

        const dModeButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.target_icon, PIXI.utils.TextureCache.target_icon, "Back", false, false, 20);

        dModeButton.position.set(840, 370);
        dModeButton.addEvent("select", this.selectDirectEGMode, this);
        slide.addChild(dModeButton);

        txt = new PIXI.extras.BitmapText(Text.strings.ob_Precise, this.optionTextOptions);
        txt.position.set(810, 440);
        slide.addChild(txt);

        earthpixi.Access.soloButtons([nModeButton, dModeButton, this.skipButton]);
    }

    selectNormalEGMode()
    {
        earthpixi.Access.settings.eyeGazeConfig.directMode = false;
        this.save();

        // this.showDwellWarning();
        this.onFinished();
    }

    showDwellWarning()
    {
        this.hideCurrentSlide();
        const slide = this.createSlide();

        const txt = new PIXI.extras.BitmapText(Text.strings.ob_chooseEGOption3_b, this.titleTextOptionsCenter);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 300, 60, 750, 50);
        slide.addChild(txt);

        const nModeButton = this.createButton(Text.strings.ob_Continue);

        nModeButton.position.set(580, 150);
        nModeButton.eyeGazeAble = true;
        nModeButton.addEvent("select", this.selectDwell, this);
        slide.addChild(nModeButton);

        earthpixi.Access.soloButtons([nModeButton, this.skipButton]);
    }

    selectDirectEGMode()
    {
        earthpixi.Access.settings.eyeGazeConfig.directMode = true;
        this.save();
        this.onFinished();
    }

    setPie(evt)
    {
        if (this.shrinkButton.toggled)
        {
            this.shrinkButton.toggle(false);
        }
        this.pieButton.toggle(true);
        earthpixi.Access.settings.eyeGazeConfig.anim = "pie";
    }

    setShrink(evt)
    {
        if (this.pieButton.toggled)
        {
            this.pieButton.toggle(false);
        }
        this.shrinkButton.toggle(true);
        earthpixi.Access.settings.eyeGazeConfig.anim = "default";
    }

    selectDwellOff()
    {
        earthpixi.Access.settings.eyeGazeConfig.dwellClickActive = false;
        this.save();
        this.onFinished();
    }

    onDwellTimeUp()
    {
        if (earthpixi.Access.settings.eyeGazeConfig.dwellTime < 10)
        {
            const newVal = earthpixi.Access.settings.eyeGazeConfig.dwellTime + 0.1;
            const multiplier = Math.pow(10, 1 || 0);

            earthpixi.Access.settings.eyeGazeConfig.dwellTime = Math.round(newVal * multiplier) / multiplier;
        }

        this.dwellTimeText.text = `${earthpixi.Access.settings.eyeGazeConfig.dwellTime.toString()}s`;
        se_platform.fitInRect(this.dwellTimeText, 520, 100, 100, 100);
    }

    onDwellTimeDown()
    {
        if (earthpixi.Access.settings.eyeGazeConfig.dwellTime > 0.5)
        {
            const newVal = earthpixi.Access.settings.eyeGazeConfig.dwellTime - 0.1;
            const multiplier = Math.pow(10, 1 || 0);

            earthpixi.Access.settings.eyeGazeConfig.dwellTime = Math.round(newVal * multiplier) / multiplier;
        }

        this.dwellTimeText.text = `${earthpixi.Access.settings.eyeGazeConfig.dwellTime.toString()}s`;
        se_platform.fitInRect(this.dwellTimeText, 520, 100, 100, 100);
    }

    onScanTimeUp()
    {
        if (earthpixi.Access.settings.switchConfig.scanTime < 10)
        {
            const newVal = earthpixi.Access.settings.switchConfig.scanTime + 0.1;
            const multiplier = Math.pow(10, 1 || 0);

            earthpixi.Access.settings.switchConfig.scanTime = Math.round(newVal * multiplier) / multiplier;
        }

        this.dwellTimeText.text = `${earthpixi.Access.settings.switchConfig.scanTime.toString()}s`;
        se_platform.fitInRect(this.dwellTimeText, 520, 100, 100, 100);
    }

    onScanTimeDown()
    {
        if (earthpixi.Access.settings.switchConfig.scanTime > 0.5)
        {
            const newVal = earthpixi.Access.settings.switchConfig.scanTime - 0.1;
            const multiplier = Math.pow(10, 1 || 0);

            earthpixi.Access.settings.switchConfig.scanTime = Math.round(newVal * multiplier) / multiplier;
        }

        this.dwellTimeText.text = `${earthpixi.Access.settings.switchConfig.scanTime.toString()}s`;
        se_platform.fitInRect(this.dwellTimeText, 520, 100, 100, 100);
    }

    onFinished()
    {
        this.save();
        se_platform.User.onboarded = true;
        se_platform.IO.saveSettings();

        this.hideCurrentSlide();

        this.skipButton.visible = false;

        const slide = this.createSlide();

        const txt = new PIXI.extras.BitmapText(Text.strings.ob_allSet, this.titleTextOptions);

        txt.maxWidth = 750;
        se_platform.fitInRect(txt, 300, 40, 750, 100);
        slide.addChild(txt);

        const finishButton = this.createButton(Text.strings.ob_play);

        finishButton.position.set(600, 250);
        finishButton.addEvent("select", this.closeOB, this);
        slide.addChild(finishButton);

        earthpixi.Access.soloButtons([finishButton]);
    }

    hideCurrentSlide()
    {
        if (!this.slide)
        {
            return;
        }

        this.disposeSlide(this.slide);

        // return;

        // for (const child of this.slide.children)
        // {
        //     child.interactive = false;
        // }
        // earthpixi.Access.reset();
        // this.slide.interactiveChildren = false;
        // earthpixi.Tween.to(this.slide, 0.5, {
        //     alpha: 0,
        //     onComplete: this.disposeSlide,
        //     onCompleteParams: [this.slide],
        //     onCompleteScope: this
        // });
        // this.slide = null;
    }

    disposeSlide(slide)
    {
        slide.destroy({ children: true });
    }

    closeOB()
    {
        earthpixi.Access.reset();
        earthpixi.Access.freeSoloButtons();
        this.parent.removeChild(this);
        this.destroy({ children: true });

        earthpixi.Access.switchAccess();
        earthpixi.Access.eyeGaze();

        se_platform.Navigation.init();
        // user is logged in call setGame (reads current hash or goes to main menu)
        se_platform.Navigation.setGame();
        se_platform.Navigation.navActive = true;
    }

    save()
    {
        earthpixi.Access.save();
        se_platform.IO.saveSettings();
    }
}
