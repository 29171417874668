import Language from "../Language";
import stringsEnGB from "./strings_en-gb";
export default class Text
{
    static get strings()
    {
        switch (se_platform.language)
        {
            case Language.EN_GB:
                return stringsEnGB;

            default:
                return stringsEnGB;
        }
    }
}
