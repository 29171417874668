export default class MenuKeyCodeSetter extends earthpixi.GameObject
{
    constructor(screen, configObj, options)
    {
        super(screen);

        this.configObj = configObj;
        this.options = options;

        const txtOptions = { font: "14px montserrat", align: "left" };
        let yPos = 0;

        this.editModeOn = false;
        this.currentEditing = null;

        this.txts = [];

        for (let i = 0; i < options.length; i++)
        {
            const option = this.options[i];
            const text = new PIXI.extras.BitmapText(
                `${option.txt} : ${earthpixi.Keyboard.KeyCodesDict[this.configObj[option.configProp]].toUpperCase()}`,
                txtOptions
            );

            text.y = yPos;
            text.configProp = option.configProp;
            text.on("pointerdown", this.onOptionSelect, this);
            text.eyeGazeAble = false;
            text.index = i;
            text.interactive = false;

            this.txts.push(text);
            this.addChild(text);

            yPos += text.height + 5;
        }

        this.editButton = new PIXI.extras.BitmapText("EDIT", { font: "14px montserrat", align: "left" });
        this.editButton.position.x = -50;
        this.editButton.position.y = 10;
        this.editButton.tint = 0x006837;
        this.editButton.interactive = true;
        this.editButton.buttonMode = true;
        this.addChild(this.editButton);

        this.editButton.on("pointerdown", this.onEdit, this);

        this.saveButton = new PIXI.Sprite(PIXI.utils.TextureCache.tick_icon);
        this.saveButton.position.set(-70, -10);
        this.saveButton.scale.set(0.7, 0.7);
        this.saveButton.visible = false;
        this.saveButton.on("pointerdown", this.onSave, this);
        this.saveButton.interactive = true;
        this.saveButton.buttonMode = true;
        this.addChild(this.saveButton);

        this.on("removed", this.onRemoved, this);
    }

    onEdit(evt)
    {
        this.editModeOn = true;

        const body = document.querySelector("body");

        this.onKeyBind = this.onKey.bind(this);
        body.addEventListener("keydown", this.onKeyBind);

        this.editButton.visible = false;
        this.saveButton.visible = true;

        for (let i = 0; i < this.txts.length; i++)
        {
            const txt = this.txts[i];

            txt.interactive = true;
        }

        this.startEdit(this.txts[0]);
    }

    onSave(evt)
    {
        const body = document.querySelector("body");

        body.removeEventListener("keydown", this.onKeyBind);

        this.editModeOn = false;

        this.editButton.visible = true;
        this.saveButton.visible = false;

        for (let i = 0; i < this.txts.length; i++)
        {
            this.resetEdit(this.txts[i]);
            this.txts[i].interactive = false;
        }
    }

    onOptionSelect(evt)
    {
        const target = evt.data.target || evt.currentTarget;

        this.startEdit(target);

        for (let i = 0; i < this.txts.length; i++)
        {
            const txt = this.txts[i];

            if (txt !== target)
            {
                this.resetEdit(txt);
            }
        }
    }

    startEdit(txtSpr)
    {
        this.currentEditing = txtSpr;
        txtSpr.tint = 0xFFFF00;
        const option = this.options[txtSpr.index];

        txtSpr.text = `${option.txt} : <press key>`;
    }

    resetEdit(txtSpr)
    {
        txtSpr.tint = null;
        const option = this.options[txtSpr.index];

        txtSpr.text = `${option.txt} : ${earthpixi.Keyboard.KeyCodesDict[this.configObj[option.configProp]].toUpperCase()}`;
    }

    onRemoved(evt)
    {
        if (this.editModeOn)
        {
            const body = document.querySelector("body");

            body.removeEventListener("keydown", this.onKeyBind);
            this.editModeOn = false;
        }
    }

    onKey(evt)
    {
        if (!evt.metaKey)
        {
            evt.preventDefault();
        }

        const keyCode = evt.keyCode;
        let taken = false;

        for (let i = 0; i < this.options.length; i++)
        {
            if (i !== this.currentEditing.index)
            {
                if (this.configObj[this.options[i].configProp] === keyCode)
                {
                    taken = true;
                }
            }
        }

        const option = this.options[this.currentEditing.index];
        const keyName = earthpixi.Keyboard.KeyCodesDict[evt.keyCode];

        if (!taken)
        {
            this.configObj[option.configProp] = evt.keyCode;

            this.emit("change", evt.keyCode);

            this.currentEditing.text = `${option.txt} : ${(keyName === " " ? "Spacebar" : keyName.toUpperCase())}`;
        }
        else
        {
            this.currentEditing.text = `${option.txt} : <${(keyName === " " ? "Spacebar" : keyName.toUpperCase())}> TAKEN!`;
        }

        window.focus = earthpixi.renderer.view;
    }
}
