import ServerSettings from "../config/ServerSettings";

export default function standAloneApi(serverURL)
{
    //console.log("stand alone api");

    if (serverURL) ServerSettings.url = serverURL;
    console.log("server", ServerSettings.url);

    se_platform.LocalStorage.load();

    console.log(se_platform.User);

    earthpixi.currentScreen = {
        verifyUpdate: () =>
            true
    };
}
