export default [

    {
        question: "Which control method do you most use when playing on the site?",
        answers: [
            "Eye Gaze",
            "Switch",
            "Mouse",
            "Not Sure"
        ],
        index: 0
    },

    {
        question: "How easy is it to set up the controls?",
        answers: [
            "Easy",
            "OK",
            "Hard",
            "Not Sure"
        ],
        index: 1
    },
    {
        question: "How did you find learning the controls for the games?",
        answers: [
            "Easy",
            "OK",
            "Hard",
            "Not Sure"
        ],
        index: 2
    },
    {
        question: "How did you find the controls once you learnt them?",
        answers: [
            "Good",
            "OK",
            "Bad",
            "Not Sure"
        ],
        index: 3
    },

    {
        question: "Which games did you try?",
        answers: [
            "Mole Miners",
            "Eyedrive",
            "Gazey Golf",
            "Open Drive",
            "Snakes And Ladders",
            "Solitaire",
            "4 in a Row",
            "Checkers",
            "Chess",
            "Sudoku",
            "Sudoku Monsters",
            "Music Player",
            "Not Sure"
        ],
        index: 4,
        multiple: true
    },
    {
        question: "Which games will you play again?",
        answers: [
            "Mole Miners",
            "Eyedrive",
            "Gazey Golf",
            "Open Drive",
            "Snakes And Ladders",
            "Solitaire",
            "4 in a Row",
            "Checkers",
            "Chess",
            "Sudoku",
            "Sudoku Monsters",
            "Music Player",
            "Not Sure"
        ],
        index: 5,
        multiple: true
    },
    {
        question: "What type of other games would you like to play on the site in the future?",
        answers: [
            "Sports",
            "Driving",
            "Point and Click",
            "Puzzle",
            "More board games",
            "Multiplayer Games",
            "Not Sure"
        ],
        index: 6,
        multiple: true
    },
    {
        question: "What types of activities, other than games, would you like to see on the site in the future?",
        answers: [
            "Music",
            "Stories",
            "Art",
            "Not Sure"
        ],
        index: 7,
        multiple: true
    },
    {
        question: "Would you like to play online against other people?",
        answers: [
            "Yes",
            "No",
            "Not Sure"
        ],
        index: 8
    },
    {
        question: "Thank you. Are you ready to submit your feedback? Press back to change your answers or press Submit.",
        answers: [
            "Submit"
        ],
        index: 9
    }

];
