import OptionButton from "../onboarding/OptionButton";
import PageDots from "../slideshow/PageDots";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import Questions from "./questions";
import strings from "./strings";

export default class FeedBackForm extends earthpixi.GameObject
{
    constructor()
    {
        super();

        earthpixi.Access.eyeGaze(true);
        earthpixi.Access.switchAccess(true);

        this.cover = new PIXI.Graphics();
        this.cover.beginFill(0x000000, 0.8);
        this.cover.drawRect(-earthpixi.config.STAGE_WIDTH, 0, earthpixi.config.STAGE_WIDTH * 2, earthpixi.config.STAGE_HEIGHT);
        this.cover.interactive = true;
        this.cover.alpha = 0;
        this.addChild(this.cover);

        this.blueBox = new PIXI.Sprite(PIXI.utils.TextureCache.blue_bg);
        this.blueBox.alpha = 0;
        earthpixi.utils.Display.position.center(this.blueBox);
        this.blueBox.x += 10;
        this.blueBox.y = 140;
        this.addChild(this.blueBox);

        this.titleTextOptions = { font: "25px montserrat", tint: 0xFFFFFF, align: "left" };
        this.titleTextOptionsCenter = { font: "25px montserrat", tint: 0xFFFFFF, align: "center" };
        this.optionTextOptions = { font: "40px concert_one", tint: 0xFFFFFF, align: "left" };

        this.fadeIn();
    }

    fadeIn()
    {
        earthpixi.Tween.to([this.cover, this.blueBox], 0.5, {
            delay: 1,
            alpha: 1,
            onCompleteScope: this,
            onComplete: this.start
        });
    }

    start()
    {
        this.startContainer = new PIXI.Container();

        this.addChild(this.startContainer);

        const setupTxt = new PIXI.extras.BitmapText(strings.title, {
            font: "25px concert_one",
            tint: 0xFFFFFF,
            align: "left"
        });

        setupTxt.maxWidth = 750;
        setupTxt.position.set(300, 200);
        // se_platform.fitInRect(setupTxt,150,200,950,100, true);
        this.startContainer.addChild(setupTxt);

        this.skipButton = new PIXI.extras.BitmapText(strings.skip, {
            font: "40px concert_one",
            tint: 0xFFFFFF,
            align: "left"
        });
        this.skipTex = earthpixi.renderer.generateTexture(this.skipButton, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.skipButton = new earthpixi.Access.Button(this.skipTex, this.skipTex, strings.skip, false, false, 10);
        this.skipButton.customDwellTime = earthpixi.Access.settings.eyeGazeConfig.dwellTime * 2;
        this.skipButton.addEvent("select", this.onSkip, this);
        this.skipButton.position.set(1010, 90);
        this.addChild(this.skipButton);

        this.backButton = new PIXI.extras.BitmapText(strings.back, {
            font: "40px concert_one",
            tint: 0xFFFFFF,
            align: "left"
        });
        this.backTex = earthpixi.renderer.generateTexture(this.backButton, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.backButton = new earthpixi.Access.Button(this.backTex, this.backTex, strings.back, false, false, 10);
        this.backButton.addEvent("select", this.onBack, this);
        this.backButton.position.set(290, 640);
        this.backButton.visible = false;
        this.addChild(this.backButton);

        this.nextButton = new PIXI.extras.BitmapText(strings.next, {
            font: "40px concert_one",
            tint: 0xFFFFFF,
            align: "left"
        });
        this.nextTex = earthpixi.renderer.generateTexture(this.nextButton, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.nextButton = new earthpixi.Access.Button(this.nextTex, this.nextTex, strings.next, false, false, 10);
        this.nextButton.addEvent("select", this.onNext, this);
        this.nextButton.position.set(1010, 640);
        this.nextButton.visible = false;
        this.addChild(this.nextButton);

        // set up pagination dots etc.
        this.pagination = new PageDots(this.screen, Questions.length);
        //console.log(this.pagination);
        this.pagination.position.set((earthpixi.config.STAGE_WIDTH - this.pagination.width) / 2, 660);
        this.pagination.visible = false;
        this.addChild(this.pagination);

        const startBut = this.createButton(strings.startQuestions);

        startBut.position.set(600, 450);
        startBut.addEvent("select", this.startQuestions, this);
        this.startContainer.addChild(startBut);

        // let submitButton =  this.createButton(strings.startQuestions);
        // submitButton.position.set(600,450);
        // submitButton.addEvent("select", this.finished, this);
        // this.startContainer.addChild(submitButton);

        earthpixi.Access.soloButtons([startBut, this.skipButton, this.backButton, this.nextButton]);
    }

    startQuestions()
    {
        this.removeChild(this.startContainer);
        this.startContainer.destroy({ children: true });

        this.questionIndex = 0;
        this.resultData = new Array(Questions.length);
        this.pagination.visible = true;

        this.newQuestion();
    }

    newQuestion()
    {
        this.nextButton.visible = false;
        this.pagination.setDot(this.questionIndex);

        if (this.currentQuestion)
        {
            this.removeChild(this.currentQuestion);
            this.currentQuestion.destroy({ children: true });
        }

        const qData = Questions[this.questionIndex];

        this.currentQuestion = new MultipleChoiceQuestion(qData.question, qData.answers, qData.multiple);
        this.currentQuestion.position.set(320, 200);
        this.currentQuestion.alpha = 0;

        // saved answers
        if (qData.chosen !== undefined)
        {
            if (Array.isArray(qData.chosen))
            {
                for (let q = 0; q < qData.chosen.length; q++)
                {
                    this.currentQuestion.buttons[qData.chosen[q]].toggle();
                }
            }
            else
            {
                this.currentQuestion.buttons[qData.chosen].toggle();
            }
            this.nextButton.visible = true;
        }

        this.qTween = earthpixi.Tween.to(this.currentQuestion, 0.4, {
            alpha: 1,
            delay: 0.5,
            onComplete: this.enableQuestion,
            onCompleteScope: this
        });
        this.addChild(this.currentQuestion);

        this.backButton.visible = true;

        let soloButtons = [];

        soloButtons = soloButtons.concat(this.currentQuestion.buttons);
        soloButtons = soloButtons.concat([this.skipButton, this.nextButton, this.backButton]);
        earthpixi.Access.soloButtons(soloButtons);

        this.currentQuestion.on("answered", this.questionAnswered, this);
        this.currentQuestion.on("notReady", this.questionNotReady, this);
    }

    enableQuestion()
    {
        this.currentQuestion.enable();
    }

    questionAnswered(index)
    {
        Questions[this.questionIndex].chosen = index;
        this.nextButton.visible = true;
    }

    questionNotReady()
    {
        this.nextButton.visible = true;
    }

    onNext()
    {
        this.submitQuestion(this.currentQuestion.getAnswers());
    }

    submitQuestion(answers)
    {
        // this.resultData[Questions[this.questionIndex].index] = answers.toString();

        this.removeChild(this.currentQuestion);
        this.currentQuestion.destroy({ children: true });

        this.questionIndex++;

        if (this.questionIndex === Questions.length)
        {
            this.finished();
        }
        else
        {
            this.resultData[Questions[this.questionIndex].index] = answers.toString();
            this.newQuestion();
        }
    }

    onBack()
    {
        if (this.questionIndex === 0)
        {
            if (this.currentQuestion)
            {
                this.removeChild(this.currentQuestion);
                this.currentQuestion.destroy({ children: true });
                this.qTween.kill();

                this.backButton.visible = false;
                this.pagination.visible = false;

                this.start();
            }

            return;
        }

        if (this.questionIndex > 0)
        {
            this.questionIndex--;
            this.newQuestion();
        }
    }

    finished()
    {
        this.nextButton.visible = false;
        this.backButton.visible = false;
        this.skipButton.visible = false;
        this.pagination.visible = false;

        console.log(this.resultData);

        const xhr = new XMLHttpRequest();

        xhr.open("POST", "https://script.google.com/macros/s/AKfycbw85OB2MIANCVEbxUVi-z-JF5tSFld7HU9raWtE36ut2Qmm8pDO/exec");
        xhr.send(JSON.stringify(
            { answers: this.resultData }
        ));

        const setupTxt = new PIXI.extras.BitmapText(strings.thanks, {
            font: "40px concert_one",
            tint: 0xFFFFFF,
            align: "center"
        });

        setupTxt.maxWidth = 750;
        setupTxt.position.set(430, 300);
        // se_platform.fitInRect(setupTxt,150,200,950,100, true);
        this.addChild(setupTxt);

        earthpixi.Tween.to(this, 0.5, { delay: 2.5, alpha: 0, onComplete: this.onSkip, onCompleteScope: this });
    }

    createButton(title)
    {
        return new OptionButton(title, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, title);
    }

    onSkip()
    {
        earthpixi.Access.reset();
        earthpixi.Access.freeSoloButtons();
        this.parent.feedbackForm = null;
        this.parent.removeChild(this);
        this.destroy({ children: true });

        earthpixi.Access.switchAccess();
        earthpixi.Access.eyeGaze();
    }
}
