
/**
 *
 */
export default class LocalStore
{
    constructor()
    {
        this.timeLimit = (60 * 1000 * 1440) * 7; // 10 days
        this.storageName = "eyegazegames_user";
    }

    /**
     *
     */
    load(){
        const localUser = window.localStorage.getItem(this.storageName);

        if (localUser)
        {
            const parsed = JSON.parse(localUser);

            // console.log(parsed);
            console.log("LocalStore.js: importing local user: ", parsed); // , localUser);
            earthpixi.Access.settings.copy(parsed.settings);
            se_platform.User.copy(parsed);
        }
    }

    /**
     *
     */
    save()
    {
        console.log('EPIXI: WILL NOT SAVE USER TO LOCAL STORAGE')
        // console.log('MM:: earthpixi.Access.settings before running saving: ', earthpixi.Access.settings)
        se_platform.User.settings.copy(earthpixi.Access.settings);

        // const userJson = se_platform.User.toJSON();

        // console.log('MM::save -> se_platform.User: ', se_platform.User)
        // console.log('MM:: userJson: ', userJson)

        // userJson.timeStamp = new Date().getTime();

        // console.log("save", userJson.onboarded);

        // window.localStorage.setItem(this.storageName, JSON.stringify(userJson));
    }

    /**
     *
     */
    reset()
    {
        window.localStorage.clear();
    }
}
