import Text from "../../config/text";
import OnlineGameBox from "./OnlineGameBox";
import GameTypes from "../../config/GameTypes";

export default class InGameOnlineOptions extends earthpixi.GameObject
{
    /**
     *
     * @param {number} gameId
     */
    constructor(gameId)
    {
        super();

        // Cards
        this.bgCard = new PIXI.Sprite(PIXI.utils.TextureCache.blue_bg);
        earthpixi.utils.Display.position.center(this.bgCard);
        this.bgCard.x += 10;
        this.bgCard.y = 140;
        this.addChild(this.bgCard);

        // const gameLabel = new PIXI.extras.BitmapText(GameTypes.getName(gameId), { font: "40px baloo_small", tint: 0x222133, align: "left" });
        // gameLabel.y = 0;
        // gameLabel.x += 200;
        // this.bgCard.addChild(gameLabel);

        this.cardsMask = new PIXI.Sprite(PIXI.utils.TextureCache.card_mask);
        this.cardsMask.x = this.bgCard.x + 21;
        this.cardsMask.y = 155;
        this.addChild(this.cardsMask);

        this.blueSlide = new PIXI.Sprite(PIXI.utils.TextureCache.blue_slide);
        this.blueSlide.x = 500;
        this.blueSlide.y = 140;
        this.blueSlide.mask = this.cardsMask;
        this.addChild(this.blueSlide);

        this.titleText = new PIXI.extras.BitmapText("PLAY ONLINE", { font: "50px baloo", align: "left" });
        this.titleText.position.set(1200, 175);
        this.titleText.skew.x = -0.25;
        this.titleText.mask = this.cardsMask;
        this.addChild(this.titleText);

        earthpixi.maskUpdateItems.push(this.cardsMask);

        if (earthpixi.renderer instanceof PIXI.CanvasRenderer)
        {
            const rect = new PIXI.Rectangle(0, 0, this.cardsMask.width, this.cardsMask.height);

            this.removeChild(this.cardsMask);

            this.cardsMask = new PIXI.Graphics();
            this.cardsMask.beginFill(0xFFFFFF, 1);
            this.cardsMask.drawRect(rect.x, rect.y, rect.width, rect.height);
            this.cardsMask.x = this.bgCard.x + 21;
            this.cardsMask.y = 155;
            this.cardsMask.drawRect(rect.x, rect.y, rect.width, rect.height);
            this.addChild(this.cardsMask);

            this.blueSlide.mask = this.cardsMask;
            this.titleText.mask = this.cardsMask;
        }

        this.titleText.text = "PLAY ONLINE";
        const txtX = this.bgCard.x + this.bgCard.width - 30 - Math.floor(this.titleText.width);

        earthpixi.Tween.to(this.titleText, 1, { x: txtX, delay: 0, ease: Expo.easeInOut });

        this.blueSlide.x = 1024;
        earthpixi.Tween.to(this.blueSlide, 1, { x: txtX - 300, ease: Expo.easeOut });

        this.settings = new OnlineGameBox(gameId);
        this.settings.on("exit", this.onExit, this);
        this.settings.on("done", this.onDone, this);
        this.settings.position.set(this.cardsMask.x, 700);
        this.addChild(this.settings);

        this.settings.mask = this.cardsMask;
        earthpixi.Tween.to(this.settings, 1, {
            y: 198, delay: 0.5, ease: Expo.easeOut,
            // onComplete: this.unmask, onCompleteParams: [[this.settings], true], onCompleteScope: this
        });

        // exit button
        this.exitButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.exit_button,
            PIXI.utils.TextureCache.exit_button,
            "Exit Settings", false, false, 20
        );

        this.exitButton.scale.set(0.8, 0.8);

        this.exitButton.position.set(this.cardsMask.x + 10, this.cardsMask.y + 10);
        this.exitButton.addEvent("select", this.onExit, this);
        this.addChild(this.exitButton);

        earthpixi.Access.soloButtons(this);

        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;
    }

    onExit(evt)
    {
        earthpixi.Tween.kill(this.settings);
        earthpixi.Tween.kill(this.blueSlide);
        earthpixi.Tween.kill(this.titleText);

        earthpixi.Access.freeSoloButtons();

        this.emit("exit");
    }

    onDone(evt)
    {
        earthpixi.Tween.kill(this.settings);
        earthpixi.Tween.kill(this.blueSlide);
        earthpixi.Tween.kill(this.titleText);

        earthpixi.Access.freeSoloButtons();
        this.settings.destroy({ children: true });

        this.emit("done");
    }

    destroy(options)
    {
        earthpixi.Tween.kill(this.settings);
        earthpixi.Tween.kill(this.blueSlide);
        earthpixi.Tween.kill(this.titleText);

        super.destroy(options);
    }
}
