/**
 * @static
 * @memberOf se_platform
 * @enum {URLAddressPart}
 * @property {string} GAME
 * @property {string} LOCATION
 * @property {string} MODE
 */

enum URLAddressPart {
    /**
     *@type {string}
     */
    GAME = "game",
    /**
     *@type {string}
     */
    LOCATION = "location",
    /**
     *@type {string}
     */
    MODE = "mode"
}

export default URLAddressPart;
