import Text from "../config/text";
import GameConfigModel from "./GameConfigModel";
import GameID from "./GameID";

/**
 * @static
 * @class
 */
export default class GameTypes
{
    /**
     *
     * @returns {se_platform.GameConfigModel[]}
     * @type {se_platform.GameConfigModel[]}
     */
    public static get array(): GameConfigModel[]
    {
        if (this._array.length === 0)
        {
            this._array = [
                GameTypes.OTHELLO,
                GameTypes.OPENDRIVE,
                GameTypes.MOLE_MINERS,
                GameTypes.EYEDRIVE,
                GameTypes.GAZEY_GOLF,
                GameTypes.SNAKES_AND_LADDERS,
                GameTypes.SOLITAIRE,
                GameTypes.CONNECT4,
                GameTypes.CHESS,
                GameTypes.CHECKERS,
                GameTypes.SUDOKU,
                GameTypes.SUDOKU_MONSTERS,
                GameTypes.MUSIC_PLAYER,
                GameTypes.BOOK
            ];
        }

        return this._array;
    }

    /**
     * @type {se_platform.GameConfigModel}
     */
    public static OTHELLO: GameConfigModel = new GameConfigModel(
        GameID.OTHELLO, "othello", "othello", "./othello/", null, true
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static SOLITAIRE: GameConfigModel = new GameConfigModel(
        GameID.SOLITAIRE, "solitaire", "solitaire", "./solitaire/",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static CONNECT4: GameConfigModel = new GameConfigModel(
        GameID.CONNECT4, "connect4", "connect4", "./connect4/",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static CHESS: GameConfigModel = new GameConfigModel(
        GameID.CHESS, "chess", "chess", "./chess/",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static CHECKERS: GameConfigModel = new GameConfigModel(
        GameID.CHECKERS, "checkers", "checkers", "./checkers/",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static SUDOKU: GameConfigModel = new GameConfigModel(
        GameID.SUDOKU, "sudoku", "sudoku", "./sudoku/", "Sudoku",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static SUDOKU_MONSTERS: GameConfigModel = new GameConfigModel(
        GameID.SUDOKU_MONSTERS, "sudoku_monsters", "monsters", "./sudoku_monsters/", "Monsters",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static MUSIC_PLAYER: GameConfigModel = new GameConfigModel(
        GameID.MUSIC_PLAYER, "musicPlayer", "musicplayer", "./musicplayer/", null, false
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static MOLE_MINERS: GameConfigModel = new GameConfigModel(
        GameID.MOLE_MINERS, "moleminers", "moleminers", "./moleminers/",
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static EYEDRIVE: GameConfigModel = new GameConfigModel(
        GameID.EYEDRIVE, "eyedrive", "eyedrive", "./eyedrive/", null, false,
    );
    /**
     * @type {se_platform.GameConfigModel}
     */
    public static BOOK: GameConfigModel = new GameConfigModel(
        GameID.BOOK, "bookreader", "bookreader", "./bookreader/",
    );

    /**
     * @type {se_platform.GameConfigModel}
     */
    public static GAZEY_GOLF: GameConfigModel = new GameConfigModel(
        GameID.GAZEY_GOLF, "gazeygolf", "gazeygolf", "./gazeygolf/",
    );

    /**
     * @type {se_platform.GameConfigModel}
     */
    public static SNAKES_AND_LADDERS: GameConfigModel = new GameConfigModel(
        GameID.SNAKES_AND_LADDERS, "snakesandladders", "snakesandladders", "./snakesandladders/",
    );

    /**
     * @type {se_platform.GameConfigModel}
     */
    public static OPENDRIVE: GameConfigModel = new GameConfigModel(
        GameID.OPENDRIVE, "opendrive", "opendrive", "./opendrive/", null, true
    );

    /**
     *
     * @param {GameID} id
     * @returns {string}
     */
    public static getName(id: GameID)
    {
        for (const g of this.array)
        {
            if (g.id === id)
            {
                return Text.strings[g.gameName];
            }
        }
    }

    protected static _array: GameConfigModel[] = [];
}
