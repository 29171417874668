/**
 * Fit a text sprite in a specified Rect,  eg text if language changes text still fits
 *
 * @memberof se_platform
 * @function se_platform#fitInRect
 *
 * @param {PIXI.DisplayObject} obj
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @param {boolean} [aligntop=false]
 */
export default function (obj, x, y, width, height, aligntop = false)
{
    let objs = [];

    if (Array.isArray(obj))
    {
        objs = obj;
    }
    else
    {
        objs.push(obj);
    }

    for (let i = 0; i < objs.length; i++)
    {
        const ob = objs[i];

        const targetW = width || obj.width;
        const targetH = height || obj.height;

        if (ob.width > targetW)
        {
            ob.width = targetW;
            ob.scale.y = ob.scale.x;
        }

        if (ob.height > targetH)
        {
            ob.height = targetH;
            ob.scale.x = ob.scale.y;
        }

        ob.x = x + ((targetW - ob.width) / 2);
        ob.y = !aligntop ? y + ((targetH - ob.height) / 2) : y;
    }
}
