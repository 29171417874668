/**
 * Allowed query string params
 *
 * @static
 * @enum {QueryName}
 * @memberOf se_platform
 * @property {string} GAME_ID
 * @property {string} USER_ID
 * @property {string} GAME_LEVEL
 * @property {string} GAME_STAGE
 * @property {string} GAME_ZONE
 * @property {string} ROOM_ID
 */
enum QueryName {
    /**
     *@type {string}
     */
    GAME_ID = "gid",
    /**
     *@type {string}
     */
    GAME_LEVEL = "level",
    /**
     *@type {string}
     */
    GAME_STAGE = "stage",
    /**
     *@type {string}
     */
    GAME_ZONE = "zone",
    /**
     *@type {string}
     */
    USER_ID = "uid",
    /**
     *@type {string}
     */
    ROOM_ID = "rid"
}

export default QueryName;
