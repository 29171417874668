export default {

    // names from GameTypes
    chess: "Chess",
    connect4: "4 in a Row",
    checkers: "Checkers",
    sudoku: "Sudoku",
    sudoku_monsters: "Sudoku Monsters",
    musicPlayer: "Music",
    moleminers: "Mole Miners",
    eydrive: "EyeDrive",
    gazeygolf: "Gazey Golf",
    snakesandladders: "Snakes And Ladders",
    opendrive: "Open Drive",

    checkExit: "Are you sure you want\nto go back to the main menu?",
    checkBack: "Are you sure you want\nto leave the game?",

    cancel: "Cancel",
    delete: "Delete",
    deleteGame: "Are you sure you want to delete this game with $userName?",
    waitingForOp: "waiting for opponent",
    serverError: "We're sorry there seems to be a problem with our server,\nwe can't log you in right now.",
    areYouNew: "Are you new?",
    cookieStatement: "Hi!  By selecting an option below, we will store your game settings anonymously to help you use the site. No personal data is required or stored. We may also use Cookies in the future to help us track how the site is used.",
    privacylink: "",
    helloUser: "Hello $username!",
    user: "user",
    username: "Username",
    yourSecretWord: "Your secret word is:",
    yes: "YES",
    new: "I'm\nNew",
    no: "NO",
    ok: "OK",
    go: "Go!",
    up: "Up",
    down: "Down",
    start: "START",
    hello: "HELLO",
    continue: "CONTINUE",
    submit: "SUBMIT",
    enterPassword: "enter secret word",
    secretWord: "secret word..",
    secretWordTitle: "Secret Word",
    userNotFound: "Sorry, no user found!",
    welcomeBackUser: "Welcome back $username!",
    secretWordInfo: "(Copy this down if you want to restore your settings and games later.)",
    logOutCheck: "Are you sure you want to log out $username?",

    joinGame: "Join Game",
    joinedGame: "You have joined a game!",
    newGame: "New Game",
    enterCode: "enter game code",
    edit: "Edit",
    login: "Login",
    deletedGame: "$user deleted the game.",
    savedGames: "Saved Games",
    genNewUser: "Generate new User",
    newOnlineGameInfo: "Start a new game and send a link to a friend.",
    clearCode: "Clear Code",
    chooseOnlineGame: "Choose Online Game",
    roomCodeIs: "Game code:",
    // roomCodeInfo1: "To invite a friend to a game, send them this URL:",
    // roomCodeInfo2: "or send them this code to join from \"online games\"",
    roomCodeInfo1: "To invite a friend to a game,",
    roomCodeInfo2: "send them this code to join from \"online games\"",
    gameNotFound: "sorry game not found \nor game full",
    yourOnlineGames: "Your online games:",
    copyGameLink: "Copy link to clipboard",

    feedback: "feedback",
    settings: "settings",
    email: "email",

    enableSwitch: "Enable Switch access",
    enableEyeGaze: "Enable Eye gaze",
    eyeGazeMode: "Eye gaze mode",
    oneSwitch: "Switch 1 (scan)",
    twoSwitch: "Switch 2 (select)",

    dwellClick: "Dwell Click",
    enableDwellClick: "Enable Dwell Click",
    dwellAnimation: "DWELL ANIMATION:",
    shrink: "SHRINK",
    pie: "PIE",
    selectMode: "SELECT MODE:",
    repeatMode: "REPEATING MODE:",
    bigButtons: "CLASSIC",
    direct: "PRECISE",
    test: "TEST",
    allowRepeat: "ALLOW",
    off: "OFF",

    dwellClickTime: "DWELL CLICK TIME",
    scanTime: "1 SWITCH SCAN TIME",
    switchMode1: "1 SWITCH",
    switchMode2: "2 SWITCH",
    switchMode: "SWITCH MODE",
    seconds: "SECONDS",
    switchMapping: "SWITCH MAPPING",

    infoEyeGazeSwitchOff: "Switch access / Eye gaze is disabled. Games are playable with mouse or touchscreens",
    infoSwitchEnabled: "Switch access is enabled.",
    playable1Switch: "Games are playable with 1 switch.",
    playable2Switch: "Games are playable with 2 switches.",
    keyInfo1: "($key1 = scan through options, $key2 = select)",
    keyInfo2: "Press $key1 to start scanning ($scanTime second interval) press $key1 again to select",

    playableEyeGaze: "Eye gaze enabled. Games playable with eye gaze ($dwellTime second dwell time).",
    playableEyeGazeNoDwell: "Eye gaze enabled. Dwell click is off. (Large accessible buttons are shown, but user has dwell click software running)",
    directMode: "Precise mode enabled (advanced eye gaze users can interact directly with game boards).",

    eyegazeAndSwitch: "Eye gaze and Switch access is enabled.",
    playableEyeAnd1Switch: "Games are playable with eye gaze or 1 switch.",
    playableEyeAnd2Switch: "Games are playable with eye gaze or 2 switches.",

    help_dwell: "Turn off if you already have Dwell Click software running.",
    help_dwellTime: "How long you need to dwell on an item to select it.",
    help_dwellAnim: "Choose the animation used by dwell click.",
    help_dwellMode: "Classic uses big buttons only, precise allows direct interaction with game boards (advanced users)",
    help_repeat: "Allow some buttons to select multiple times while hovering.",

    help_switchMode: "Switch mode:\n2 Switches = Move and Select.\n1 Switch = Press to start, press again to select.",
    help_scanTime: "Choose time interval for moving between items when scanning",
    help_switchMap: "Edit the key strokes you have configured for your switches.",

    /* onboarding*/

    ob_intro: "Hey you're new! Welcome to Eye Gaze Games! \n\nOur free and accessible web games are designed to be playable with just your eyes, using your eye gaze camera and its mouse control software.",

    ob_intro2: "Eye Gaze Games is dedicated to the memory of Google Executive James Howard, a passionate supporter and the inspiration for this global project. Our heartfelt thanks go to his devoted family and friends whose support was invaluable in laying its foundations."
        + "\n\n Dr Mick Donegan, Founder & CEO SpecialEffect",

    ob_newUser: "Choose a few settings below to get started, you can always change them again later.",

    ob_skip: "skip",
    ob_allSet: "You're all set!  You can change settings anytime by selecting the Settings button.",

    ob_setup: "New User",

    ob_play: "Play Games",
    ob_next: "Next",
    ob_settings: "Quick Setup",

    ob_chooseControl: "Choose how you want to play:",
    ob_Option1: "Mouse",
    ob_Option2: "Touch",
    ob_Option3: "Eye Gaze",
    ob_Option4: "Switch",

    ob_chooseEGOption1: "The website is designed to be playable with just your eyes, using your own eye gaze camera and mouse control software. \n\n"
        + "Would you like to use the site's built-in click to automatically select when you hover over a target with your mouse cursor, or your own click method?",
    ob_EGOption1: "Built-in",
    ob_EGOption2: "My Own",
    ob_EGOption2a: "Recommended \nwhen using \nmouse emulation",
    ob_EGOption2b: "I have my own click software running.",

    ob_chooseEGOption2: "Choose how long a Dwell-click takes",
    ob_chooseEGOption3: "Choose type of Dwell-click animation",
    ob_chooseEGOption3_b: "Remember to turn off any of your own dwell clicking when using ours",
    ob_chooseEGOptionPie: "Pie",
    ob_chooseEGOptionShrink: "Shrink",

    ob_chooseEGOption4: "How precise is your eye gaze setup?",
    ob_Normal: "Normal",
    bigButtons_normal: "Classic",
    ob_Precise: "Precise",
    ob_Continue: "Continue",
    ob_EGOption3: "Choose \"Classic\" to use larger targets when playing. \n Choose \"Precise\" to interact with smaller targets.",
    ob_EGOption4: "In Precise mode, eye gaze can be used to interact directly with smaller items on the game boards (but it's more difficult for some eye gaze setups)",

    ob_chooseSwitchOption: "How many switches do you have?",

    ob_switchOption1: "1 Switch",
    ob_switchOption1b: "Press switch 1 (Spacebar) once and wait to start scanning. Press again to select.",
    ob_switchOption2: "2 Switches",
    ob_switchOption2b: "Press switch 1 (Spacebar) to scan, use switch 2 (Enter) to select.",

    ob_switchOption3: "Scan time for 1 switch",

    ob_increase: "increase",
    ob_decrease: "decrease",

};
