import Text from "../../config/text";
import RoomModel from "../model/RoomModel";
import GameTypes from "../../config/GameTypes";
import moment from "moment";

export default class RoomListItem extends earthpixi.Access.Button
{
    constructor(roomModelData, connectedClientIds)
    {
        super(
            PIXI.utils.TextureCache.list_item_white_fade,
            PIXI.utils.TextureCache.list_item_white_fade,
            Text.strings.chooseOnlineGame,
            false,
            false,
            20
        );

        console.log(roomModelData);

        this.room = new RoomModel();

        this.room.copy(roomModelData);

        this.darkBG = new PIXI.Sprite(PIXI.utils.TextureCache.list_item_dark);

        this.addChild(this.darkBG);
        this.darkBG.visible = false;

        const userTitle = new PIXI.extras.BitmapText(GameTypes.getName(this.room.game.gameId), { font: "13px montserrat_small", align: "left" });

        userTitle.x = 10;
        userTitle.y = 4;

        const nameLengthLimit = 14;

        let username = this.room.opponent.username || Text.strings.waitingForOp;

        if (username.length > nameLengthLimit) username = `${username.substr(0, nameLengthLimit)}...`;

        const label = new PIXI.extras.BitmapText(username, { font: "24px baloo_small", align: "left" });

        label.y = 14;
        label.x = 10;

        // Game info

        const gameTime = this.getTimeSinceUpdate(roomModelData.updated_at);

        const turn = this.room.ourTurn() ? "Your turn" : "Their turn";

        const gameUpdatedText = new PIXI.extras.BitmapText(`${gameTime} ago`, { font: "13px montserrat_small", align: "left" });
        const turnText = new PIXI.extras.BitmapText(turn, { font: "13px montserrat_small", align: "left" });

        gameUpdatedText.x = 190;
        turnText.x = 190;
        gameUpdatedText.y = 10;
        turnText.y = 30;

        // player icons

        // // local user (pink)
        // const localUserIcon = new PIXI.Sprite(PIXI.utils.TextureCache.user_pink_nostroke);
        //
        // localUserIcon.scale.set(0.8);
        // localUserIcon.y = 15;
        // localUserIcon.x = 250;
        // this.addChild(localUserIcon);

        // remote user (purple)
        const remoteUserIcon = new PIXI.Sprite(PIXI.utils.TextureCache.user_purple_nostroke);

        remoteUserIcon.scale.set(0.8);
        remoteUserIcon.y = 15;
        remoteUserIcon.x = 278;
        this.addChild(remoteUserIcon);

        const onlineDot = new PIXI.Sprite(PIXI.utils.TextureCache.icon_circle_purple);

        onlineDot.visible = connectedClientIds.find((id) => id === this.room.opponent.username);

        onlineDot.y = 32;
        onlineDot.x = 290;
        onlineDot.scale.set(0.7);

        this.addChild(onlineDot);
        this.addChild(label);
        this.addChild(userTitle);
        this.addChild(gameUpdatedText);
        this.addChild(turnText);

        this.deleteOverlay = new PIXI.Sprite(PIXI.utils.TextureCache.list_item_white);
        this.deleteOverlay.addChild(new PIXI.Sprite(PIXI.utils.TextureCache.list_item_white_fade));
        const cancelIcon = new PIXI.Sprite(PIXI.utils.TextureCache.cancel_icon);

        cancelIcon.scale.set(0.8);
        cancelIcon.x = cancelIcon.y = 8;
        this.deleteOverlay.addChild(cancelIcon);
        this.deleteOverlay.tint = 0xFF0000;
        this.deleteOverlay.visible = false;
        this.addChild(this.deleteOverlay);
    }

    set editMode(bool)
    {
        this._editMode = bool;

        if (this._editMode)
        {
            this.showOn(true);
            this.deleteOverlay.visible = true;
            this.deleteOverlay.x = 300;
            earthpixi.Tween.to(this.deleteOverlay, 0.3, { x: 250 });
        }
        else
        {
            // this.deleteOverlay.visible = false;
            earthpixi.Tween.to(this.deleteOverlay, 0.3, { x: 350 });
            this.showOn(false);
        }
    }

    getTimeSinceUpdate(updatedDateString)
    {
        const previous = moment(updatedDateString);
        const now = moment();
        const duration = moment.duration(now.diff(previous));

        return duration.humanize();
    }

    showOn(bool)
    {
        this.darkBG.visible = bool;
    }
}
