import LoadingScreen from "../menu/screens/LoadingScreen";
/**
 * @typedef {object} ScreenAsset
 * @property {string} name
 * @property {string} url
 * @property {PIXI.loaders.LoaderOptions} [options]
 */

/**
 * Need to make this to use a Screen class constructor as a type 💩
 * @interface ScreenClass
 */

/**
 * @function
 * @name ScreenClass#new
 * @return {earthpixi.Screen}
 */

/**
 * Shortcut to setScreen with Eye gaze games loading screen
 * @memberof se_platform
 * @function se_platform#goToScreen
 * @param {ScreenAsset[]} assetList
 * @param {ScreenClass} screenClass
 * @param {boolean} [fade]
 * @param {boolean} [stopAudio]
 * @param {Class<LoadingScreen>} [LoadingScreenClass]
 */
export default function (assetList, screenClass, fade = true, stopAudio = true, LoadingScreenClass = null)
{
    console.log('running goToScreen.js')
    // set some defaults, specify other settings in screen
    // earthpixi.Access.settings.eyeGazeConfig.directMode = false;
    earthpixi.Access.settings.switchConfig.directMode = false;
    earthpixi.Access.settings.switchConfig.rowSelectorMode = false;
    earthpixi.Access.reset();


    console.log('have reset earthpixi.access')
    
    if (se_platform.GameMenu._init){
        console.log('se_platform.GameMenu._init is: ', se_platform.GameMenu._init)
        se_platform.GameMenu.reset();
        se_platform.GameMenu.hide();
    }

    if (LoadingScreenClass)
    {
        console.log('LoadingScreenClass exists')
        earthpixi.setScreen(
            new LoadingScreenClass(assetList, screenClass, fade, true, stopAudio, stopAudio)
        );
        console.log('have setScreen A')
    } else {
        console.log('LoadingScreenClass does not exist')
        earthpixi.setScreen(
            new LoadingScreen(assetList, screenClass, fade, true, stopAudio, stopAudio)
        );
        console.log('have setScreen B')
    }
}
