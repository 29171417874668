/**
 * States or views games can show
 *
 * @constant
 * @memberOf se_platform
 * @enum {GameLocation}
 * @property {string} NONE
 * @property {string} GAME
 * @property {string} CHOOSE_OPTIONS
 * @property {string} CHOOSE_PLAYERS
 * @property {string} HOW_TO_PLAY
 * @property {string} LOBBY
 * @property {string} END
 *
 */
enum GameLocation {

    NONE= "",
    /**
     * @type {string}
     */
    GAME = "game",
    /**
     * @type {string}
     */
    CHOOSE_OPTIONS = "options",
    /**
     * @type {string}
     */
    CHOOSE_PLAYERS = "players",
    /**
     * @type {string}
     */
    HOW_TO_PLAY = "howtoplay",
    /**
     * @type {string}
     */
    LOBBY = "lobby",
    /**
     * @type {string}
     */
    END = "end",
}

export default GameLocation;
