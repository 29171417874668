import Text from "../config/text";
import RoomServiceDisplay from "../io/ui/RoomServiceDisplay";
import LoadingScreen from "../menu/screens/LoadingScreen";
import InGameOnlineOptions from "../io/ui/InGameOnlineOptions";
import ConfirmOverlay from "./ConfirmOverlay";
import InGameSettings from "./InGameSettings";
import { sendMessage } from "../../../../game_entry_files/sendMessage";

/**
 *
 */
export default class GameMenuOverlay extends PIXI.Container
{
    constructor()
    {
        super();

        this._init = false;
        //  @type {ScreenClass}
        this.screenToGoBackTo = null;

        // @type {ScreenClass}
        this.loadingScreenClass = LoadingScreen;

        // @type {boolean | null}
        this.backWarning = null;
        this._state = 0;
        this._dark = false;
        this._open = false;
        this.doExit = true;

        // @type {Function | null}
        this.onBackMethod = null;

        // @type {Function | null}
        this.onCancelBackMethod = null;

        this.textures = {}
    }

    /**
     * @constant
     * @memberof GameMenuOverlay
     * @type {object}
     * @property {number} BACK_STATE
     * @property {number} EXIT_STATE
     * @property {number} GAME_STATE
     */
    get STATES()
    {
        return {
            BACK_STATE: 0,
            EXIT_STATE: 1,
            GAME_STATE: 2
        };
    }

    /**
     * @type {number}
     */
    get state()
    {
        if (!this._init)
        {
            this.init();
        }

        return this._state;
    }

    set state(val)
    {
        if (!this._init)
        {
            this.init();
        }

        // console.log("set state", val);
        this.reset();

        switch (val)
        {
            case this.STATES.BACK_STATE :
                this.exitButton.visible = false;
                this.altExitButton.visible = false;

                this.backButton.visible = true;
                this.altBackButton.visible = true;

                break;

            case this.STATES.GAME_STATE :
                this.exitButton.visible = false;
                this.altExitButton.visible = false;

                this.backButton.visible = true;

                this.altBackButton.visible = false;

                break;

            case this.STATES.EXIT_STATE :
                this.exitButton.visible = true;
                this.altExitButton.visible = true;

                this.backButton.visible = false;
                this.altBackButton.visible = false;

                break;
        }
        this._state = val;

        this.setEyeToggle();
        this.hide(true);
        this.show(true);
        this.dark = false;
    }

    /**
     * @type {boolean}
     */
    get dark()
    {
        return this._dark;
    }

    set dark(val)
    {
        this._dark = val;
        const tint = val ? 0x222133 : 0xFFFFFF;

        this.cover.tint = val ? 0xFFFFFF : 0x222133;

        this.exitButton.tint
            = this.burgerButton.tint
            = this.musicButton.tint
            = this.settingsButton.tint
            = this.altBackButton.tint
            = this.altExitButton.tint
            = this.collapseButton.tint
            = this.eyeRestButton.tint
            = this.backButton.tint = tint;

        this.customButtons.forEach((but) =>
        {
            but.tint = tint;
            but.text.tint = tint;
        });

        if (this.secretWordText)
        { this.secretWordText.tint = val ? 0x222133 : 0xFFFFFF; }

        if (this.gameLinkMessage)
        { this.gameLinkMessage.tint = val ? 0x222133 : 0xFFFFFF; }
    }

    /**
     *
     */
    init()
    {
        if (this._init) return;
            
        this.textures = {
            menu: PIXI.utils.TextureCache.menu,
            back: PIXI.utils.TextureCache.back,
            close: PIXI.utils.TextureCache.close,
        }

        console.log("<gamemenu> GAME MENU init: ", this.textures);

        this.cover = earthpixi.utils.Draw.DrawRect({ x: 0, y: 0, width: 128, height: 128 }, 0xFFFFFFCC);
        this.cover.width = earthpixi.config.STAGE_WIDTH * 4;
        this.cover.x = -earthpixi.config.STAGE_WIDTH * 2;
        this.cover.height = earthpixi.config.STAGE_HEIGHT * 2;
        this.cover.y = -earthpixi.config.STAGE_HEIGHT / 2;
        this.cover.interactive = true;

        this._init = true;

        this.burgerButton = new earthpixi.Access.Button(
            this.textures.menu,
            this.textures.menu,
            // PIXI.utils.TextureCache.menu,
            // PIXI.utils.TextureCache.menu,
            // PIXI.utils.TextureCache.burger_button,
            // PIXI.utils.TextureCache.burger_button,
            "Menu", false, false, 0
        );

        console.log('PIXI.utils.TextureCache: ', PIXI.utils.TextureCache)

        this.burgerButton.setScale(1);
        this.burgerButton.width = 350
        console.log('so scale is: ', this.burgerButton)
        this.burgerButton.tabIndex = 100;
        this.burgerButton.rowGroup = 1;
        // this.burgerButton.addEvent("select", this.showMenus, this);
        this.burgerButton.addEvent("select", this.triggerMenu, this);

        this.altBackButton = new earthpixi.Access.Button(
            // PIXI.utils.TextureCache.back_button,
            // PIXI.utils.TextureCache.back_button,
            this.textures.back,
            this.textures.back,
            "Back", false, false, 0
        );
        this.altBackButton.tabIndex = 100;
        this.altBackButton.rowGroup = 1;
        this.altBackButton.setScale(1.05);
        this.altBackButton.addEvent("select", this.onBack, this);
        this.altBackButton.visible = false;
        // this.addChild(this.altBackButton);

        this.altExitButton = new earthpixi.Access.Button(
            // PIXI.utils.TextureCache.exit_button_large,
            // PIXI.utils.TextureCache.exit_button_large,
            // PIXI.utils.TextureCache.close,
            // PIXI.utils.TextureCache.close,
            this.textures.close,
            this.textures.close,
            "Exit Game", false, false, 30
        );
        this.altExitButton.tabIndex = 100;
        this.altExitButton.rowGroup = 1;
        this.altExitButton.setScale(0.90);
        this.altExitButton.addEvent("select", this.triggerExitWarning, this);
        this.altExitButton.visible = false;
        // this.addChild(this.altExitButton);

        this.eyeRestButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.eye_icon_on_large,
            PIXI.utils.TextureCache.eye_icon_on_large,
            "Toggle eye gaze", false, false, 30
        );
        
        this.eyeRestButton.tabIndex = 100;
        this.eyeRestButton.rowGroup = 1;
        this.eyeRestButton.visible = false;
        this.eyeRestButton.switchable = false;
        this.eyeRestButton.setScale(0.8);
        this.eyeRestButton.addEvent("select", this.onEyeToggle, this);
        this.addChild(this.eyeRestButton);

        this.hasCustomButton = false;

        // this.createIOCommsDisplay();

        this.createLeftMenu();
        this.createRightMenu();
        this.createGameMenu();

        this.addChild(this.burgerButton);
        this.addChild(this.altBackButton);
        this.addChild(this.altExitButton);

        earthpixi.Access.on("menu", this.onAccessMenu, this);

        earthpixi.stageOverlay.addChild(this);
    }

    checkIframeIsEmbedded(){
        let isEmbedded = window.top !== window
        console.log('is iframe embedded? ', isEmbedded)
        return isEmbedded
    }

    triggerExitWarning(){
        if(this.checkIframeIsEmbedded()){
            globalThis.earthpixi.pause(true);
            sendMessage({action: 'triggerExitWarning'});

        }
        else console.log('trigger-menu clicked but app not embedded')
    }

    triggerMenu(){
        if(this.checkIframeIsEmbedded()){
            console.log('getting game state: ', globalThis.se_platform.GameMenu.STATES.GAME_STATE)
            
            console.log('getting game state: ', globalThis.se_platform.Navigation.location)

            globalThis.earthpixi.pause(true);

            sendMessage({
                action: 'triggerMenu', 
                location: globalThis.se_platform.Navigation.location,
                hash: globalThis.se_platform.Navigation.url,
                game: globalThis.se_platform.Navigation.game,
                mode: globalThis.se_platform.Navigation.mode,
            });
        }
        else console.log('trigger-menu clicked but app not embedded')
    }

    toggleMenu(){
        if(this.checkIframeIsEmbedded()){
            sendMessage({action: 'toggleMenu'})
        }
    }

    onEscapeKey(e)
    {
        console.log("<gamemenu> escape");
        this.toggleMenu()
        // if (this.visible)
        // {
        //     if (this._open)
        //     {
        //         this.hideMenus();
        //     }
        //     else
        //     {
        //         this.showMenus();
        //     }
        // }
    }

    reset()
    {
        // console.log("reset");
        this.hasCustomButton = false;
        this.customButtonContainer.children.forEach((but) =>
        {
            but.destroy({ children: true });
        });
        this.customButtons = [];
        this.screenToGoBackTo = null;
        this.onBackMethod = null;
        this.onCancelBackMethod = null;
        this.backWarning = false;
    }

    onAccessMenu()
    {
        // console.log("menu!");

        if (this.visible)
        {
            if (this.cover.parent)
            {
                this.hideMenus();
            }
            else
            {
                this.showMenus();
            }
        }
    }

    createLeftMenu()
    {
        this.leftMenuContainer = new PIXI.Container();

        // left column
        this.leftCol = new PIXI.Graphics();
        this.leftCol.beginFill(0xFFFFFF, 1);
        this.leftCol.drawRect(0, 0, 128, 128);

        this.leftCol = earthpixi.renderer.generateTexture(this.leftCol, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.leftCol = new PIXI.Sprite(this.leftCol);
        this.leftCol.width = 300;
        this.leftCol.height = earthpixi.config.STAGE_HEIGHT;

        this.leftMenuContainer.addChild(this.leftCol);

        // exit button
        this.exitButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.exit_button_large,
            PIXI.utils.TextureCache.exit_button_large,
            "Exit Game", false, false, 30
        );
        this.exitButton.position.set(95, 85);
        this.exitButton.setScale(0.90);
        this.leftMenuContainer.addChild(this.exitButton);
        // this.exitButton.addEvent("select", this.onExit, this);
        this.exitButton.addEvent("select", this.triggerExitWarning, this);

        this.backButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.back_button_large,
            PIXI.utils.TextureCache.back_button_large,
            "Go Back", false, false, 30
        );
        this.backButton.position.set(105, 85);
        this.leftMenuContainer.addChild(this.backButton);
        this.backButton.addEvent("select", this.onBack, this);

        this.settingsButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.settings_button_large,
            PIXI.utils.TextureCache.settings_button_large,
            "Settings", false, false, 30
        );
        this.settingsButton.setScale(1.05, 1.05);
        this.settingsButton.position.set(105, 355);
        this.leftMenuContainer.addChild(this.settingsButton);
        this.settingsButton.addEvent("select", this.onSettings, this);

        this.musicButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.music_large,
            PIXI.utils.TextureCache.music_large,
            "Music On/Off", false, false, 30
        );
        this.musicButton.position.set(105, 615);
        this.leftMenuContainer.addChild(this.musicButton);
        this.musicButton.addEvent("select", this.onMusic, this);
        this.musicButton.alpha = earthpixi.Audio._muteAudio ? 0.5 : 1;
    }

    createRightMenu()
    {
        this.rightMenuContainer = new PIXI.Container();

        // left column
        this.rightCol = new PIXI.Graphics();
        this.rightCol.beginFill(0xFFFFFF, 1);
        this.rightCol.drawRect(0, 0, 128, 128);

        this.rightCol = earthpixi.renderer.generateTexture(this.rightCol, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.rightCol = new PIXI.Sprite(this.rightCol);
        this.rightCol.width = 310;
        this.rightCol.height = earthpixi.config.STAGE_HEIGHT;

        this.rightMenuContainer.addChild(this.rightCol);

        this.collapseButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.collapse_button_large,
            PIXI.utils.TextureCache.collapse_button_large,
            "Exit Menu", false, false, 30
        );
        this.collapseButton.setScale(1.05, 1.05);
        this.collapseButton.position.set(100, 75);
        this.collapseButton.addEvent("select", this.hideMenus, this);
        this.rightMenuContainer.addChild(this.collapseButton);

        this.customButtonContainer = new PIXI.Container();
        this.rightMenuContainer.addChild(this.customButtonContainer);
    }

    createGameMenu()
    {
        this.customButtons = [];
    }

    createCustomButton(index)
    {
        const gameButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.rounded_rect, PIXI.utils.TextureCache.rounded_rect, Text.strings.newGame, false, false, 20);

        gameButton.addEvent("select", this.onCustomButton, this, index);
        gameButton.index = index;
        // gameButton.customFilter = this.customFilter;
        gameButton.text = new PIXI.extras.BitmapText("Button", {
            font: `${27}px montserrat`,
            tint: 0xFFFFFF,
            align: "center"
        });
        se_platform.fitInRect(gameButton.text, 0, -5, 200, 70);
        gameButton.addChild(gameButton.text);
        gameButton.setScale(1.25);

        return gameButton;
    }

    createIOCommsDisplay()
    {
        // console.log("create io display");
        this.roomServiceDisplay = new RoomServiceDisplay();
        this.roomServiceDisplay.visible = true;
        this.addChild(this.roomServiceDisplay);
    }

    /**
     * Show user icons
     */
    showRoomServiceDisplay(show = true)
    {
        if (show && !this.roomServiceDisplay)
        { this.createIOCommsDisplay(); }

        if (this.roomServiceDisplay)
        {
            this.roomServiceDisplay.visible = show;
            // this.roomServiceDisplay.updateStatus();
        }
    }

    onCustomButton(evt, index)
    {
        this.hideMenus();

        if (this.customButtons[index].method)
        {
            this.customButtons[index].method();
        }
    }

    /**
     *
     *
     * @param {string} title
     * @param {Function} method
     */
    addCustomButton(title, method)
    {
        console.log("Adding custom button", title, method);

        this.hasCustomButton = true;

        // check not already added
        for (const but of this.customButtons)
        {
            if (but.text.text === title)
            {
                return;
            }
        }

        this.setEyeToggle();

        const index = this.customButtons.length;
        const customBut = this.createCustomButton(index);

        customBut.method = method;

        this.customButtons.push(customBut);
        customBut.position.set(20, 260 + (index * 145));
        this.customButtonContainer.addChild(customBut);

        customBut.text.text = title;
        se_platform.fitInRect(customBut.text, 0, -5, 200, 70);

        this.updateCustomButtonPositions();

        // tint the buttons
        this.dark = this._dark;
    }

    updateCustomButtonPositions()
    {
        for (let i = 0; i < this.customButtons.length; i++)
        {
            this.customButtons[i].position.set(20, 260 + (i * 145));
        }
    }

    removeCustomButton(title)
    {
        let found = null;

        for (const but of this.customButtons)
        {
            if (but.text.text === title)
            {
                found = but;
            }
        }

        if (found)
        {
            this.customButtons.splice(this.customButtons.indexOf(found), 1);
            found.method = null;
            found.destroy({ children: true });
        }

        this.updateCustomButtonPositions();
    }

    addGameLinkButton()
    {
        if (this.gameLinkContainer)
        {
            if (this.gameLinkContainer.parent) this.customButtonContainer.removeChild(this.gameLinkContainer);
            this.gameLinkContainer.destroy({ children: true });
        }

        const gameLinkContainer = this.gameLinkContainer = new PIXI.Container();

        const message = this.gameLinkMessage = new PIXI.extras.BitmapText(Text.strings.roomCodeIs, { font: "30px montserrat", align: "center" });

        message.position.set(0, 0);
        gameLinkContainer.addChild(message);

        const secretWord = this.secretWordText = new PIXI.extras.BitmapText(se_platform.IO.RoomService.room._id,
            { font: "35px baloo", align: "center" });

        secretWord.y = 40;
        if (secretWord.width > 260)
        {
            secretWord.width = 260;
            secretWord.scale.y = secretWord.scale.x;
        }

        gameLinkContainer.addChild(secretWord);

        const copyButton = new PIXI.Sprite(PIXI.utils.TextureCache.copy_clipboard_icon);

        copyButton.y = 100;
        copyButton.x = (secretWord.width / 2) - (copyButton.width / 2);
        copyButton.interactive = true;
        copyButton.buttonMode = true;
        copyButton.on("pointerup", () =>
        {
            earthpixi.utils.CopyToClipboard(se_platform.IO.RoomService.room._id);

            copyButton.alpha = 0.5;
            earthpixi.Tween.kill(copyButton);
            earthpixi.Tween.to(copyButton, 0.5, { alpha: 1 });
        });

        gameLinkContainer.addChild(copyButton);

        earthpixi.utils.Display.anchor.bottom(gameLinkContainer, 40);
        gameLinkContainer.x = 30;

        this.secretWordText.tint = this._dark ? 0x222133 : 0xFFFFFF;
        this.gameLinkMessage.tint = this._dark ? 0x222133 : 0xFFFFFF;

        this.customButtonContainer.addChild(gameLinkContainer);
    }

    getBounds(){
        return earthpixi.utils.Display.getScreenBounds();
    }

    displayCover(){
        const bounds = this.getBounds();
        this.cover.width = earthpixi.config.STAGE_WIDTH;
        // this.cover.height = earthpixi.config.STAGE_WIDTH;
        console.log(`window height vs stage height: ${window.innerHeight} / ${earthpixi.config.STAGE_HEIGHT}`);
        this.cover.height = window.innerHeight;
        this.cover.x = 0;
        this.cover.tint = 0x222133;
        this._open = true;
        this.addChild(this.cover);
    }

    hideCover(){
        this.settingsChange();
        this.removeChild(this.cover);
        this._open = false;
    }

    showMenus(evt){
        if (earthpixi.currentScreen.onShowGameNav){
            earthpixi.currentScreen.onShowGameNav();
        }

        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;
        const bounds = earthpixi.utils.Display.getScreenBounds();

        this.cover.width = earthpixi.config.STAGE_WIDTH;
        this.cover.x = 0;
        this.cover.tint = this.dark ? 0xFFFFFF : 0x222133;

        this._open = true;

        this.addChild(this.cover);

        earthpixi.Access.soloButtons(
            [this.exitButton, this.backButton, this.collapseButton, this.musicButton, this.settingsButton].concat(this.customButtons)
        );

        // left menu
        if (this.leftMenuTween)
        {
            earthpixi.Tween.kill(this.leftMenuTween);
        }

        earthpixi.utils.Display.anchor.remove(this.leftMenuContainer);
        earthpixi.utils.Display.anchor.top(this.leftMenuContainer, 0);
        this.leftMenuContainer.x = -600;
        this.leftMenuTween = earthpixi.Tween.to(
            this.leftMenuContainer, 0.5,
            {
                x: 0 + bounds.left,
                onComplete: earthpixi.utils.Display.anchor.left,
                onCompleteParams: [this.leftMenuContainer, 0]
            }
        );
        this.leftCol.tint = earthpixi.config.BACKGROUND_COLOR;
        this.rightCol.tint = earthpixi.config.BACKGROUND_COLOR;
        this.addChild(this.leftMenuContainer);

        this.leftMenuContainer.height = bounds.bottom - bounds.top;
        this.leftMenuContainer.scale.x = this.leftMenuContainer.scale.y;

        // right menu
        if (this.rightMenuTween)
        {
            earthpixi.Tween.kill(this.rightMenuTween);
        }

        earthpixi.utils.Display.anchor.remove(this.rightMenuContainer);
        earthpixi.utils.Display.anchor.top(this.rightMenuContainer, 0);
        this.rightMenuContainer.height = bounds.bottom - bounds.top;
        this.rightMenuContainer.scale.x = this.rightMenuContainer.scale.y;
        this.rightMenuContainer.x = bounds.right + 300;
        const rightAnchorDist = this.rightCol.width * this.rightMenuContainer.scale.x;

        this.rightMenuTween = earthpixi.Tween.to(
            this.rightMenuContainer, 0.5,
            {
                x: bounds.right - rightAnchorDist + 5,
                onComplete: earthpixi.utils.Display.anchor.right,
                onCompleteParams: [this.rightMenuContainer, rightAnchorDist - 5, false]
            }
        );
        this.addChild(this.rightMenuContainer);
    }

    /**
     *
     * @param noTween
     */
    hideMenus(noTween = false)
    {
        this.settingsChange();

        earthpixi.Access.freeSoloButtons();

        this.removeChild(this.cover);

        this._open = false;

        if (this.leftMenuTween)
        {
            earthpixi.Tween.kill(this.leftMenuTween);
            earthpixi.Tween.kill(this.rightMenuTween);
        }

        earthpixi.utils.Display.anchor.remove(this.leftMenuContainer);

        if (noTween === false)
        {
            this.removeChild(this.leftMenuContainer);
            this.removeChild(this.rightMenuContainer);

            return;
        }

        this.leftMenuTween = earthpixi.Tween.to(
            this.leftMenuContainer, 0.5,
            {
                x: -600,
                onComplete: this.removeChild,
                onCompleteParams: [this.leftMenuContainer],
                onCompleteScope: this
            }
        );

        earthpixi.utils.Display.anchor.remove(this.rightMenuContainer);

        this.rightMenuTween = earthpixi.Tween.to(
            this.rightMenuContainer, 0.5,
            {
                x: earthpixi.config.STAGE_WIDTH + 305,
                onComplete: this.removeChild,
                onCompleteParams: [this.rightMenuContainer],
                onCompleteScope: this
            }
        );
    }

    /**
     *
     * @param evt
     */
    onExit(evt)
    {
        //  console.log("game menu exit");

        if (evt)
        {
            evt.stopPropagation();
        }

        this.hideMenus(true);

        this.backWarning = null;
        this.onBackMethod = null;
        se_platform.Navigation.home();
        this.hide();
    }

    /**
     *
     * @param evt
     */
    onBack(evt)
    {
        this.hideMenus();

        //  console.log("back press");

        if (evt)
        {
            evt.stopPropagation();
        }

        const exit = this.doExit;

        if (this.backWarning && exit)
        {
            this.hideMenus();
            // this.showBackWarnScreen();
            this.triggerExitWarning()
            return;
        }
        else if (this.newGameScreen)
        {
            this.hideMenus();
            this.showBackWarnScreen();

            return;
        }
        else if (this.screenToGoBackTo && exit)
        {
            this.hideMenus(true);
            this.hide();

            if (this.onBackMethod)
            {
                this.onBackMethod();
                this.onBackMethod = null;
            }

            se_platform.goToScreen(this.screenToGoBackTo.assetList, this.screenToGoBackTo, false, true, this.loadingScreenClass);
            this.loadingScreenClass = null;
            this.screenToGoBackTo = null;
            this.onCancelBackMethod = null;
        }
        else if (this.onBackMethod)
        {
            this.onBackMethod();
            this.onBackMethod = null;
        }
    }

    onEyeToggle()
    {
        earthpixi.Access.settings.eyeGazeConfig.directMode = !earthpixi.Access.settings.eyeGazeConfig.directMode;
        earthpixi.Access.directMode(earthpixi.Access.settings.eyeGazeConfig.directMode);

        this.settingsChange();

        se_platform.IO.saveSettings();
    }

    setEyeToggle()
    {
        // console.log("set toggle", this.state);

        if (earthpixi.Access.settings.eyeGazeActive && this.state === this.STATES.GAME_STATE
            // TODO permanent solution
            && !earthpixi.currentScreen.noDirectMode)
        {
            this.eyeRestButton.visible = true;
        }
        else
        {
            this.eyeRestButton.visible = false;
        }

        if (earthpixi.Access.settings.eyeGazeConfig.directMode)
        {
            this.eyeRestButton.texture = PIXI.utils.TextureCache.eye_icon_on_large;
            this.eyeRestButton.staticTex = PIXI.utils.TextureCache.eye_icon_on_large;
            this.eyeRestButton.hoverTex = PIXI.utils.TextureCache.eye_icon_on_large;
        }
        else
        {
            this.eyeRestButton.texture = PIXI.utils.TextureCache.eye_icon_off_large;
            this.eyeRestButton.staticTex = PIXI.utils.TextureCache.eye_icon_off_large;
            this.eyeRestButton.hoverTex = PIXI.utils.TextureCache.eye_icon_off_large;
        }
    }

    showBackWarnScreen()
    {
        // this.triggerExitWarning()
        this.confirmOverlay = new ConfirmOverlay(
            this._state === this.STATES.BACK_STATE || this._state === this.STATES.GAME_STATE ? Text.strings.checkBack : Text.strings.checkExit,
            this.onOK,
            this.onCancel,
            this,
            this._dark,
            Text.strings.ok,
            Text.strings.cancel
        );

        this.addChild(this.confirmOverlay);
    }

    onSettings()
    {
        console.log("OnSettings");

        this.hideMenus();

        this.cover.width = earthpixi.config.STAGE_WIDTH * 4;
        this.cover.x = -earthpixi.config.STAGE_WIDTH * 2;

        this.addChild(this.cover);

        this.settingsBox = new InGameSettings();
        // earthpixi.utils.Display.position.center(this.settingsBox);
        // this.settingsBox.x +=10;
        this.settingsBox.y = -50;
        this.settingsBox.on("done", this.onCancel, this);
        this.addChild(this.settingsBox);
    }

    /**
     *
     * @param {number} gameId
     */
    showOnlineGameOptions(gameId)
    {
        // console.log(gameType);

        this.hideMenus();

        this.cover.width = earthpixi.config.STAGE_WIDTH * 4;
        this.cover.x = -earthpixi.config.STAGE_WIDTH * 2;

        this.addChild(this.cover);

        this.onlineGameBox = new InGameOnlineOptions(gameId);
        // earthpixi.utils.Display.position.center(this.settingsBox);
        // this.settingsBox.x +=10;
        this.onlineGameBox.y = -50;
        this.onlineGameBox.on("exit", this.onCloseOnlineOptions, this);
        this.onlineGameBox.on("done", this.removeOnlineGameBox, this);

        this.addChild(this.onlineGameBox);
    }

    removeOnlineGameBox()
    {
        if (this.onlineGameBox)
        {
            if (this.cover && this.cover.parent)
            {
                this.removeChild(this.cover);
            }
            this.removeChild(this.onlineGameBox);
            this.onlineGameBox.destroy({ children: true });
        }
    }

    onCloseOnlineOptions()
    {
        if (this.cover && this.cover.parent)
        {
            this.removeChild(this.cover);
        }
        this.backWarning = false;
        this.onBack();
    }

    onMusic()
    {
        this.hideMenus();

        earthpixi.Audio.muteMusic();

        se_platform.User.musicMuted = earthpixi.Audio._muteMusic;
        // console.log("music setting changed", se_platform.User.musicMuted);

        se_platform.IO.saveSettings();

        this.musicButton.alpha = earthpixi.Audio._muteMusic ? 0.3 : 1;

        earthpixi.currentScreen.emit("musicSettingChanged");
    }

    onOK()
    {
        // console.log("onOk");
        this.backWarning = null;

        if (this.confirmOverlay)
        {
            this.confirmOverlay.done();
            this.confirmOverlay = null;
        }

        if (this.newGameScreen)
        {
            this.screenToGoBackTo = this.newGameScreen;
            this.newGameScreen = null;
        }

        if (this.onBackMethod)
        {
            console.log("onOk back method");
            this.onBackMethod();
            this.onBackMethod = null;
        }

        this.hideMenus(true);
        this.hide();

        // now decide where to navigate to;

        if (!this.screenToGoBackTo)
        {
            console.log("onOk go home");
            se_platform.Navigation.home();

            return;
        }

        if (this._state === this.STATES.BACK_STATE || this._state === this.STATES.GAME_STATE)
        {
            console.log("onOk go back");
            earthpixi.Audio.stopAllSFX();
            se_platform.goToScreen(this.screenToGoBackTo.assetList, this.screenToGoBackTo, false, true, this.loadingScreenClass);
            this.loadingScreenClass = null;
            se_platform.Navigation.clearQueries();

            return;
        }

        if (this._state === this.STATES.EXIT_STATE)
        {
            // this.onExit();
            this.triggerExitWarning();
        }
    }

    onCancel(resetting)
    {
        // console.log("cancel");
        if (this.cover && this.cover.parent)
        {
            this.removeChild(this.cover);
        }

        if (this.settingsBox)
        {
            this.removeChild(this.settingsBox);
            this.settingsBox.destroy({ children: true });
            if (!resetting)
            { this.settingsChange(); }
        }

        if (this.onlineGameBox)
        {
            this.removeChild(this.onlineGameBox);
            this.onlineGameBox.destroy({ children: true });
        }

        earthpixi.Access.freeSoloButtons();

        if (this.onCancelBackMethod && !resetting)
        {
            this.onCancelBackMethod();
            this.onCancelBackMethod = null;
        }

        if (this.confirmOverlay)
        { this.confirmOverlay.done(); }
    }

    settingsChange()
    {
        console.log('<gamemenu> settings change');

        this.setEyeToggle();

        this.toggleLargeButtons();

        if (earthpixi.currentScreen.onSettingsChange)
        {
            earthpixi.currentScreen.onSettingsChange();
        }
    }

    /**
     * Put menus away
     */
    hide(resetting)
    {
        // console.log('hiding');

        this.onCancel(resetting);

        if (this.leftMenuContainer.parent)
        {
            this.removeChild(this.leftMenuContainer);
        }
        if (this.rightMenuContainer.parent)
        {
            this.removeChild(this.rightMenuContainer);
        }

        if (this.cover && this.cover.parent)
        {
            this.removeChild(this.cover);
        }

        this.showRoomServiceDisplay(false);

        this.visible = false;
        // this.altBackButton.visible = false;
        this.customButtons.forEach((but) =>
        {
            but.visible = false;
        });

        this.musicButton.visible = true;

        if (this.gameLinkContainer)
        {
            this.gameLinkContainer.destroy({ children: true });
        }
    }

    /**
     * Slide out the menus
     */
    show(resetting = false)
    {
        console.log('<gamemenu> SHOWING GAME MENU!!!')
        // console.warn("show game menu");

        this.visible = true;

        earthpixi.Access.reset();

        this.toggleLargeButtons();

        this.musicButton.alpha = earthpixi.Audio._muteMusic ? 0.3 : 1;

        earthpixi.Access.add(this.exitButton);
        earthpixi.Access.add(this.burgerButton);
        earthpixi.Access.add(this.musicButton);
        earthpixi.Access.add(this.settingsButton);
        earthpixi.Access.add(this.altBackButton);
        earthpixi.Access.add(this.altExitButton);
        earthpixi.Access.add(this.eyeRestButton);
        earthpixi.Access.add(this.collapseButton);
        earthpixi.Access.add(this.backButton);

        earthpixi.Keyboard.addKey(27, this.onEscapeKey, null, null, this.onEscapeKey, this);

        this.customButtons.forEach((but) =>
        {
            earthpixi.Access.add(but);
        });
    }

    toggleLargeButtons()
    {
        // earthpixi.Access.reset();
        if (earthpixi.Access.settings.eyeGazeActive && !earthpixi.Access.settings.eyeGazeConfig.directMode)
        {
            console.log('<gamemenu> toggleLargeButtons() -> CASE A')
            // this.burgerButton.staticTex = PIXI.utils.TextureCache.burger_button_large;
            // this.burgerButton.hoverTex = PIXI.utils.TextureCache.burger_button_large;
            // this.burgerButton.texture = PIXI.utils.TextureCache.burger_button_large;
            this.burgerButton.staticTex = this.textures.menu;
            this.burgerButton.hoverTex = this.textures.menu;
            this.burgerButton.texture = this.textures.menu;
            // this.burgerButton.texture = PIXI.utils.TextureCache.menu;
            this.burgerButton.setScale(0.4);

            // this.altBackButton.staticTex = PIXI.utils.TextureCache.back_button_large;
            // this.altBackButton.hoverTex = PIXI.utils.TextureCache.back_button_large;
            // this.altBackButton.texture = PIXI.utils.TextureCache.back_button_large;
            // this.altBackButton.setScale(0.8);
            this.altBackButton.staticTex = this.textures.back;
            this.altBackButton.hoverTex = this.textures.back;
            this.altBackButton.texture = this.textures.back;
            this.altBackButton.setScale(0.4);

            // this.altExitButton.staticTex = PIXI.utils.TextureCache.exit_button_large;
            // this.altExitButton.hoverTex = PIXI.utils.TextureCache.exit_button_large;
            // this.altExitButton.texture = PIXI.utils.TextureCache.exit_button_large;
            this.altExitButton.staticTex = this.textures.close;
            this.altExitButton.hoverTex = this.textures.close;
            this.altExitButton.texture = this.textures.close;
            this.altExitButton.setScale(0.4);

            this.eyeRestButton.setScale(0.8);
        }
        else {
            console.log('<gamemenu> toggleLargeButtons() -> CASE B')
            this.burgerButton.staticTex = PIXI.utils.TextureCache.burger_button;
            this.burgerButton.hoverTex = PIXI.utils.TextureCache.burger_button;
            this.burgerButton.texture = PIXI.utils.TextureCache.burger_button;
            this.burgerButton.setScale(1);

            this.altBackButton.staticTex = PIXI.utils.TextureCache.back_button;
            this.altBackButton.hoverTex = PIXI.utils.TextureCache.back_button;
            this.altBackButton.texture = PIXI.utils.TextureCache.back_button;
            this.altBackButton.setScale(1);

            this.altExitButton.staticTex = PIXI.utils.TextureCache.exit_button;
            this.altExitButton.hoverTex = PIXI.utils.TextureCache.exit_button;
            this.altExitButton.texture = PIXI.utils.TextureCache.exit_button;
            this.altExitButton.setScale(1);

            this.eyeRestButton.setScale(0.5);
        }

        earthpixi.utils.Display.anchor.right(this.burgerButton, 20);
        earthpixi.utils.Display.anchor.top(this.burgerButton, 15);
        earthpixi.utils.Display.anchor.left(this.altBackButton, 20);
        earthpixi.utils.Display.anchor.top(this.altBackButton, 15);
        earthpixi.utils.Display.anchor.left(this.altExitButton, 20);
        earthpixi.utils.Display.anchor.top(this.altExitButton, 15);
        earthpixi.utils.Display.anchor.top(this.eyeRestButton, 15);
        earthpixi.utils.Display.anchor.left(this.eyeRestButton, 15);
    }
}

