import ServiceEvent from "../service/events/ServiceEvent";
import RoomEvent from "../service/room/events/RoomEvent";
import GameID from "../../config/GameID";

/**
 * @extends {earthpixi.GameObject}
 */
export default class RoomServiceDisplay extends earthpixi.GameObject
{
    constructor()
    {
        super();

        this.dontDestroy = true;

        // Cover / waiting overlay
        this.cover = new PIXI.Graphics();
        this.cover.beginFill(0xFFFFFF, 0.8);
        this.cover.drawRect(0, 0, 128, 128);
        this.cover = earthpixi.renderer.generateTexture(this.cover, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.cover = new PIXI.Sprite(this.cover);
        this.cover.width = earthpixi.config.STAGE_WIDTH * 4;
        this.cover.x = -earthpixi.config.STAGE_WIDTH * 2;
        this.cover.height = earthpixi.config.STAGE_HEIGHT;
        this.cover.interactive = true;
        this.cover.width = earthpixi.config.STAGE_WIDTH;
        this.cover.x = 0;
        // this.addChild(this.cover);

        this.cloudIcon = new PIXI.Sprite(PIXI.utils.TextureCache.io_waiting_large);
        earthpixi.utils.Display.position.center(this.cloudIcon);
        // earthpixi.Tween.to(cloudButton, 0.5, { alpha: 0.5, repeat: -1, yoyo: true });
        // this.addChild(this.cloudIcon);

        // //////////////////////////////////

        // local user (pink)
        this.localUserIcon = new PIXI.Sprite(PIXI.utils.TextureCache.user_pink);
        this.localUserIcon.y = 120;
        earthpixi.utils.Display.anchor.right(this.localUserIcon, 40);
        this.addChild(this.localUserIcon);

        this.localUserTurnArrow = new PIXI.Sprite(PIXI.utils.TextureCache.turn_indicator_pink);
        this.localUserTurnArrow.y = 128;
        earthpixi.utils.Display.anchor.right(this.localUserTurnArrow, 20);
        this.addChild(this.localUserTurnArrow);
        this.localUserTurnArrow.visible = false;

        // remote user (pink)
        this.remoteUserIcon = new PIXI.Sprite(PIXI.utils.TextureCache.user_purple);
        this.remoteUserIcon.y = 170;
        earthpixi.utils.Display.anchor.right(this.remoteUserIcon, 40);
        this.addChild(this.remoteUserIcon);

        const connectedCloud = new PIXI.Sprite(PIXI.utils.TextureCache.io_connected);

        connectedCloud.scale.set(0.5);
        connectedCloud.x = 20;
        connectedCloud.y = 24;
        this.remoteUserIcon.addChild(connectedCloud);

        // this.remoteUserIcon.alpha = 0.5;

        this.remoteUserTurnArrow = new PIXI.Sprite(PIXI.utils.TextureCache.turn_indicator_purple);
        this.remoteUserTurnArrow.y = 180;
        earthpixi.utils.Display.anchor.right(this.remoteUserTurnArrow, 20);
        this.addChild(this.remoteUserTurnArrow);
        this.remoteUserTurnArrow.visible = false;

        this.connectionIcon = new PIXI.Sprite(PIXI.utils.TextureCache.io_waiting);
        this.connectionIcon.scale.set(1);
        this.connectionIcon.y = 173;
        earthpixi.utils.Display.anchor.right(this.connectionIcon, 35);
        this.addChild(this.connectionIcon);

        this.player1ColIcon = earthpixi.utils.Draw.DrawCircle(10, 0xFFFFFF);
        this.player1ColIcon.y = 130;
        this.player1ColIcon.visible = false;
        this.addChild(this.player1ColIcon);
        earthpixi.utils.Display.anchor.right(this.player1ColIcon, 75);

        this.player2ColIcon = new PIXI.Sprite(this.player1ColIcon.texture);
        this.player2ColIcon.y = 175;
        this.player2ColIcon.visible = false;
        this.addChild(this.player2ColIcon);
        earthpixi.utils.Display.anchor.right(this.player2ColIcon, 75);

        // init RoomService
        se_platform.IO.RoomService.on(ServiceEvent.CONNECT, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(ServiceEvent.DISCONNECT, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(RoomEvent.ROOM_JOINED, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(RoomEvent.ROOM_UPDATE, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(RoomEvent.COLOR_PICKED, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(RoomEvent.OPPONENT_LEFT_ROOM, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(RoomEvent.OPPONENT_JOINED_ROOM, this.updateIOStatus, this);
        se_platform.IO.RoomService.on(RoomEvent.OPPONENT_GAME_ACTION, this.updateIOStatus, this);

        this.updateIOStatus();
    }

    updateIOStatus()
    {
        // console.log("update RoomServiceDisplay status", se_platform.IO.RoomService.roomState, se_platform.IO.RoomService.isConnected);
        // console.log("room state", se_platform.IO.RoomService.roomState);
        // console.log("isConnected", se_platform.IO.RoomService.isConnected);
        // console.log("inRoom", se_platform.IO.RoomService.inRoom);
        // console.log("opponent in room?", se_platform.IO.RoomService.opponentInRoom);

        this.showConnectingCover(!se_platform.IO.RoomService.isConnected);

        this.updateTurn();

        this.updateColours();

        this.updateRemoteUserConnectionIcon();

        if (se_platform.IO.RoomService.isConnected)
        {
            if (se_platform.IO.RoomService.inRoom)
            {
                // console.log(se_platform.IO.RoomService.roomState);
            }
            else
            {

                // TODO why not in room? show online game box?

            }
        }
    }

    /**
     * Check if user has colour assigned in the room and show, like in chess
     */
    updateColours()
    {
        this.player1ColIcon.visible = false;
        this.player2ColIcon.visible = false;
        const room = se_platform.IO.RoomService.room;

        // console.log("here", room.game.gameId, GameID.CONNECT4, room.myPlayer.colour);

        if (room.myPlayer && room.myPlayer.colour !== "")
        {
            this.player1ColIcon.visible = true;
            const myPlayer = room.myPlayer;
            // chess / checkers white and black

            if (
                room.game.gameId === GameID.CHESS
                || room.game.gameId === GameID.CHECKERS
            )
            {
                if (myPlayer.colour === "w")
                {
                    this.player1ColIcon.tint = 0xffffff;
                }
                if (myPlayer.colour === "b")
                {
                    this.player1ColIcon.tint = 0x000000;
                }
            }
            if (
                room.game.gameId === GameID.CONNECT4
            )
            {
                // connect 4 yellow and blue
                if (myPlayer.colour === "y")
                {
                    this.player1ColIcon.tint = 0xEFA300;
                }
                if (myPlayer.colour === "b")
                {
                    this.player1ColIcon.tint = 0x2300F7;
                }
            }
        }

        if (room.opponent && room.opponent.colour !== "")
        {
            this.player2ColIcon.visible = true;
            const opp = room.opponent;

            if (
                room.game.gameId === GameID.CHESS
                || room.game.gameId === GameID.CHECKERS
            )
            {
                if (opp.colour === "w")
                {
                    this.player2ColIcon.tint = 0xffffff;
                }
                if (opp.colour === "b")
                {
                    this.player2ColIcon.tint = 0x000000;
                }
            }
            if (
                room.game.gameId === GameID.CONNECT4
            )
            {
                if (opp.colour === "y")
                {
                    this.player2ColIcon.tint = 0xEFA300;
                }
                if (opp.colour === "b")
                {
                    this.player2ColIcon.tint = 0x2300F7;
                }
            }
        }
    }

    /**
     * decide what state the connection icon should be in
     */
    updateRemoteUserConnectionIcon()
    {
        if (this.connectionIconTween) this.connectionIconTween.kill();
        this.connectionIcon.alpha = 1;
        // connected / in room
        if (se_platform.IO.RoomService.opponentInRoom)
        {
            // this.connectionIcon.texture = PIXI.utils.TextureCache.io_waiting;
            //
            // if (se_platform.IO.RoomService.myTurn)
            // {
            // this.connectionIcon.texture = PIXI.utils.TextureCache.io_connected;
            // }

            this.connectionIcon.visible = false;
            this.remoteUserIcon.visible = true;
        }
        else
        {
            this.connectionIcon.visible = true;
            this.remoteUserIcon.visible = false;

            this.connectionIcon.texture = PIXI.utils.TextureCache.io_waiting;
            this.connectionIcon.alpha = 1;
            this.connectionIconTween = earthpixi.Tween.to(this.connectionIcon, 0.5, { alpha: 0.5, repeat: -1, yoyo: true });
        }
    }

    /**
     * decide if need to turn on turn indicator next to player icon
     */
    updateTurn()
    {
        const myTurn = se_platform.IO.RoomService.myTurn;

        if (myTurn === null) return;

        if (myTurn)
        {
            this.remoteUserTurnArrow.visible = false;
            this.localUserTurnArrow.visible = true;
            this.localUserTurnArrow.alpha = 1;
            // this._turnTween = earthpixi.Tween.to(this.localUserTurnArrow, 0.5, { alpha: 0.5, yoyo: true, repeat: -1 });
        }
        else
        {
            this.remoteUserTurnArrow.visible = true;
            this.localUserTurnArrow.visible = false;
            this.remoteUserTurnArrow.alpha = 1;
            // this._turnTween = earthpixi.Tween.to(this.remoteUserTurnArrow, 0.5, { alpha: 0.5, yoyo: true, repeat: -1 });
        }
    }

    /**
     *
     * @param {boolean} show
     */
    showConnectingCover(show)
    {
        // console.warn("show cover", show, this.cover.parent);
        if (!show)
        {
            if (this.cover.parent)
            {
                this.removeChild(this.cover);
                this.removeChild(this.cloudIcon);
            }
        }
        else
        {
            this.cover.tint = 0x222133; // se_platform.GameMenu.dark ? 0xFFFFFF : 0x222133;
            this.addChild(this.cover);
            this.addChild(this.cloudIcon);
            if (this.cloudTween) this.cloudTween.kill();
            this.cloudTween = earthpixi.Tween.to(this.cloudIcon, 0.5, { alpha: 0.5, repeat: -1, yoyo: true });
        }
    }
}
