/**
 * A model for how to read hashed URLs,  in case we want to change the order around
 *
 * example URL:
 *
 * eyegazegames.com/#/game/location/mode/?gid=646
 *
 *
 */
import URLAddressPart from "./enums/URLAddressPart";

export default class URLScheme
{
    public static DATA_ORDER: string[] = [
        URLAddressPart.GAME,
        URLAddressPart.LOCATION,
        URLAddressPart.MODE,
    ];
}
