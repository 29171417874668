import ServerSettings from "../config/ServerSettings";
/**
 *  Initiate earthpixi and start app
 *
 * @param {object} startUpConfig
 */

function startApp(startUpConfig)
{
    console.log("startup", startUpConfig);

    const cdn = startUpConfig.cdn || "./";

    if (startUpConfig.server)
    {
        ServerSettings.url = startUpConfig.server;
        console.log("server", ServerSettings.url);
    }
    // framework CONFIG
    earthpixi.config.ROOT = `${cdn}`;
    earthpixi.config.WORKER_ROOT = `./workers/`;
    earthpixi.config.FW_ROOT = `${cdn}framework/`;
    earthpixi.config.DATA_ROOT = `${cdn}assets/data/`;
    earthpixi.config.IMG_ROOT = `${cdn}assets/img/`;
    earthpixi.config.FONT_ROOT = `${cdn}framework/font/`;
    earthpixi.config.FONT_ROOT_OLD = `${cdn}framework/fnt/`;
    earthpixi.config.AUDIO_ROOT = `${cdn}assets/snd/`;
    earthpixi.config.VIDEO_ROOT = `${cdn}assets/vid/`;
    earthpixi.config.SPINE_ROOT = `${cdn}assets/animation/`;
    earthpixi.config.VFX_ROOT = `${cdn}assets/vfx/`;

    earthpixi.config.BACKGROUND_COLOR = 0x222133;
    earthpixi.config.FADE_TIME = 0.5;
    earthpixi.config.FORCE_CANVAS = startUpConfig.forceCanvas || false;
    // earthpixi.config.FORCE_RES = 2;

    earthpixi.config.STAGE_WIDTH = 1366;
    earthpixi.config.STAGE_HEIGHT = 768;
    // earthpixi.config.SHOW_GRID = true;

    earthpixi.Keyboard.KeyCodesDict[32] = "Spacebar";

    window.TweenMax.defaultEase = window.Expo.easeOut;

    // sort height and show footer
    earthpixi.resizePreFix = () =>
    {
        let h = window.innerHeight;

        if (earthpixi.showCW)
        {
            h -= 20;
            if (se_platform.watermark) se_platform.watermark.visible = false;
            document.getElementById("footer").style.display = "block";
            document.getElementById("app_container").style.height = `${h - 20}px`;
        }
        else
        {
            if (se_platform.watermark) se_platform.watermark.visible = true;
            document.getElementById("footer").style.display = "none";
        }
        document.getElementById("app_container").style.height = `${h}px`;
    };

    earthpixi.init(document.getElementById("game"));

    // fonts
    earthpixi.globalAssetsList.push({
        name: "baloo",
        url: `${earthpixi.config.FONT_ROOT_OLD}baloo${earthpixi.resolution}x.fnt`
    });
    earthpixi.globalAssetsList.push({
        name: "baloo_small",
        url: `${earthpixi.config.FONT_ROOT_OLD}baloo_small${earthpixi.resolution}x.fnt`
    });
    earthpixi.globalAssetsList.push({
        name: "montserrat",
        url: `${earthpixi.config.FONT_ROOT}montserrat/montserrat${earthpixi.resolution}x.fnt`
    });
    earthpixi.globalAssetsList.push({
        name: "montserrat_small",
        url: `${earthpixi.config.FONT_ROOT}montserrat/montserrat_small${earthpixi.resolution}x.fnt`
    });
    earthpixi.globalAssetsList.push({
        name: "concert_one",
        url: `${earthpixi.config.FONT_ROOT_OLD}concert_one${earthpixi.resolution}x.fnt`
    });

    // game nav menu
    earthpixi.globalAssetsList.push({
        name: "settings_menu0",
        url: `${earthpixi.config.IMG_ROOT}menu/menu0@${earthpixi.resolution}x.json`
    });
    earthpixi.globalAssetsList.push({
        name: "settings_menu1",
        url: `${earthpixi.config.IMG_ROOT}menu/menu1@${earthpixi.resolution}x.json`
    });
    earthpixi.globalAssetsList.push({
        name: "settings_menu2",
        url: `${earthpixi.config.IMG_ROOT}menu/menu2@${earthpixi.resolution}x.json`
    });
    earthpixi.globalAssetsList.push({
        name: "settings_menu3",
        url: `${earthpixi.config.IMG_ROOT}menu/menu3@${earthpixi.resolution}x.json`
    });
    earthpixi.globalAssetsList.push({
        name: "egg_ui",
        url: `${earthpixi.config.IMG_ROOT}menu/egg_ui.json`
    });

    se_platform.LocalStorage.load();
    se_platform.Navigation.init();
    se_platform.Navigation.navigate(startUpConfig.game);
    se_platform.Navigation.navActive = true;
    
    setTimeout(() => {
        earthpixi.Access.clickSound = `${earthpixi.config.AUDIO_ROOT}sfx/click.mp3`;
        earthpixi.Access.settings.switchConfig.rowSelectorMode = true;
        earthpixi.Audio._musicVolume = 0.5;
    
    }, 1000)
}

export default startApp;
