export default class MenuCounter extends earthpixi.GameObject
{
    constructor(screen, configObj, prop, prependTxt, appendTxt, limits, incr)
    {
        super(screen);

        const txtOptions = { font: "15px montserrat", align: "left" };

        this.prepend = prependTxt || "";
        this.append = appendTxt || "";
        this.incr = incr || 1;
        this.configObj = configObj;
        this.prop = prop;

        this.text = new PIXI.extras.BitmapText(`${this.prepend} ${this.configObj[this.prop]} ${this.append}`, txtOptions);
        this.text.tint = 0xFFFF00;
        this.addChild(this.text);

        this.limits = limits;

        const minus = this.minus = new PIXI.Sprite(PIXI.utils.TextureCache.minus_button);

        minus.interactive = true;
        minus.buttonMode = true;
        minus.on("pointerdown", this.onMinus, this);
        minus.on("pointerup", this.onUp, this);
        minus.on("pointerupoutside", this.onUp, this);
        minus.x = this.text.width + 20;
        minus.y = -2;
        this.addChild(minus);

        const plus = this.plus = new PIXI.Sprite(PIXI.utils.TextureCache.plus_button);

        plus.interactive = true;
        plus.buttonMode = true;
        plus.on("pointerdown", this.onPlus, this);
        plus.on("pointerup", this.onUp, this);
        plus.on("pointerupoutside", this.onUp, this);
        plus.x = this.text.width + 50;
        plus.y = -2;
        this.addChild(plus);

        this.updateText();
    }

    updateText()
    {
        this.text.text = `${this.prepend} ${this.configObj[this.prop]} ${this.append}`;

        if (this.limits)
        {
            if (this.configObj[this.prop] === this.limits[1])
            {
                this.plus.alpha = 0.5;
                this.plus.interactive = false;
                this.onUp();
            }
            else
            {
                this.plus.alpha = 1;
                this.plus.interactive = true;
            }

            if (this.configObj[this.prop] === this.limits[0])
            {
                this.minus.alpha = 0.5;
                this.minus.interactive = false;
                this.onUp();
            }
            else
            {
                this.minus.alpha = 1;
                this.minus.interactive = true;
            }
        }

        this.emit("change", this.configObj[this.prop]);
    }

    doPlus()
    {

        const multiplier = Math.pow(10, 1 || 0);
        const newVal = this.configObj[this.prop] + this.incr;

        // console.log(multiplier, this.configObj[this.prop], this.incr, newVal, this.limits[1]);

        if (this.limits)
        {
            if (this.configObj[this.prop] < this.limits[1])
            {
                this.configObj[this.prop] = Math.round(newVal * multiplier) / multiplier;
                this.updateText();
            }
        }
        else
        {
            this.configObj[this.prop] = Math.round(newVal * multiplier) / multiplier;
            this.updateText();
        }
    }

    doMinus()
    {
        const multiplier = Math.pow(10, 1 || 0);
        const newVal = this.configObj[this.prop] - this.incr;

        if (this.limits)
        {
            if (this.configObj[this.prop] > this.limits[0])
            {
                this.configObj[this.prop] = Math.round(newVal * multiplier) / multiplier;
                this.updateText();
            }
        }
        else
        {
            this.configObj[this.prop] = Math.round(newVal * multiplier) / multiplier;
            this.updateText();
        }
    }

    onPlus(evt)
    {
        this.doPlus();
        this.quickUpdateIsPlus = true;
        if (this.plus.interactive)
        { this.heldInterval = setInterval(this.onHeld.bind(this), 500); }
    }

    onMinus(evt)
    {
        this.doMinus();
        this.quickUpdateIsPlus = false;
        if (this.minus.interactive)
        { this.heldInterval = setInterval(this.onHeld.bind(this), 500); }
    }

    onUp()
    {
        if (this.heldInterval)
        {
            clearInterval(this.heldInterval);
            this.heldInterval = null;
        }

        if (this.quickUpdateInterval)
        {
            clearInterval(this.quickUpdateInterval);
            this.quickUpdateInterval = null;
        }
    }

    onHeld()
    {
        clearInterval(this.heldInterval);
        this.heldInterval = null;

        this.quickUpdateInterval = setInterval(this.quickUpdateIsPlus ? this.doPlus.bind(this) : this.doMinus.bind(this), 100);
    }

    destroy(options)
    {
        this.onUp();
        super.destroy(options);
    }
}
