import GameModel from "./GameModel";
import PlayerModel from "./PlayerModel";
import RoomState from "./RoomState";

/**
 *
 */
export default class RoomModel
{
    /**
     *
     */
    public _id: string = "";
    /**
     *
     */
    public title: string = "";
    /**
     *
     */
    public owner: string = "";
    /**
     *
     */
    public encrypted: boolean = false;

    /**
     *
     */
    public state: RoomState = RoomState.WAITING_FOR_PLAYER;
    /**
     *
     */
    public endpoint: string = "";
    /**
     *
     */
    public connectedPlayerIds: string[] = [];
    /**
     *
     */
    public player1: PlayerModel = new PlayerModel();
    /**
     *
     */
    public player2: PlayerModel = new PlayerModel();
    /**
     *
     */
    public completed: boolean = false;
    /**
     *
     */
    public game: GameModel = new GameModel();

    /**
     * Should the room be hidden from public list
     */
    public isPrivate: boolean = true;

    /**
     *
     * @param {RoomModel} room
     */
    public copy(room: RoomModel)
    {
        // console.log("copy room", room, this);
        this._id = room._id !== undefined ? room._id : this._id;
        this.title = room.title !== undefined ? room.title : this.title;
        this.owner = room.owner !== undefined ? room.owner : this.owner;
        this.encrypted = room.encrypted !== undefined ? room.encrypted : this.encrypted;
        this.state = room.state !== undefined ? room.state : this.state;
        this.endpoint = room.endpoint !== undefined ? room.endpoint : this.endpoint;
        this.connectedPlayerIds = room.connectedPlayerIds !== undefined ? room.connectedPlayerIds : this.connectedPlayerIds;
        this.player1.copy(room.player1);
        this.player2.copy(room.player2);
        this.completed = room.completed !== undefined ? room.completed : this.completed;
        this.game.copy(room.game);
        this.isPrivate = room.isPrivate !== undefined ? room.isPrivate : this.isPrivate;
    }

    public get myPlayer(): PlayerModel | null
    {
        if (se_platform.User.username === this.player1.username) { return this.player1; }
        if (se_platform.User.username === this.player2.username) { return this.player2; }

        return null;
    }

    public get opponent(): PlayerModel | null
    {
        if (this.player1._id !== "" && se_platform.User.username !== this.player1.username) { return this.player1; }
        if (this.player2._id !== "" && se_platform.User.username !== this.player2.username) { return this.player2; }

        return new PlayerModel();
    }

    /**
     * For server to create schema
     */
    public toJSON(): object
    {
        const obj = { ...this };

        obj.player1 = this.player1.toJSON();
        obj.player2 = this.player2.toJSON();
        obj.game = this.game.toJSON();

        // any other tweaks
        return obj;
    }

    public ourTurn(): boolean
    {
        return (this.player1.username === se_platform.User.username && this.state === RoomState.PLAYER1_TURN)
               || (this.player2.username === se_platform.User.username && this.state === RoomState.PLAYER2_TURN);
    }

    public switchTurn(): void
    {
        if (this.player1.username === se_platform.User.username && this.state === RoomState.PLAYER1_TURN)
        {
            this.state = RoomState.PLAYER2_TURN;
        }

        if (this.player2.username === se_platform.User.username && this.state === RoomState.PLAYER2_TURN)
        {
            this.state = RoomState.PLAYER1_TURN;
        }
    }

    public resign(): void
    {
        if (this.player1.username === se_platform.User.username)
        {
            this.state = RoomState.PLAYER1_RESIGNED;
        }

        if (this.player2.username === se_platform.User.username)
        {
            this.state = RoomState.PLAYER2_RESIGNED;
        }

        console.log("room resigned", this.state);
    }
}
