import { EventEmitter } from "eventemitter3";
import socketIoClient from "socket.io-client";
import PlayerModel from "../model/PlayerModel";
import RoomModel from "../model/RoomModel";
import ServiceEvent from "../service/events/ServiceEvent";
import ServerSettings from "../../config/ServerSettings";

/**
 *
 * @extends {EventEmitter}
 */
export default class SocketService extends EventEmitter
{
    /**
     * @type {boolean}
     */
    public get isConnected(): boolean
    {
        return this._isConnected;
    }

    protected get socket(): SocketIOClient.Socket
    {
        return this._socket;
    }

    /**
     * @type {string}
     */
    protected get id(): string
    {
        let id = "-1";

        if (this._socket) { id = this._socket.id; }

        return id;
    }

    protected readonly _socket: SocketIOClient.Socket;

    private _isConnected: boolean = false;

    /**
     *
     * @param server
     */
    protected constructor(urlAndNamespace:string)
    {
        super();

        //console.log("AttemptToConnectToSocket...");
        // console.log("socket connect", urlAndNamespace);
        this._socket = socketIoClient(urlAndNamespace, {transports: ["polling", "websocket"], withCredentials:false});

        this._socket.on("reconnect_attempt", () =>
        {
            this._socket.io.opts.transports = ["polling", "websocket"];
        });

        this.resetListeners();
    }

    public resetListeners()
    {
        this._socket.removeAllListeners();
        this._socket.on(ServiceEvent.CONNECT, this.onConnected.bind(this));
        this._socket.on(ServiceEvent.DISCONNECT, this.onDisconnect.bind(this));
        this._socket.on(ServiceEvent.ERROR, this.onError.bind(this));
    }

    private onError(error: any): void
    {
        console.log("error hit at socketService", error);
        this.emit(ServiceEvent.ERROR, error);
    }

    private onConnected(): void
    {
        // console.log("client connected");
        this._isConnected = true;
        this.emit(ServiceEvent.CONNECT);
    }

    private onDisconnect(): void
    {
        // console.log("client disconnected");
        this._isConnected = false;
        this.emit(ServiceEvent.DISCONNECT);
    }

    /*
    *
    * Send actions if you want the room to know about something that your player/client has done (x and y position change for example)
    *
    */
    // public action(action: string = "updated", game: string, room: string, player: PlayerModel): void {
    //     this._socket.emit(ServiceEvent.ACTION, {action, game, room, player});
    // }

    /**
     * Send globals if you want the room to know about (global) data changes
     *
     * @param {string} global
     * @param {string} game
     * @param {string} room
     * @param {string} update
     */
    // public global(global: string, game: string, room: string, update: any): void {
    //     this._socket.emit(ServiceEvent.GLOBAL, {global, game, room, update});
    // }

    // /**
    //  *
    //  * @param game
    //  * @param room
    //  * @param player
    //  */
    // public joinRoom(game: String, room: string, player: PlayerModel): void {
    //     this._socket.emit(ClientEvent.JOIN_ROOM, {game: game, room: room, player: player});
    // }

    /*
    *
    * Subscribe to actions from other clients
    *
    */
    // protected onAction(action: string, handler: (n: PlayerModel) => void, handlerContext: any, once: boolean = false): void {
    //     if (!once) { this._socket.on(action, handler.bind(handlerContext)); } else { this._socket.once(action, handler.bind(handlerContext)); }
    // }

    /*
    *
    * Subscribe to globals from other clients
    *
    */
    // protected onGlobal(global: string, handler: (n: RoomModel) => void, handlerContext: any, once: boolean = false): void {
    //     if (!once) { this._socket.on(global, handler.bind(handlerContext)); } else { this._socket.once(global, handler.bind(handlerContext)); }
    // }
}
