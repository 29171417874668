/**
 *
 */
import LevelProgress from "./LevelProgress";

// Game Model shared by Server and Games (need to init all values for Mongoose Schema)

export default class GameModel
{
    /**
     * @type {string} dont init, mongoose will init this in db when used by schema
     */
    public _id : string;
    /**
     * @type {string}
     */
    public owner: string = "";
    /**
     * @type {string}
     */
    public username: string = "";
    /**
     * @type {GameID} the game type from GameID
     */
    public gameId: number = 0;
    /**
     * @type {number}
     */
    public level: number = 0;
    /**
     * @type {number}
     */
    public levelBest: number = 0;
    /**
     * @type {number}
     */
    public zone: number = 0;
    /**
     * @type {number}
     */
    public zoneBest: number = 0;
    /**
     * @type {boolean}
     */
    public quick: boolean = false;
    /**
     * @type {string}
     */
    public difficulty: string = "easy";
    /**
     * @type {string}
     */
    public board_state: string = "";
    /**
     * @type {string}
     */
    public previous_board_state: string = "";
    /**
     * @type {string}
     */
    public last_move: string = "";
    /**
     * @type {boolean}
     */
    public tutorialPlayed: boolean = false;
    /**
     * @type {LevelProgress[]}
     */
    public levelProgress: LevelProgress[] = [new LevelProgress("", 0, 0, 0, 0, [], false, "")];
    /**
     * List of achievement ids for a game if needed
     *
     * @type {LevelProgress[]}
     */
    public achievements: string[] = [];
    /**
     *
     * Settings specific to the game type (stringified json)
     * @type {string}
     */
    public settings: string = "{}";

    /**
     *
     * another unique id to use to obfuscate scores to prevent cheating
     * @type {string}
     */
    public seed: string = "";

    /**
     *
     * @param {GameModel} game
     */
    public copy(game: GameModel)
    {
        // console.log("game copy", game.previous_board_state? "has state" : "no state", game.previous_board_state);

        this._id = game._id !== undefined ? game._id : this._id;
        this.owner = game.owner !== undefined ? game.owner : this.owner;
        this.gameId = game.gameId !== undefined ? game.gameId : this.gameId;
        this.quick = game.quick !== undefined ? game.quick : this.quick;
        this.level = game.level !== undefined ? game.level : this.level;
        this.levelBest = game.levelBest !== undefined ? game.levelBest : this.levelBest;
        this.zone = game.zone !== undefined ? game.zone : this.zone;
        this.zoneBest = game.zoneBest !== undefined ? game.zoneBest : this.zoneBest;
        this.tutorialPlayed = game.tutorialPlayed !== undefined ? game.tutorialPlayed : this.tutorialPlayed;
        this.difficulty = game.difficulty !== undefined ? game.difficulty : this.difficulty;
        this.board_state = game.board_state !== undefined ? game.board_state : this.board_state;
        this.previous_board_state = game.previous_board_state !== undefined ? game.previous_board_state : this.previous_board_state;
        this.last_move = game.last_move !== undefined ? game.last_move : this.last_move;
        this.achievements = game.achievements !== undefined ? game.achievements : this.achievements;
        this.settings = game.settings !== undefined ? game.settings : this.settings;
        this.seed = game.seed !== undefined ? game.seed : this.seed;

        if (game.levelProgress && game.levelProgress.length)
        {
            for (const level of game.levelProgress)
            {
                if (level.id !== "")
                {
                    const levelState = new LevelProgress(level.id, level.level, level.zone, level.score, level.highScore, level.highScores, level.completed, level.state);
                    
                    this.levelProgress.push(levelState);
                }
            }
        }
    }

    public convert(score: any): any
    {
        if (typeof score === "string" || score instanceof String)
        {
            return parseInt(atob(score as string).replace(this._id, ""), 10);
        }

        return btoa(this._id + score);
    }

    /**
     * used to create mongo schema
     */
    public toJSON()
    {
        const props = { ...this };

        props.levelProgress = [];
        for (const level of this.levelProgress)
        {
            props.levelProgress.push(level.toJSON());
        }

        return props;
    }
}
