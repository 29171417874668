import OptionButton from "../onboarding/OptionButton";
import strings from "./strings";

export default class MultipleChoiceQuestion extends earthpixi.GameObject
{
    constructor(question, answers, alowMultipleAnswers = false)
    {
        super();

        this.question = question;
        this.multipleAnswers = alowMultipleAnswers;

        if (alowMultipleAnswers)
        {
            question += `  (${strings.selectMoreThanOne})`;
        }
        else if (answers.length > 1) // TODO hacky if only one answer its confirmation at the end
        {
            question += `  (${strings.selectOne})`;
        }

        const titleText = new PIXI.extras.BitmapText(question, {
            font: "26px montserrat",
            tint: 0xFFFFFF,
            align: "left"
        });

        titleText.maxWidth = 700;
        titleText.position.set(0, 0);
        this.addChild(titleText);

        this.buttonsContainer = new PIXI.Container();
        this.addChild(this.buttonsContainer);

        this.buttons = [];

        let startY = 0;
        let startX = 0;

        for (let i = 0; i < answers.length; i++)
        {
            const txt = answers[i];
            const q = new OptionButton(
                txt,
                PIXI.utils.TextureCache.game_button,
                PIXI.utils.TextureCache.game_button_on,
                txt,
                false,
                true
            );

            q.addEvent("select", this.onSelect, this, txt);
            q.addEvent("toggleOff", this.toggleOff, this);
            // q.scale.set(0.8);
            q.interactive = false;
            this.buttons.push(q);
            q.index = i;
            q.answer = txt;
            q.x = startX;
            q.y = startY;
            this.buttonsContainer.addChild(q);

            startX += 240;

            if (i === 4)
            {
                startY += 220;
                startX = 0;
            }
        }
        se_platform.fitInRect(this.buttonsContainer, 0, 110, 750, 320, true);
    }

    enable()
    {
        for (let i = 0; i < this.buttons.length; i++)
        {
            this.buttons[i].interactive = true;
        }

        if (this.playButton)
        {
            this.playButton.interactive = true;
        }
    }

    onSelect(evt, data)
    {
        //console.log("select");

        const selected = evt.data.target || evt.currentTarget;
        let answer = selected.index;

        if (!this.multipleAnswers)
        {
            for (let i = 0; i < this.buttons.length; i++)
            {
                const but = this.buttons[i];

                if (but.toggled)
                {
                    but.toggle(false);
                }
            }
            selected.toggle(true);
        }
        else
        {
            answer = [];
            for (let i = 0; i < this.buttons.length; i++)
            {
                const but = this.buttons[i];

                if (but.toggled)
                {
                    answer.push(but.index);
                }
            }
        }

        this.emit("answered", answer);
    }

    toggleOff()
    {
        let somethingSelected = false;

        for (let i = 0; i < this.buttons.length; i++)
        {
            const but = this.buttons[i];

            if (but.toggled)
            {
                somethingSelected = true;
                break;
            }
        }

        if (!somethingSelected)
        {
            this.emit("notReady");
        }
    }

    getAnswers()
    {
        const answers = [];
        // get toggled buttons destroy and emit

        for (let i = 0; i < this.buttons.length; i++)
        {
            if (this.buttons[i].toggled)
            {
                answers.push(this.buttons[i].answer);
            }
        }

        return answers;
    }
}
