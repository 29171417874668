import GameID from "../../../config/GameID";
import IORoomScreen from "../../interfaces/IORoomScreen";
import GameModel from "../../model/GameModel";
import RoomModel from "../../model/RoomModel";
import RoomState from "../../model/RoomState";
import ServiceEvent from "../../service/events/ServiceEvent";
import SocketService from "../../service/SocketService";
import RoomEvent from "../room/events/RoomEvent";

/**
 *
 * Game service to request actions from api,
 * only one request at a time (responses reset listeners attached)
 *
 *
 * - joinRoom - create new or join existing room if allowed
 *
 * - roomNotAllowed - show message room is full or user not allowed
 *
 *
 * - get public rooms for public list to join
 *
 *
 *
 *
 * @class
 * @extends {SocketService}
 *
 */
export default class RoomService extends SocketService
{
    /**
     * {boolean}
     */
    public get inRoom(): boolean
    {
        return this._connectedToRoom;
    }

    /**
     * {RoomModel}
     */
    public get room(): RoomModel
    {
        return this._currentRoom;
    }

    /**
     * {boolean}
     */
    public get opponentInRoom(): boolean
    {
        return this._opponentInRoom;
    }

    public get myTurn(): boolean | null
    {
        if (!this.room) { return null; }

        if (this.room.state === RoomState.PLAYER1_TURN
            || this.room.state === RoomState.PLAYER2_TURN
        )
        {
            // it is someones turn
            if (this.room.state === RoomState.PLAYER1_TURN && this.room.player1.username === se_platform.User.username)
            {
                return true;
            }
            else if (this.room.state === RoomState.PLAYER2_TURN && this.room.player2.username === se_platform.User.username)
            {
                return true;
            }

            return false;
        }
        else if (this.room.state === RoomState.WAITING_FOR_PLAYER && this.room.myPlayer)
        {
            return this.room.owner === this.room.myPlayer._id;
        }

        return null;
    }

    /**
     * {RoomState}
     */
    public get roomState(): RoomState
    {
        return this._currentRoom.state;
    }

    public get shouldUpdateGame(): boolean
    {
        return this._updateGame;
    }

    public set shouldUpdateGame(bool: boolean)
    {
        this._updateGame = bool;
    }

    public static notificationSupported(): boolean
    {
        return (
            "Notification" in window
            && "serviceWorker" in navigator
            && "PushManager" in window
        );
    }

    public static closeNotification()
    {
        if (RoomService._instance.currentNotification)
        {
            RoomService._instance.currentNotification.close();
            RoomService._instance.currentNotification = null;
        }
    }

    private static _instance: RoomService;

    public currentNotification: Notification|null = null;

    private _busy: boolean = false;
    private _prevState: RoomState = RoomState.WAITING_FOR_PLAYER;
    private _updateGame: boolean = true;
    private _connectedToRoom: boolean = false;
    private _currentGameScreen: IORoomScreen | null;
    private _currentRoom: RoomModel;
    private _opponentInRoom: boolean;
    private _opponentUserName: string;

    /**
     *
     * @param serverUrl
     */
    constructor(serverUrl: string)
    {
        super(`${serverUrl}eyegazegames-rooms`);

        RoomService._instance = this;

        this.reset();
    }

    /**
     *
     * @param gameId
     * @param callback
     * @param callbackScope
     */
    public getMyRooms(gameId: Number, callback: (error: string, rooms: RoomModel[], roomClientLists: Array<{roomId: string, connectedUsers: string[]}>) => void, callbackScope: Object): void
    {
        this._socket.emit(RoomEvent.GET_ROOMS, gameId, se_platform.User._id, (error: string, rooms: RoomModel[], roomClientLists: Array<{roomId: string, connectedUsers: string[]}>) =>
        {
            callback.call(callbackScope, error, rooms, roomClientLists);
        });
    }

    /**
     * Ask server to create a new room and return model
     *
     * If you pass roomId as null, server creates new Room with you as Player 1 (your turn)
     *
     * @param roomId
     * @param gameId
     * @param gotRoomCallBack
     * @param callBackContext
     * @param [gotRoomCallBack]
     * @param [noRoomCallBack]
     * @param [roomNotAllowed]
     */
    public joinRoom(roomId: string | null, gameId: GameID, gotRoomCallBack?: (room: RoomModel) => void, noRoomCallBack?: () => void, roomNotAllowed?: () => void, callBackContext?: any): void
    {
        if (this._busy)
        {
            console.warn(" - RoomService socket Busy");

            return;
        }

        this._busy = true;

        // if we're creating new room create a fresh room model
        this._currentRoom = new RoomModel();

        // listen for socket comebacks
        this.socket.once(RoomEvent.ROOM_JOINED, (room: RoomModel, opponentInRoom: boolean, opponentUsername: string) =>
        {
            this.reset();

            console.log("Room joined", room, opponentInRoom, opponentUsername);

            this._currentRoom.copy(room);

            this.socket.on(RoomEvent.ROOM_UPDATE, (room: RoomModel, opponentJoined: boolean, opponentUsername: string) => { this.roomUpdate(room, opponentJoined, opponentUsername); });

            this.socket.on(RoomEvent.OPPONENT_LEFT_ROOM, (opponentUsername: string) => { this.opponentLeftRoom(opponentUsername); });

            this.socket.on(RoomEvent.ROOM_DELETED, (username: string) => { this.opponentDeletedRoom(username); });

            this.joinedRoom(this._currentRoom, opponentInRoom, opponentUsername);

            if (gotRoomCallBack) { gotRoomCallBack.call(callBackContext, room); }
        });

        if (noRoomCallBack)
        {
            this.socket.once(RoomEvent.ROOM_NOT_FOUND, () =>
            {
                noRoomCallBack.call(callBackContext);
                this.resetListeners();
                this.reset();
            });
        }

        if (roomNotAllowed)
        {
            this.socket.once(RoomEvent.ROOM_NOT_ALLOWED, () =>
            {
                roomNotAllowed.call(callBackContext);
                this.resetListeners();
                this.reset();
            });
        }

        // send request
        this.socket.emit(RoomEvent.JOIN_ROOM, roomId, gameId, se_platform.User._id, se_platform.User.username);
    }

    public deleteRoom(roomId: string)
    {
        // socket delete room
        // Non owners allowed to delete a room or just leave it?

        // What if in room and it gets deleted?
        //
        this.socket.emit(RoomEvent.DELETE_ROOM, roomId);
    }

    public leaveRoom()
    {
        // console.log("- Room Service: send Exit room", se_platform.User._id, se_platform.User.username );

        // send request
        this.socket.emit(RoomEvent.LEAVE_ROOM, this._currentRoom._id, se_platform.User._id, se_platform.User.username);

        this._currentRoom = new RoomModel();
    }

    public resignRoom()
    {
        // / console.log("- Room Service: send resigned room", se_platform.User._id, se_platform.User.username );

        this._currentRoom.resign();

        this.updateRoom();
    }

    /**
     * Send our local room data to the server to update game status
     *
     * Player who's turn it is has the power to switch turns (or change the room state)
     *
     */
    public updateRoom(): void
    {
        const room = this.room;

        this._prevState = room.state;

        console.log("RoomService - send room update", room);

        // update the room on server, just pass in relevent stuff from RoomModel for update
        const data = {
            game: room.game.toJSON(),
            state: room.state,
            encrypted: true,
        };

        // send only our player data
        if (room.myPlayer)
        {
            console.log("RoomService - room.myPlayer");

            if (room.myPlayer.username === room.player1.username)
            {
                console.log("RoomService - room.myPlayer is p1");
                // @ts-ignore
                data.player1 = room.myPlayer.toJSON();
            }
            else
            {
                console.log("RoomService - room.myPlayer is p2");
                // @ts-ignore
                data.player2 = room.myPlayer.toJSON();
            }
        }

        console.log("RoomService - data", data);

        this.socket.emit(RoomEvent.ROOM_UPDATE, data, room._id, se_platform.User._id);

        // this._currentRoom.copy(room);
        this.emit(RoomEvent.ROOM_UPDATE);
    }

    /**
     * For looser to call when they want to restart a game
     */
    public restartRoom(takeOwnership = true, switchOwners = false, update = true)
    {
        const room = new RoomModel();

        room.copy(this.room);

        room.game = new GameModel();
        room.game.gameId = this.room.game.gameId;

        room.owner = takeOwnership ? this.room.myPlayer._id : this.room.owner;

        if (switchOwners && room.player1._id && room.player2._id)
        {
            const p1 = room.player1;
            const p2 = room.player2;

            room.owner = room.owner === room.player1._id ? room.player2._id : room.player1._id;

            room.player1 = p2;
            room.player2 = p1;
        }

        room.state = RoomState.WAITING_FOR_PLAYER;
        // update the room on server, just pass in relevent stuff from RoomModel for update

        const data = {
            game: room.game.toJSON(),
            state: RoomState.WAITING_FOR_PLAYER,
            owner: room.owner,
            encrypted: true,
        };

        if (takeOwnership && room.myPlayer)
        {
            room.player1 = this.room.myPlayer;
            room.player2 = this.room.opponent;
        }

        room.player1.colour = "";
        room.player2.colour = "";

        data.player1 = room.player1.toJSON();
        data.player2 = room.player2.toJSON();

        this._currentRoom = room;

        if (!update) { return; }

        this.socket.emit(RoomEvent.ROOM_UPDATE, data, room._id, se_platform.User._id);

        // this._currentRoom.copy(room);
        this.emit(RoomEvent.ROOM_UPDATE);
    }

    private reset()
    {
        super.resetListeners();

        this.on(ServiceEvent.CONNECT, this.onRoomServiceConnect, this);
        this.on(ServiceEvent.DISCONNECT, this.onRoomServiceDisconnect, this);

        // Other room events get added when Joining room

        this._busy = false;
        this._connectedToRoom = false;
        this._currentGameScreen = null;
        this._currentRoom = new RoomModel();
        this._opponentInRoom = false;
        this._opponentUserName = "";
    }

    private checkScreen()
    {
        if (earthpixi.currentScreen instanceof IORoomScreen)
        {
            this._currentGameScreen = earthpixi.currentScreen as IORoomScreen;
        }
    }

    private onRoomServiceConnect()
    {
        // console.log(" - Room service connected to socket, re-join a room?");

        // console.log(this._updateGame, this._currentGameScreen);

        this.checkScreen();
        if (this._updateGame && this._currentGameScreen)
        {
            if (this._currentGameScreen.onRoomServiceConnectionChange)
            {
                this._currentGameScreen.onRoomServiceConnectionChange(true);
            }

            // console.log(" - Room service re-join room? ", this._currentRoom);

            if (this._currentRoom._id !== "" && !this._connectedToRoom)
            {
                // console.log(" - Room service re-join room", this._currentRoom._id, this._currentRoom.game.gameId);
                this.joinRoom(this._currentRoom._id, this._currentRoom.game.gameId);
            }
        }
    }

    private onRoomServiceDisconnect()
    {
        // console.log(" - Room service disconnected from server socket, pause game etc");

        this._connectedToRoom = false;
        this._opponentInRoom = false;

        this.checkScreen();
        if (this._updateGame && this._currentGameScreen)
        {
            if (this._currentGameScreen.onRoomServiceConnectionChange)
            {
                this._currentGameScreen.onRoomServiceConnectionChange(false);
            }

            // TODO any other messages to display about disconnecting here?
        }
    }

    private opponentDeletedRoom(username)
    {
        // console.log("opponent deleted room");

        // report back to game
        this.checkScreen();
        if (this._currentGameScreen && this._currentGameScreen.roomDeleted)
        {
            this._currentGameScreen.roomDeleted(username);
        }
    }

    /**
     *
     * Receive an updated room model from the server
     *
     * @param room
     * @param opponentJoined
     * @param opponentUsername
     */
    private roomUpdate(room: RoomModel, opponentJoined: boolean, opponentUsername: string)
    {
        console.log(" - RoomService: room updated", room, opponentJoined, opponentUsername);

        this._currentRoom.copy(room);

        if (this._prevState !== room.state)
        {
            // console.log("state change", room.state);

            if (
                (this._currentRoom.player1 && this._currentRoom.myPlayer._id === this._currentRoom.player1._id && this._currentRoom.state === RoomState.PLAYER1_TURN)
                || (this._currentRoom.player2 && this._currentRoom.myPlayer._id === this._currentRoom.player2._id && this._currentRoom.state === RoomState.PLAYER2_TURN)
            )
            {
                if (this.currentNotification) { this.currentNotification.close(); }

                if (RoomService.notificationSupported() && Notification.permission === "granted")
                {
                    console.log("Notify my turn", Notification.permission);
                    this.currentNotification = new Notification("It's your turn!", { body: `${this._currentRoom.opponent.username}had their turn` });
                    document.addEventListener("visibilitychange", RoomService.closeNotification);
                }
            }

            this._prevState = room.state;
        }
        // console.log(this._currentRoom);

        this.emit(RoomEvent.ROOM_UPDATE);

        if (opponentJoined)
        {
            this._opponentInRoom = true;
            this._opponentUserName = opponentUsername;

            this.emit(RoomEvent.OPPONENT_JOINED_ROOM);
            if (this._updateGame && this._currentGameScreen && this._currentGameScreen.opponentJoined)
            {
                this._currentGameScreen.opponentJoined(opponentUsername);
            }
        }

        this.checkScreen();
        if (this._updateGame && this._currentGameScreen && this._currentGameScreen.roomUpdated)
        {
            this._currentGameScreen.roomUpdated(this._currentRoom, opponentJoined, opponentUsername);
        }
    }

    /**
     *
     * @param room
     * @param opponentInRoom
     * @param opponentUsername
     */
    private joinedRoom(room: RoomModel, opponentInRoom: boolean, opponentUsername: string)
    {
        console.log(" - RoomService: joined room success", opponentInRoom, opponentUsername);
        this._currentRoom.copy(room);
        this._prevState = room.state;
        this._connectedToRoom = true;
        this._opponentInRoom = opponentInRoom;
        this._opponentUserName = opponentUsername;

        if (RoomService.notificationSupported() && Notification.permission !== "granted")
        {
            Notification.requestPermission();
        }

        // emit event for room io display etc
        this.emit(RoomEvent.ROOM_JOINED);

        // report back to game
        this.checkScreen();
        if (this._updateGame && this._currentGameScreen && this._currentGameScreen.roomJoined)
        {
            this._currentGameScreen.roomJoined(this._currentRoom);
        }
    }

    /**
     *
     * @param room
     */
    private leftRoom(room: RoomModel | null)
    {
        // console.log("- RoomService: we left room");
        this._connectedToRoom = false;
        this.emit(RoomEvent.LEAVE_ROOM, room);
    }

    // /**
    //  *
    //  * @param playerId
    //  */
    // private opponentJoinedRoom(playerId: string)
    // {
    //     console.log(" - RoomService: opponent joined room: " + playerId);
    //
    //     this.emit(RoomEvent.OPPONENT_JOINED_ROOM, playerId);
    // }

    /**
     *
     * @param playerId
     */
    private opponentLeftRoom(username: string)
    {
        // console.log("- RoomService: opponent left room: " + " " + username);

        // console.log(this._updateGame, this._currentGameScreen);

        if (this.room.opponent && username === this.room.opponent.username)
        {
            this._opponentInRoom = false;

            this.emit(RoomEvent.OPPONENT_LEFT_ROOM, username);

            // report back to game
            this.checkScreen();
            if (this._updateGame && this._currentGameScreen && this._currentGameScreen.opponentLeft)
            {
                this._currentGameScreen.opponentLeft(username);
            }
        }
    }

    /**
     *
     * @param gameAction
     */
    private opponentGameAction(gameAction: string)
    {
        // console.log(" - RoomService: game action ");

        // this.emit(RoomEvent.OPPONENT_GAME_ACTION, gameAction);
    }
}
