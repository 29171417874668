import PageDots from "./PageDots";

/**
 * @class
 */
export default class SlideShow extends earthpixi.GameObject
{
    /**
     * @constructor
     * @param {earthpixi.Screen} screen
     * @param {PIXI.DisplayObject[]} slides
     * @param {PIXI.DisplayObject} leftButton
     * @param {PIXI.DisplayObject} rightButton
     * @param {PIXI.DisplayObject} paginateDot
     */
    constructor(screen, slides, leftButton, rightButton, paginateDot)
    {
        super(screen);

        this.previousState = se_platform.GameMenu.state;
        this.saveDarkState = se_platform.GameMenu.dark;
        this.screenBack = se_platform.GameMenu.screenToGoBackTo;

        se_platform.GameMenu.state = se_platform.GameMenu.STATES.BACK_STATE;
        se_platform.GameMenu.screenToGoBackTo = null;
        se_platform.GameMenu.burgerButton.visible = false;
        se_platform.GameMenu.backWarning = false;
        se_platform.GameMenu.onBackMethod = this.exit.bind(this);
        se_platform.GameMenu.doExit = false;
        se_platform.GameMenu.musicButton.visible = false;
        se_platform.GameMenu.altBackButton.visible = true;

        this.bg = new PIXI.Graphics();
        this.bg.beginFill(0x000000, 0.5);
        this.bg.drawRect(0, 0, earthpixi.config.STAGE_WIDTH, earthpixi.config.STAGE_HEIGHT);
        this.addChild(this.bg);
        this.bg.interactive = true;

        this.slides = slides;

        this.slidesCounter = 0;

        this.slidesContainer = new PIXI.Container();
        this.addChild(this.slidesContainer);

        this.leftButton = leftButton;
        this.rightButton = rightButton;
        this.dot = paginateDot;

        if (this.leftButton && this.rightButton && this.slides.length > 1)
        {
            this.leftButton.interactive = this.rightButton.interactive = true;
            this.leftButton.addEvent("select", this.onLeft, this);
            this.rightButton.addEvent("select", this.onRight, this);

            this.addChild(this.leftButton);
            this.leftButton.position.set(140, 590);
            earthpixi.utils.Display.anchor.left(this.leftButton, 10);

            this.addChild(this.rightButton);
            this.rightButton.position.set(1040, 595);
            earthpixi.utils.Display.anchor.right(this.rightButton, 10);

            // set up pagination dots etc.
            this.pagination = new PageDots(screen, slides.length);
            this.pagination.position.set((earthpixi.config.STAGE_WIDTH - this.pagination.width) / 2, 720);
            this.addChild(this.pagination);
        }
        else
        {
            // console.log('single page');
        }

        this.showSlide(0);

        this.buttons = [se_platform.GameMenu.backButton, this.leftButton, this.rightButton];

        earthpixi.Access.soloButtons([se_platform.GameMenu.burgerButton, se_platform.GameMenu.altBackButton].concat(this.buttons));

        screen.addChild(this);
    }

    /**
     *
     * @param index
     */
    showSlide(index)
    {
        this.slidesCounter = index || this.slidesCounter;
        this.slidesContainer.children.length = 0;
        const slide = this.slides[this.slidesCounter];

        slide.alpha = 0;
        earthpixi.Tween.to(slide, 0.8, { alpha: 1 });
        this.slidesContainer.addChild(slide);
        earthpixi.utils.Display.position.center(slide);
        slide.y -= slide.paddingBottom || 0;
        if (this.pagination)
        {
            this.pagination.setDot(this.slidesCounter);
        }
    }

    onLeft()
    {
        if (this.slidesCounter > 0)
        {
            this.slidesCounter--;
            this.showSlide(this.slidesCounter);
        }
    }

    onRight()
    {
        if (this.slidesCounter < this.slides.length - 1)
        {
            this.slidesCounter++;
            this.showSlide(this.slidesCounter);
        }
    }

    /**
     *
     * @param evt
     */
    exit(evt)
    {
        se_platform.Navigation.location = se_platform.GameLocation.NONE;

        if (this.parent)
        {
            this.parent.removeChild(this);

            earthpixi.Access.freeSoloButtons();

            se_platform.GameMenu.state = this.previousState;
            se_platform.GameMenu.screenToGoBackTo = this.screenBack;
            se_platform.GameMenu.dark = this.saveDarkState;
            se_platform.GameMenu.burgerButton.visible = true;
            se_platform.GameMenu.doExit = true;
            se_platform.GameMenu.musicButton.visible = true;
            se_platform.GameMenu.altBackButton.visible = false;
        }
    }
}
