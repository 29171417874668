export default class GameButtons extends earthpixi.GameObject
{
    constructor(screen)
    {
        super(screen);

        this._buttons = [];

        this._slides = [];
        this.slidePosition = 0;
        this.slideWidth = 765;

        this.slideContainer = new PIXI.Container();
        this.addChild(this.slideContainer);

        this.rightButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.game_scroll_button_right, PIXI.utils.TextureCache.game_scroll_button_right, "", false);
        this.rightButton.y = 160;
        this.rightButton.x = this.slideWidth;
        this.rightButton.alpha = 0.7;
        this.rightButton.addEvent("select", this.scrollRight, this);
        this.addChild(this.rightButton);

        this.leftButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.game_scroll_button_left, PIXI.utils.TextureCache.game_scroll_button_left, "", false);
        this.leftButton.y = 160;
        this.leftButton.x = 0;
        this.leftButton.alpha = 0.7;
        this.leftButton.addEvent("select", this.scrollLeft, this);
        this.addChild(this.leftButton);

        this.updateSlidePosition();
    }

    updateSlidePosition()
    {
        if (this._buttons.length < 7)
        {
            this.leftButton.visible = this.rightButton.visible = false;
            this.slidePosition = 0;
        }

        this.leftButton.visible = this.slidePosition !== 0;
        this.rightButton.visible = this.slidePosition !== this._slides.length - 1;

        for (let i = 0; i < this._slides.length; i++)
        {
            for (let c = 0; c < this._slides[i].children.length; c++)
            {
                this._slides[i].children[c].interactive = i === this.slidePosition;
            }
        }
    }

    addButton(button)
    {
        this.addChild(button);
        this._buttons.push(button);
    }

    scrollRight()
    {
        this.slidePosition++;
        earthpixi.Tween.kill(this.slideContainer);
        earthpixi.Tween.to(this.slideContainer, 0.5, { x: -(this.slidePosition * this.slideWidth) });
        this.updateSlidePosition();
    }

    scrollLeft()
    {
        this.slidePosition--;
        earthpixi.Tween.kill(this.slideContainer);
        earthpixi.Tween.to(this.slideContainer, 0.5, { x: this.slidePosition === 0 ? 0 : -(this.slidePosition * this.slideWidth) });
        this.updateSlidePosition();
    }

    arrange()
    {
        // arrange buttons
        const startX = 85;
        const startY = 40;
        let x = startX;
        let y = startY;
        let button;
        const perSlide = 6;
        let slideCount = 0;
        let newSlide = true;
        let slide;

        for (let i = 0; i < this._buttons.length; i++)
        {
            if (newSlide)
            {
                slide = new PIXI.Container();
                slide.x = slideCount * this.slideWidth;
                slideCount++;
                this.slideContainer.addChild(slide);
                this._slides.push(slide);
                newSlide = false;
            }

            button = this._buttons[i];

            slide.addChild(button);
            button.x = x;
            button.y = y;

            x += 240;

            if (i === 2)
            {
                x = startX;
                y = 240;
            }

            if (i === 5)
            {
                newSlide = true;
                x = startX;
                y = startY;
            }

            if (i === 8)
            {
                x = startX;
                y = 240;
            }

            if (i === 11)
            {
                newSlide = true;
                x = startX;
                y = startY;
            }
        }

        this.updateSlidePosition();
    }
}
