// @ts-ignore
import AccessibilitySettings from "earthpixi/schema/AccessibilitySettings";

/**
 * @class
 */
export default class UserModel
{
    /**
     *
     */
    public _id: string = "secretword";
    /**
     *
     */
    public username: string = "NewUser";
    /**
     *
     */
    public onboarded: boolean = false;
    /**
     *
     */
    public sfxMuted: boolean = false;
    /**
     *
     */
    public musicMuted: boolean = false;
    /**
     *
     */
    public musicVolume: number = 100;
    /**
     *
     */
    public sfxVolume: number = 100;
    /**
     *
     */
    public lang: string = "en";
    public clickAnim: boolean = true;
    /**
     *
     */
    public settings: earthpixi.Access.AccessibilitySettings = new AccessibilitySettings.AccessibilitySettings();

    /**
     *
     * @param {UserModel} user
     */
    public copy(user: UserModel): void
    {
        /**
         * @member {string}
         */
        this._id = user._id !== undefined ? user._id : this._id;
        /**
         * @member {string}
         */
        this.username = user.username !== undefined ? user.username : this.username;
        /**
         * @member {boolean}
         */
        this.onboarded = user.onboarded !== undefined ? user.onboarded : this.onboarded;
        /**
         * @member {boolean}
         */
        this.sfxMuted = user.sfxMuted !== undefined ? user.sfxMuted : this.sfxMuted;
        /**
         * @member {boolean}
         */
        this.musicMuted = user.musicMuted !== undefined ? user.musicMuted : this.musicMuted;
        /**
         * @member {earthpixi.Access.AccessibilitySettings}
         */
        console.log('SEP: user.settings', user)
        this.settings.copy(user);

        setTimeout(() => {
            console.log('SEP: this.settings', this.settings)
        })

        // also copy new settings
        user.clickAnim !== undefined && user.clickAnim !== null && (this.clickAnim = user.clickAnim);
        user.lang !== undefined && user.lang !== null && (this.lang = user.lang);
    }

    /**
     *
     */
    public toJSON(): object
    {
        const obj = { ...this };

        obj.settings = this.settings.toJSON();

        return obj;
    }

    /**
     *
     */
    public reset():void
    {
        this.username = "NewUser";
        se_platform.LocalStorage.reset();
        earthpixi.Access.resetSettings();
    }

    /**
     *
     */
    public logOut()
    {
        this.reset();
        se_platform.firstScreen();
    }
}
