import InGameSettings from "../../menu_game/InGameSettings";

/**
 * @class
 */
export default class SettingsOnlyScreen extends earthpixi.Screen
{
    private inGameSettings: InGameSettings;
    static get assetList(): any[]
    {
        return [];
    }
    private settingsBox: InGameSettings;

    public static onSettingsDone:()=>void;
    public static onSettingsChange:()=>void;

    constructor()
    {
        super();

        // earthpixi.clearMask();
        earthpixi.setStageColor(0x222133);

        this.inGameSettings = new InGameSettings();
        // earthpixi.utils.Display.position.center(this.settingsBox);
        // this.settingsBox.x +=10;
        this.inGameSettings.y = -150;
        this.inGameSettings.x = -200;
        this.inGameSettings.scale.set(1.25);
        // this.settingsBox.on("done", this.onCancel, this);
        this.addChild(this.inGameSettings);

        this.inGameSettings.on("done", () =>
        {
            if (SettingsOnlyScreen.onSettingsDone) SettingsOnlyScreen.onSettingsDone();
        });
    }

    public run(): void
    {
        earthpixi.Access.settings.switchConfig.directMode = false;
        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;

        // default eyegaze on to start
        earthpixi.Access.eyeGaze(true);
        earthpixi.Access.switchAccess(earthpixi.Access.settings.switchActive);
    }
}
