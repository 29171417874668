import LoadingScreen from "../menu/screens/LoadingScreen";
import MenuScreen from "../menu/screens/MenuScreen";
import UserScreen from "../menu/screens/UserScreen";
import ServerSettings from "../config/ServerSettings";

/**
 * Init the app by deciding on first screen to show
 *
 * @function se_platform#firstScreen
 * @param {boolean} [serverOff]
 */
export default function ()
{
    se_platform.LocalStorage.load();

    if (ServerSettings.offlineBuild)
    {
        se_platform.IO.offline = true;
        // se_platform.User.reset();

        se_platform.Navigation.init();

        // user is logged in call setGame (reads current hash or goes to main menu)
        se_platform.Navigation.navigate();
        se_platform.Navigation.navActive = true;

        console.log(earthpixi.Access.settings.switchConfig.scanTime);
        console.log(se_platform.User.settings.switchConfig.scanTime);

        // earthpixi.setScreen(
        //     new LoadingScreen(MenuScreen.assetList, MenuScreen)
        // );

        return;
    }

    if (se_platform.User.username !== "NewUser")
    {
        // async check user details, will navigate back to user screen if responds with bad user
        se_platform.IO.checkUserExists();

        return;
    }

    // user not logged in so go to user screen
    earthpixi.setScreen(
        new LoadingScreen(UserScreen.assetList, UserScreen)
    );
}
