/**
 *  se_platform
 *
 *  Library to sit in global scope to provide services for games to use.
 *  eg. se_platform.User
 *
 *  Set this library up to keep the menu app and game code separate,
 *  and before I really started using typescript 😂
 *  So a bit of a weird setup!
 *
 *  Running tsdef in scripts creates typedefs from these jsdoc comments
 *
 *  TODO set this up as a TS class with static properties, see if this works as a webpack lib export
 *
 *
 *
 */

/**
 * @namespace se_platform
 */

/**
 * @function se_platform#startApp
 */
import Language from "./config/Language";

export { default as startApp } from "./init/startApp";

/**
 * @function se_platform#startSettingsOnly
 */
export { default as startSettingsOnly } from "./init/startSettingsOnly";

/**
 * @function se_platform#standAloneApi
 */
export { default as standAloneApi } from "./init/standAloneApi";

import IO from "./io/IO";
import LocalStore from "./io/LocalStore";
import UserModel from "./io/model/UserModel";
import NavigationManager from "./navigation/NavigationManager";
import GameMenuOverlay from "./menu_game/GameMenuOverlay";

const _language = Language.EN_GB;

/**
 * @static
 * @name language
 * @type {Language}
 * @memberof se_platform
 *
 */
export { _language as language };

const _ioInstance = new IO();
/**
 * @static
 * @name IO
 * @type {IO}
 * @memberof se_platform
 *
 */

export { _ioInstance as IO };

const _userInstance = new UserModel();

/**
 * @static
 * @name User
 * @type {UserModel}
 * @memberof se_platform
 *
 */
export { _userInstance as User };

/**
 * @static
 * @name GameConfigModel
 * @type {Class<GameConfigModel>}
 * @memberof se_platform
 *
 */
export { default as GameConfigModel } from "./config/GameConfigModel";
/**
 * @static
 * @name GameModel
 * @type {GameModel}
 * @memberof se_platform
 *
 */
export { default as GameModel } from "./io/model/GameModel";
/**
 * @static
 * @name GameTypes
 * @type {GameTypes}
 * @memberof se_platform
 *
 */
export { default as GameTypes } from "./config/GameTypes";
/**
 * @static
 * @name GameMode
 * @type {GameMode}
 * @memberof se_platform
 *
 */
export { default as GameMode } from "./navigation/enums/GameMode";
/**
 * @static
 * @name GameLocation
 * @type {GameLocation}
 * @memberof se_platform
 *
 */
export { default as GameLocation } from "./navigation/enums/GameLocation";
/**
 * @static
 * @name QueryName
 * @type {QueryName}
 * @memberof se_platform
 */
export { default as QueryName } from "./navigation/enums/QueryName";
/**
 * @static
 * @name OptionButton
 * @type {OptionButton}
 * @memberof se_platform
 */
export { default as OptionButton } from "./menu/onboarding/OptionButton";
/**
 * @static
 * @name SlideShow
 * @type {SlideShow}
 * @memberof se_platform
 */

export { default as LoadingScreen } from "./menu/screens/LoadingScreen";
/**
 * @static
 * @name LoadingScreen
 * @type {LoadingScreen}
 * @memberof se_platform
 *
 */

export { default as SlideShow } from "./menu/slideshow/SlideShow";
/**
 * @static
 * @name Text
 * @type {object}
 * @memberof se_platform
 */
export { default as Text } from "./config/text";

export { default as firstScreen } from "./init/firstScreen";
export { default as goToScreen } from "./navigation/goToScreen";
export { default as fitInRect } from "./menu/utils/fitInRect";

const _navInstance = new NavigationManager();
/**
 * @static
 * @const
 * @name Navigation
 * @type {NavigationManager}
 * @memberof se_platform
 *
 */

export { _navInstance as Navigation };
const _localStoreInstance = new LocalStore();
/**
 * @static
 * @const
 * @name LocalStorage
 * @type {LocalStore}
 * @memberof se_platform
 *
 */

export { _localStoreInstance as LocalStorage };

const _gameMenuInstance = new GameMenuOverlay();
/**
 * @static
 * @const
 * @name GameMenu
 * @type {GameMenuOverlay}
 * @memberof se_platform
 *
 */

export { _gameMenuInstance as GameMenu };

