/**
 *
 * @static
 * @enum {GameMode}
 * @memberOf se_platform
 * @property {string} NONE
 * @property {string} ONE_PLAYER
 * @property {string} TWO_PLAYER
 * @property {string} REMOTE_PLAYER
 *
 */
enum GameMode {
    /**
     *@type {string}
     */
    NONE = "",
    /**
     *@type {string}
     */
    ONE_PLAYER = "1player",
    /**
     *@type {string}
     */
    TWO_PLAYER = "2player",
    /**
     *@type {string}
     */
    REMOTE_PLAYER = "connect",
    /**
     *@type {string}
     */
    QUEST = "quest",
    /**
     *@type {string}
     */
    QUICK = "quick",

}

export default GameMode;
