/**
 *
 * @enum {string}
 * @name RoomEvent
 * @property {string} JOIN_ROOM
 * @property {string} CREATE_ROOM
 * @property {string} ROOM_CREATED
 * @property {string} ROOM_JOINED
 * @property {string} ROOM_FULL
 * @property {string} ROOM_NOT_FOUND
 * @property {string} ROOM_NOT_ALLOWED
 * @property {string} COLOR_PICKED
 * @property {string} DO_MOVE
 * @property {string} JOINED_GAME
 * @property {string} LEFT_GAME
 * @property {string} CANCEL_GAME
 * @property {string} GET_PARTICIPANTS
 * @property {string} GET_ROOMS
 * @property {string} GET_ROOM
 * @property {string} GOT_ROOM
 * @property {string} OPPONENT_JOINED_ROOM
 * @property {string} OPPONENT_LEFT_ROOM
 * @property {string} OPPONENT_GAME_ACTION
 *
 */
enum RoomEvent {
    COLOR_PICKED = "colorpicked",
    DO_MOVE = "domove",
    LEFT_GAME = "leftgame",
    JOINED_GAME = "joinedgame",
    CANCEL_GAME = "cancelgame",
    JOIN_ROOM = "joinroom",
    LEAVE_ROOM = "leaveroom",
    RESIGN_ROOM = "resignroom",
    ROOM_CREATED = "roomcreated",
    DELETE_ROOM = "deletedRoom",
    ROOM_DELETED = "roomDeleted",
    ROOM_JOINED = "roomjoined",
    ROOM_FULL = "roomfull",
    ROOM_UPDATE = "roomupdate",
    ROOM_NOT_ALLOWED = "roomnotallowed",
    ROOM_NOT_FOUND = "roomnotfound",
    GET_PARTICIPANTS = "getparticipants",
    GOT_ROOM = "gotroom",
    GET_ROOMS = "getrooms",
    GET_ONLINE_PLAYERS = "gettonlineplayers",
    OPPONENT_JOINED_ROOM = "oppjoinedroom",
    OPPONENT_LEFT_ROOM = "oppleftroom",
    OPPONENT_RESIGNED = "oppresignedroom",
    OPPONENT_GAME_ACTION = "oppgameaction",
}
export default RoomEvent;
