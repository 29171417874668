/**
 * In game- player properties, init all properties for conversion to mongoose Scheme
 */
export default class PlayerModel
{
    /**
     *
     */
    public _id: string = "";

    /**
     *
     */
    public username: string = "";

    /**
     *
     */
    public encrypted: boolean = false;

    /**
     *
     */
    public colour: string = "";

    /**
     *
     */
    public skillLevel: string = "";

    /**
     *
     * @param {string} [id]
     */
    constructor(id?: string)
    {
        this._id = id || "";
    }

    copy(player:PlayerModel)
    {
        Object.assign(this, player);
    }

    public toJSON()
    {
        return { ...this };
    }
}
