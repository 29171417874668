import moment from "moment";
import GameTypes from "../../../config/GameTypes";
import ServerSettings from "../../../config/ServerSettings";
import GameModel from "../../model/GameModel";
import LeaderboardModel from "../../model/LeaderboardModel";
import LevelProgress from "../../model/LevelProgress";
import APIService from "../APIService";

export default class GameService extends APIService
{
    public currentGame: GameModel;
    public currentGameTypeID: GameID;
    public cDate: string;
    public uDate: string;

    public getGame(
        callback: (data: GameModel) => void, 
        callbackContext?: any, 
        error?: (response: Response) => void, 
        gameId?: number
    ): void
    {
        console.log('getGame -> ID: ', gameId)
        console.log('getGame -> gameTypeId : ', this.currentGameTypeID)

        if (gameId !== undefined){
            this.currentGameTypeID = gameId;
        }

        if (se_platform.User.username === "NewUser"){
            console.log('will create a new game')
            // TODO what to do when user not logged in - just return a new game that wont save?
            // saved game needs to have an owner
            const newGame = new GameModel();

            newGame.gameId = this.currentGameTypeID;
            this.currentGame = newGame;
            callback.apply(callbackContext, [this.currentGame]);

            return;
        }
        else{
            console.log('Not an unknown user, Will not create a new game');
        }

        this.fetchGame(
            (gameModelData: any) =>
            {
                const gameModel = new GameModel();

                console.log('data from fetch game is: ', gameModelData)

                this.cDate = moment(gameModelData.created_at).format("x");
                this.uDate = moment(gameModelData.updated_at).format("x");

                console.log('cDate is now: ', this.cDate)

                gameModel.copy(gameModelData);

                console.log('copied game model: ', gameModel)

                this.currentGame = gameModel;

                console.log('assigned this.currentGame: ', this.currentGame)

                callback.apply(callbackContext, [this.currentGame]);
            },
            null,
            (response) =>
            {
                console.log("server couldn't create new game", response);
                this.currentGame = new GameModel();
                this.currentGame.gameId = this.currentGameTypeID;
            },
        );
    }

    public getLeaderBoard(callback: (data: LeaderboardModel) => void, callbackContext?: any, error?: (response: Response) => void, gameId?: number): void
    {
        this.fetchAPI(`${ServerSettings.url}leaderboard/${gameId || this.currentGame.gameId}`, {
            method: "GET",
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback, callbackContext, error);
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    /**
     * Updates the current game on the server
     * @param callback
     * @param callbackContext
     * @param error
     */
    
    public update(callback?: (game: GameModel) => void, callbackContext?: any, error?: (response: Response) => void): void
    {
        if (
            !(earthpixi.currentScreen.verifyUpdate && earthpixi.currentScreen.verifyUpdate())
        )
        {
            return;
        }

        console.log("gameservice.ts api update game");

        // TODO server needs username to save highscores now, but otherwise not needed
        this.currentGame.username = globalThis.se_platform.User.username;

        console.log('gameservice.ts - se_platform.User: ', globalThis.se_platform.User)

        console.log('gameservice.ts - currentGame: ', this.currentGame)

        const jsonData = JSON.stringify(this.currentGame.toJSON());

        console.log('gameservice.ts - jsonData: ', jsonData)

        this.fetchAPI(`${ServerSettings.url}game/${this.currentGame._id}/update/${this.uDate}/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: jsonData,
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback || this.updateSuccess, callbackContext);
            }, (err) =>
            {
                APIService.fetchRejected(err, error || this.updateError, callbackContext);
            });
    }

    public getLevelProgress(level: number, zone: number): LevelProgress | undefined
    {
        // console.log("get level progress");
        const id = this.levelProgressId(level, zone);

        const prog = this.currentGame.levelProgress.find((progress: LevelProgress) =>
            progress.id === id);

        // console.log(prog);
        return prog;
    }

    // updates the highScore if higher
    public updateLevelProgress(level: number, zone: number, score: number, state: string, completed: boolean)
    {
        let prog = this.getLevelProgress(level, zone);

        if (prog)
        {
            // save over current level progress
            if (score !== undefined && score !== 0)
            {
                const newHigh = this.updateHighScore(score, prog);
            }

            if (state !== undefined)
            {
                prog.state = state;
            }

            if (completed !== undefined)
            {
                prog.completed = completed;
            }
        }
        else
        {
            // new level Progress
            prog = new LevelProgress(
                this.levelProgressId(level, zone),
                level,
                zone,
                score,
                score,
                [score],
                false,
                state,
            );
            this.currentGame.levelProgress.push(prog);
        }

        this.update();
    }
    
    public updateGame(level: number, boardState: string)
    {
        this.currentGame.level = level;

        if (level > this.currentGame.levelBest)
        {
            this.currentGame.levelBest = level;
        }

        if (boardState !== undefined)
        {
            this.currentGame.board_state = boardState;
        }

        this.update();
    }

    private updateHighScore(score: number, prog: LevelProgress): boolean
    {
        // console.log("update high score", score);

        if (this.currentGame.gameId === GameTypes.EYEDRIVE.id)
        {
            prog.highScores.sort((a, b) => a - b);
        }
        else
        {
            prog.highScores.sort((a, b) => b - a);
        }

        let found = null;

        if (prog.highScores.length < 3)
        {
            // add new score if not found already
            if (!prog.highScores.find((hS) => hS === score))
            {
                prog.highScores.push(score);
                found = true;
                prog.highScores.sort((a, b) => (a > b ? -1 : 1));
            }
        }
        else
        {
            for (let i = 0; i < prog.highScores.length; i++)
            {
                if (this.currentGame.gameId === GameTypes.EYEDRIVE.id)
                {
                    if (score < prog.highScores[i])
                    {
                        found = i;
                        break;
                    }
                }
                else
                if (score > prog.highScores[i])
                {
                    found = i;
                    break;
                }
            }
            if (found !== null)
            {
                prog.highScores.splice(found, 0, score);

                if (prog.highScores.length > 3)
                {
                    prog.highScores.length = 3;
                }

                if (this.currentGame.gameId === GameTypes.EYEDRIVE.id)
                {
                    prog.highScores.sort((a, b) => a - b);
                }
                else
                {
                    prog.highScores.sort((a, b) => b - a);
                }
            }
        }

        prog.score = score;
        prog.highScore = prog.highScores[0];

        return found !== null;
    }

    /**
     * Requests saved game from server, if server doesn;t find one for this user / gameType, creates a new one
     * @param callback
     * @param callbackContext
     * @param error
     */

    private fetchGame(callback: (data: GameModel) => void, callbackContext?: any, error?: (response: Response) => void)
    {
        console.log("fetching game via: " + `${ServerSettings.url}game/${se_platform.User._id}/${this.currentGameTypeID}`);

        this.fetchAPI(`${ServerSettings.url}game/${se_platform.User._id}/${this.currentGameTypeID}`, {
            method: "GET",
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                console.log('created fetch operation: ', res)
                APIService.fetchResponded(res, callback, callbackContext, error);
            }, (err) =>
            {
                console.log('failed to create fetch operation: ', err)
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    private levelProgressId(level: number, zone: number): string
    {
        return `${zone}_${level}`;
    }

    private updateSuccess(gameModelData: any)
    {
        if (gameModelData.notFound)
        {
            console.log("update - game not found", gameModelData);

            return;
        }

        console.log("update success", gameModelData);

        se_platform.IO.GameService.currentGame.seed = gameModelData.seed;
        se_platform.IO.GameService.cDate = moment(gameModelData.created_at).format("x");
        se_platform.IO.GameService.uDate = moment(gameModelData.updated_at).format("x");
    }

    private updateError(response: Response)
    {
        console.log("update error", response);
    }

    private putLevelProgress(levelProgress: LevelProgress, callback?: (game: GameModel) => void, callbackContext?: any, error?: (response: Response) => void): void
    {
        const jsonData = JSON.stringify(levelProgress.toJSON());

        this.fetchAPI(`${ServerSettings.url}game/${this.currentGame._id}/update`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: jsonData,
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback || this.updateSuccess, callbackContext);
            }, (err) =>
            {
                APIService.fetchRejected(err, error || this.updateError, callbackContext);
            });
    }
}
