/**
 *
 * @enum {string}
 * @property {string} CONNECT
 * @property {string} DISCONNECT
 * @property {string} GLOBAL
 * @property {string} ACTION
 * @property {string} UPDATED
 * @property {string} GET_USER
 * @property {string} ERROR
 *
 *
 */
enum ServiceEvent {
    CONNECT = "connect",
    DISCONNECT = "disconnect",
    GLOBAL = "global",
    ACTION = "action",
    UPDATED = "updated",
    GET_USER = "getuser",
    ERROR = "socketerror",
}

export default ServiceEvent;
