export default class SaveGamesScroller extends earthpixi.GameObject
{
    constructor()
    {
        super();

        this.bg = new PIXI.Sprite(PIXI.utils.TextureCache.menu_scroller_bg);
        this.addChild(this.bg);
    }
}
