import Text from "../../config/text";
import ConfirmOverlay from "../../menu_game/ConfirmOverlay";

let _instance = null;

export default class UserMiniUI extends earthpixi.GameObject
{
    constructor()
    {
        super();

        _instance = this;

        this.logOutButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.log_out, PIXI.utils.TextureCache.log_out, "log_out", false, false, 20);
        this.logOutButton.addEvent("select", this.logOut, this);
        this.logOutButton.position.set(30, 22);
        this.logOutButton.visible = false;

        this.addChild(this.logOutButton);

        // title user name
        this.userNameTxt = new PIXI.extras.BitmapText(
            se_platform.User.username !== "NewUser"
                ? se_platform.User.username : ""
            , {
                font: "35px baloo",
                align: "left",
                tint: 0x323044
            });
        this.userNameTxt.y = 25;
        this.userNameTxt.x = 50;
        this.addChild(this.userNameTxt);
    }

    static get instance()
    {
        return _instance;
    }

    showLogout()
    {
        if (this.logOutButton.visible)
        { return; }

        this.logOutButton.alpha = 0;
        this.logOutButton.visible = true;
        earthpixi.Tween.kill(this.logOutButton);
        earthpixi.Tween.kill(this.userNameTxt);
        earthpixi.Tween.to(this.logOutButton, 0.5, { alpha: 1 });
        earthpixi.Tween.to(this.userNameTxt, 0.5, { x: 100 });
    }

    hideLogout()
    {
        if (!this.logOutButton.visible)
        { return; }

        earthpixi.Tween.kill(this.logOutButton);
        earthpixi.Tween.kill(this.userNameTxt);
        this.logOutButton.visible = false;
        earthpixi.Tween.to(this.userNameTxt, 0.5, { x: 50 });
    }

    logOut()
    {
        this.confirmOverlay = new ConfirmOverlay(
            Text.strings.logOutCheck.replace("$username", se_platform.User.username),
            () =>
            {
                se_platform.User.logOut();
            },
            null,
            null,
            true,
            Text.strings.ok,
            Text.strings.cancel
        );

        earthpixi.stageOverlay.addChild(this.confirmOverlay);
    }
}
