import Text from "../config/text";

export default class ConfirmOverlay extends earthpixi.GameObject
{
    constructor(message, onYesCallback, onNoCallback, scope, darkMode = false, yesText = "ok", noText = "cancel")
    {
        super();

        this.cover = earthpixi.utils.Draw.DrawRect({ x: 0, y: 0, width: 128, height: 128 }, 0xFFFFFFCC);
        this.cover.width = earthpixi.config.STAGE_WIDTH * 4;
        this.cover.x = -earthpixi.config.STAGE_WIDTH * 2;
        this.cover.height = earthpixi.config.STAGE_HEIGHT * 2;
        this.cover.y = -earthpixi.config.STAGE_HEIGHT / 2;
        this.cover.tint = darkMode ? 0xFFFFFF : 0x222133;
        this.cover.interactive = true;

        this.addChild(this.cover);

        this.onYesCallback = onYesCallback;
        this.onNoCallback = onNoCallback;
        this.callBackScope = scope;

        this.goBackCheckText = new PIXI.extras.BitmapText(message, {
            font: "50px montserrat",
            tint: darkMode ? 0x222133 : 0xFFFFFF,
            align: "center"
        });

        this.goBackCheckText.maxWidth = 750;

        se_platform.fitInRect(this.goBackCheckText, 0, 0, 750, 200);

        this.cancelButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.game_button,
            PIXI.utils.TextureCache.game_button_on,
            noText,
            false, false, 10
        );
        this.cancelButton.addEvent("select", this.onNo, this);
        this.cancelButton.addText(noText, 30, "montserrat");

        this.okButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.game_button,
            PIXI.utils.TextureCache.game_button_on,
            yesText, false, false, 10
        );

        this.okButton.addText(yesText, 30, "montserrat");
        this.okButton.position.set(350, 430);
        this.okButton.addEvent("select", this.onYes, this);

        earthpixi.utils.Display.position.center(this.goBackCheckText);
        this.goBackCheckText.y = 250;

        earthpixi.utils.Display.position.center(this.okButton);
        earthpixi.utils.Display.position.center(this.cancelButton);

        this.okButton.x -= 250;
        this.okButton.y += 220;

        this.cancelButton.x += 200;
        this.cancelButton.y += 220;

        this.addChild(this.goBackCheckText);
        this.addChild(this.okButton);
        this.addChild(this.cancelButton);

        // save previous solo'd buttons so can restore after confirmation
        if (earthpixi.Access.soloButtonArr)
        {
            //console.log(earthpixi.Access.soloButtonArr);
            this.restoreSoloButtons = earthpixi.Access.soloButtonArr;
        }

        earthpixi.Access.soloButtons(this);

        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;
    }

    onYes()
    {
        if (this.onYesCallback)
        {
            if (this.callBackScope)
            {
                this.onYesCallback.call(this.callBackScope);
            }
            else
            {
                this.onYesCallback();
            }
        }

        this.done();
    }

    onNo()
    {
        if (this.onNoCallback)
        {
            if (this.callBackScope)
            {
                this.onNoCallback.call(this.callBackScope);
            }
            else
            {
                this.onNoCallback();
            }
        }

        this.done();
    }

    done()
    {
        if (this._destroyed)
        { return; }

        //console.log("confirm overlay done");
        if (this.restoreSoloButtons)
        {
            earthpixi.Access.soloButtons(this.restoreSoloButtons);
        }
        else
        {
            earthpixi.Access.freeSoloButtons();
        }

        this.onNoCallback = null;
        this.onYesCallback = null;
        this.callBackScope = null;

        this.destroy({ children: true });
    }
}
