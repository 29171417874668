import GameTypes from "../../config/GameTypes";
import FeedBackForm from "../feedback_form/FeedBackForm";
import GameButtons from "../game_select/GameButtons";
import GameButtonSquare from "../game_select/GameButtonSquare";
import InfoBox from "../info/InfoBox";
import OnBoardingOverlay from "../onboarding/OnBoardingOverlay";
import SettingsBox from "../settings/SettingsBox";
import UserMiniUI from "../user/UserMiniUI";
import UserSettingsBox from "../user/UserSettingsBox";

export default class MenuScreen extends earthpixi.Screen
{
    constructor()
    {
        super();

        this.localStorageString = "EyeGazeGamesUser";

        // earthpixi.clearMask();
        earthpixi.setStageColor(0x222133);

        this.contentCardY = 198;
        this.navButtonsY = 60;
        this.gameButtonsY = 220;

        // testing
        // const box = new UserSettingsBox();
        // box.x = 300;
        // box.y = 50;
        // this.addChild(box);
        // this.run=()=>{};
        // return;

        this.createCards();
        this.createHeader();
        this.createGameButtons();

        this.createInfoBox();
        // this.createSettingsBoard();
        // this.createUserBoard();
    }

    static get assetList()
    {
        return [
            // added to globalAssetList see app.js
        ];
    }

    run()
    {
        console.log('TEST::TEST')
        earthpixi.showCW = true;
        earthpixi.resizePreFix();

        // earthpixi.Access.settings.switchConfig.directMode = false;
        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;

        earthpixi.Access.eyeGaze(earthpixi.Access.settings.eyeGazeActive);
        earthpixi.Access.switchAccess(earthpixi.Access.settings.switchActive);

        // earthpixi.Keyboard.addKey(27, this.onEscapeKey, null, null, this.onEscapeKey, this);

        const localSettings = window.localStorage.getItem(this.localStorageString);

        if (!localSettings)
        {
            this.localStorageData = { gamesPlayed: 0, settingsStored: 0 };
        }
        else
        {
            this.localStorageData = JSON.parse(localSettings);
        }

        // this.showFeedBack();
        //
        // return;

        this.introAnim(true);

        if (!se_platform.User.onboarded)
        {
            this.showOB();
        }
        else if (this.localStorageData.gamesPlayed > 4 && this.localStorageData.settingsStored > 2 && !this.localStorageData.feedbackShown)
        {
            this.showFeedBack();
        }
    }

    onEscapeKey(e)
    {
        //console.log("escape key");

        if (this._animating)
        { return; }

        if (this.onBoarding && this.onBoarding.parent !== null)
        {
            this.onBoarding.onSkip();

            return;
        }

        if (this.settingsButton.interactive)
        {
            this.onSettings();

            return;
        }

        if (this.backButton.interactive)
        {
            this.onBack();

            return;
        }
    }

    showOB()
    {
        this.onBoarding = new OnBoardingOverlay();
        this._animating = false;
        this.addChild(this.onBoarding);
        se_platform.User.onboarded = true;
    }

    showFeedBack()
    {
        if (this.feedbackForm)
        {
            this.removeChild(this.feedbackForm);
            this.feedbackForm.destroy({ children: true });
        }

        this.feedbackForm = new FeedBackForm();
        this.addChild(this.feedbackForm);

        this.localStorageData.feedbackShown = true;
        this.saveLocal();
    }

    introAnim(start)
    {
        this.titleText.text = "GAMES";

        if (start)
        {
            this.bgCard.alpha = 0;
            earthpixi.Tween.to(this.bgCard, 1, { alpha: 1 });
            this.addChild(this.bgCard);

            this.header.y = -200;
            earthpixi.Tween.to(this.header, 1, { y: 0, ease: Expo.easeOut });
            this.addChild(this.header);
        }
        else
        {
            this.settingsButton.interactive = true;
            this.settingsButton.visible = true;
            this.helpButton.interactive = true;
            this.helpButton.visible = true;

            const offline = se_platform.IO.offline || se_platform.User.username === "NewUser";

            this.userButton.interactive = !offline;
            this.userButton.visible = !offline;

            earthpixi.Tween.to([this.settingsButton, this.helpButton, this.userButton], 0.3, {
                y: this.navButtonsY,
                delay: 0.2
            });

            if (this.onBoarding && this.onBoarding.parent)
            {
                this.addChild(this.onBoarding);
            }
        }

        this.blueSlide.x = 1024;
        earthpixi.Tween.to(this.blueSlide, 1, { x: 600, ease: Expo.easeOut });
        this.addChild(this.blueSlide);

        this.yellowCard.y = 700;
        this.yellowCard.visible = true;
        this.cardsMask.visible = true;
        this.yellowCard.mask = this.yellowCard.maskObj || this.cardsMask;

        earthpixi.Tween.to(this.yellowCard, 1, {
            y: this.contentCardY, delay: 0, ease: Expo.easeInOut,
            onComplete: this.unmask, onCompleteParams: [[this.yellowCard], true], onCompleteScope: this
        });

        const txtX = 1100 - Math.floor(this.titleText.width);

        earthpixi.Tween.to(this.titleText, 1, {
            x: txtX,
            delay: 0.2, ease: Expo.easeInOut
        });

        this.addChild(this.titleText);
        this.addChild(this.yellowCard);

        const buttonDelay = 1;

        this.gameButtons.y = this.gameButtonsY;
        this.gameButtons.visible = true;

        const buttonContainer = this.gameButtons._slides[this.gameButtons.slidePosition];

        this.gameButtons.rightButton.interactive = false;
        this.gameButtons.rightButton.alpha = 0;
        this.gameButtons.leftButton.interactive = false;
        this.gameButtons.leftButton.alpha = 0;

        earthpixi.Tween.to(this.gameButtons.rightButton, 0.5, {
            alpha: 0.7,
            delay: 1,
            onComplete: this.activateButton,
            onCompleteParams: [this.gameButtons.rightButton, true],
            onCompleteScope: this,
        });

        earthpixi.Tween.to(this.gameButtons.leftButton, 0.5, {
            alpha: 0.7,
            delay: 1,
            onComplete: this.activateButton,
            onCompleteParams: [this.gameButtons.leftButton, true],
            onCompleteScope: this,
        });

        for (let i = 0; i < buttonContainer.children.length; i++)
        {
            const button = buttonContainer.children[i];

            const origX = button.x;
            // button.x = 1500;

            button.scale.set(0);
            button.interactive = true;
            earthpixi.Tween.to(button.scale, 0.5, {
                x: 1, y: 1, delay: buttonDelay + (i * 0.05), ease: Expo.easeOut,
                onComplete: this.activateButton,
                onCompleteParams: [button, true],
                onCompleteScope: this,
            });
        }
        this.gameButtons.mask = this.gameButtons.maskObj || this.cardsMask;
        this.addChild(this.gameButtons);

        if (this.onBoarding && this.onBoarding.parent)
        {
            this.addChild(this.onBoarding);
        }

        // this.addChild(this.settings);
    }

    activateButton(but, activate)
    {
        but.interactive = activate;
    }

    clearForHomeAnim(whenDone)
    {
        // clear buttons and title / blue wipe
        earthpixi.Tween.to(this.titleText, 0.3, { x: 1200 });

        earthpixi.Tween.to(this.blueSlide, 0.3, { x: 1200, delay: 0.2 });

        this.settingsButton.interactive = false;
        this.helpButton.interactive = false;
        this.userButton.interactive = false;

        earthpixi.Tween.to([this.settingsButton, this.helpButton, this.backButton, this.userButton], 0.3, {
            y: -100,
            onComplete: this.unmask,
            onCompleteScope: this,
            onCompleteParams: [[this.settingsButton, this.helpButton, this.backButton, this.userButton], false]
        });

        const buttonDelay = 0.3;

        const buttonContainer = this.gameButtons._slides[this.gameButtons.slidePosition];

        for (let i = 0; i < buttonContainer.children.length; i++)
        {
            const button = buttonContainer.children[i];

            earthpixi.Tween.to(button.scale, 0.5, { x: 0, y: 0, delay: buttonDelay - (i * 0.05), ease: Expo.easeOut });
        }
        // clear yellow box
        if (this.yellowCard.visible)
        {
            this.yellowCard.mask = this.cardsMask;

            earthpixi.Tween.kill(this.yellowCard);
            earthpixi.Tween.kill(this.gameButtons);

            earthpixi.Tween.to([this.yellowCard, this.gameButtons], 0.5, {
                y: 680,
                delay: 0.4,
                ease: Expo.easeOut,
                onComplete: this.unmask,
                onCompleteScope: this,
                onCompleteParams: [[this.yellowCard, this.gameButtons], false]
            });
        }

        // clear settings
        if (this.settings && this.settings.visible)
        {
            this.settings.mask = this.cardsMask;
            earthpixi.Tween.kill(this.settings);
            earthpixi.Tween.to(this.settings, 1, {
                y: 680, delay: 0.4, ease: Expo.easeOut,
                onComplete: this.unmask, onCompleteScope: this, onCompleteParams: [[this.settings], false]
            });
        }

        // clear settings
        if (this.userSettings && this.userSettings.visible)
        {
            UserMiniUI.instance.hideLogout();
            this.userSettings.mask = this.cardsMask;
            earthpixi.Tween.kill(this.userSettings);
            earthpixi.Tween.to(this.userSettings, 1, {
                y: 680, delay: 0.4, ease: Expo.easeOut,
                onComplete: this.unmask, onCompleteScope: this, onCompleteParams: [[this.userSettings], false]
            });
        }

        // clear info
        if (this.info.visible)
        {
            earthpixi.Tween.to(this.info, 1, {
                alpha: 0, ease: Expo.easeOut,
                onComplete: this.unmask, onCompleteScope: this, onCompleteParams: [[this.info], false]
            });
        }

        if (!this.bgCard.visible)
        {
            this.bgCard.visible = true;
            earthpixi.Tween.to(this.bgCard, 0.3, { alpha: 1, delay: 0.4 });
        }

        if (whenDone)
        {
            earthpixi.delayedCall(0.5, whenDone, [], this);
        }

        earthpixi.Tween.to(this.ggLogo.scale, 0.3, { x: 1, y: 1 });
        earthpixi.Tween.to(this.ggLogo, 0.3, { x: this.logoStartX, y: this.logoStartY });
    }

    unmask(objects, remainVisible)
    {
        for (let i = 0; i < objects.length; i++)
        {
            objects[i].mask = null;
            objects[i].visible = remainVisible;
        }

        // this.cardsMask.visible = false;
    }

    createHeader()
    {
        const header = this.header = new PIXI.Container();

        header.x = 10;

        const seLogo = new PIXI.Sprite(PIXI.utils.TextureCache.se_logo);

        seLogo.x = 260;
        seLogo.y = 10;
        seLogo.scale.set(0.6);
        seLogo.buttonMode = true;
        seLogo.interactive = true;
        seLogo.on("pointerup", this.goToSEWebsite);
        header.addChild(seLogo);

        const ggLogo = this.ggLogo = new PIXI.Sprite(PIXI.utils.TextureCache.ggr_logo);

        ggLogo.x = this.logoStartX = 290;
        ggLogo.y = this.logoStartY = 50;
        ggLogo.scale.set(1);
        header.addChild(ggLogo);

        this.backButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.back_button, PIXI.utils.TextureCache.back_button, "Back", false, false, 20);
        this.backButton.x = 1000;
        this.backButton.y = this.navButtonsY;
        this.backButton.visible = false;
        this.backButton.addEvent("select", this.onBack, this);
        // this.backButton.eyeGazeAble = false;
        header.addChild(this.backButton);

        this.helpButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.help_button, PIXI.utils.TextureCache.help_button, "Info", false, false, 20);
        this.helpButton.x = 740;
        this.helpButton.y = this.navButtonsY;
        this.helpButton.addEvent("select", this.onHelp, this);
        // this.helpButton.eyeGazeAble = false;
        header.addChild(this.helpButton);

        this.settingsButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.settings_button, PIXI.utils.TextureCache.settings_button, "Settings", false, false, 20);
        this.settingsButton.x = 880;
        this.settingsButton.y = this.navButtonsY;
        // this.settingsButton.eyeGazeAble = false;
        this.settingsButton.addEvent("select", this.onSettings, this);
        header.addChild(this.settingsButton);

        this.userButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.user_menu, PIXI.utils.TextureCache.user_menu, "My User", false, false, 20);
        this.userButton.x = 1020;
        this.userButton.y = this.navButtonsY;
        // this.settingsButton.eyeGazeAble = false;
        this.userButton.addEvent("select", this.onUser, this);
        header.addChild(this.userButton);

        if (se_platform.IO.offline || se_platform.User.username === "NewUser")
        {
            this.helpButton.x += 120;
            this.settingsButton.x += 120;
            this.userButton.visible = false;
        }
    }

    createInfoBox()
    {
        this.info = new InfoBox(this);
        // this.addChild(this.info);
    }

    createCards()
    {
        // Cards
        this.bgCard = new PIXI.Sprite(PIXI.utils.TextureCache.blue_bg);
        earthpixi.utils.Display.position.center(this.bgCard);
        this.bgCard.x += 10;
        this.bgCard.y = 140;

        this.userMiniUI = new UserMiniUI();
        this.bgCard.addChild(this.userMiniUI);

        this.cardsMask = new PIXI.Sprite(PIXI.utils.TextureCache.card_mask);
        this.cardsMask.x = this.bgCard.x + 21;
        this.cardsMask.y = 155;
        this.addChild(this.cardsMask);

        this.blueSlide = new PIXI.Sprite(PIXI.utils.TextureCache.blue_slide);
        this.blueSlide.x = 500;
        this.blueSlide.y = 140;
        this.blueSlide.mask = this.cardsMask;
        // this.addChild(this.blueSlide);

        this.titleText = new PIXI.extras.BitmapText("GAMES", { font: "50px baloo", align: "left" });
        this.titleText.position.set(1200, 175);
        this.titleText.skew.x = -0.25;
        this.titleText.mask = this.cardsMask;

        this.yellowCard = new PIXI.Sprite(PIXI.utils.TextureCache.yellow_card);
        this.yellowCard.x = this.bgCard.x + 21;
        this.yellowCard.y = 198;
        this.yellowCard.mask = this.cardsMask;
        // this.addChild(this.yellowCard);

        earthpixi.maskUpdateItems.push(this.cardsMask);

        if (earthpixi.renderer instanceof PIXI.CanvasRenderer)
        {
            const rect = new PIXI.Rectangle(0, 0, this.cardsMask.width, this.cardsMask.height);

            this.removeChild(this.cardsMask);

            this.cardsMask = new PIXI.Graphics();
            this.cardsMask.beginFill(0xFFFFFF, 1);
            this.cardsMask.drawRect(rect.x, rect.y, rect.width, rect.height);
            this.cardsMask.x = this.bgCard.x + 21;
            this.cardsMask.y = 155;
            this.cardsMask.drawRect(rect.x, rect.y, rect.width, rect.height);
            this.addChild(this.cardsMask);

            this.blueSlide.mask = this.cardsMask;
            this.titleText.mask = this.cardsMask;
            this.yellowCard.mask = this.cardsMask;

            // this.mask1 = new PIXI.Graphics();
            // this.mask1.beginFill(0xFFFFFF,1);
            // this.mask1.x = this.bgCard.x + 21;
            // this.mask1.y = 155;
            // this.mask1.drawRect(rect.x,rect.y,rect.width,rect.height);
            // this.addChild(this.mask1);
            // this.yellowCard.mask = this.mask1;
            // this.yellowCard.maskObj = this.mask1;

            // this.cardsMask.endFill();
            // this.cardsMask.x = this.bgCard.x + 21;
            // this.cardsMask.y = 155;
            // this.addChild(this.cardsMask);
        }
    }

    createGameButtons()
    {
        this.gameButtons = new GameButtons(this);
        this.gameButtons.x = 260;
        this.gameButtons.y = this.gameButtonsY;

        const games = GameTypes.array;

        for (let i = 0; i < games.length; i++)
        {
            const button = new GameButtonSquare(
                `${earthpixi.config.IMG_ROOT}menu/game_icons/${games[i].icon}@${earthpixi.resolution}x.png`,
                PIXI.utils.TextureCache.game_button,
                PIXI.utils.TextureCache.game_button,
                `Select ${games[i].name}`,
                games[i].beta
            );

            //  console.log(games[i].beta);

            button.addEvent("select", this.onSelectGame, this, games[i]);
            button.interactive = false;

            this.gameButtons.addButton(button);
        }

        this.gameButtons.arrange();
    }

    createSettingsBoard()
    {
        if (this.settings)
        {
            this.settings.destroy({ children: true });
        }
        this.settings = new SettingsBox(this, true);
        this.settings.visible = false;
        this.settings.position.set(this.yellowCard.x, this.contentCardY);
        // this.addChild(this.settings);
    }

    createUserBoard()
    {
        if (this.userSettings)
        {
            this.userSettings.destroy({ children: true });
        }
        this.userSettings = new UserSettingsBox(this, true);
        this.userSettings.visible = false;
        this.userSettings.position.set(this.yellowCard.x, this.contentCardY);
        // this.addChild(this.settings);
    }

    onBack()
    {
        this._animating = true;
        earthpixi.delayedCall(1.3, () =>
        {
            this._animating = false;
        });
        earthpixi.Access.reset();
        this.clearForHomeAnim(this.introAnim);
    }

    onHelp()
    {
        this._animating = true;
        earthpixi.delayedCall(1.3, () =>
        {
            this._animating = false;
        });
        earthpixi.Access.reset();
        this.clearForHomeAnim(this.showHelp);
    }

    showHelp()
    {
        this.currentCard = "help";
        this.backButton.visible = true;
        this.backButton.y = -100;

        earthpixi.Tween.to(this.backButton, 0.5, { y: this.navButtonsY, ease: Expo.easeOut });

        this.cardsMask.visible = false;

        if (this.unmaskTween) this.unmaskTween.kill();
        this.unmaskTween = earthpixi.Tween.to([this.bgCard], 0.3, {
            alpha: 0, ease: Linear.easeNone,
            onComplete: this.unmask, onCompleteScope: this, onCompleteParams: [[this.bgCard], false]
        });

        this.info.alpha = 0;
        this.info.visible = true;
        this.addChild(this.info);
        this.addChild(this.header);
        earthpixi.Tween.to([this.info], 0.3, { alpha: 1, ease: Linear.easeNone });

        earthpixi.Tween.to(this.ggLogo.scale, 0.3, { x: 0.95, y: 0.95 });

        earthpixi.Tween.to(this.ggLogo, 0.3, { x: 485, y: 50 });
    }

    onSettings()
    {
        this._animating = true;
        earthpixi.delayedCall(1.3, () =>
        {
            this._animating = false;
        });

        earthpixi.Access.reset();
        this.clearForHomeAnim(this.showSettings);
    }

    onUser()
    {
        this._animating = true;
        earthpixi.delayedCall(1.3, () =>
        {
            this._animating = false;
        });

        earthpixi.Access.reset();
        this.clearForHomeAnim(this.showUser);
    }

    showSettings()
    {
        this.createSettingsBoard();

        this.currentCard = "settings";
        earthpixi.Tween.kill(this.backButton);
        this.backButton.visible = true;
        this.backButton.y = -100;
        earthpixi.Tween.to(this.backButton, 1, { y: this.navButtonsY, ease: Expo.easeOut });

        this.titleText.text = "SETTINGS";
        const txtX = 1100 - Math.floor(this.titleText.width);

        earthpixi.Tween.to(this.titleText, 1, { x: txtX, delay: 0, ease: Expo.easeInOut });

        this.blueSlide.x = 1024;
        earthpixi.Tween.to(this.blueSlide, 1, { x: txtX - 300, ease: Expo.easeOut });

        this.addChild(this.settings);
        this.settings.mask = this.cardsMask;
        this.settings.visible = true;
        this.settings.y = 700;
        if (this.unmaskTween) this.unmaskTween.kill();
        this.unmaskTween = earthpixi.Tween.to(this.settings, 1, {
            y: this.contentCardY, delay: 0, ease: Expo.easeOut,
            onComplete: this.unmask, onCompleteParams: [[this.settings], true], onCompleteScope: this
        });

        earthpixi.Tween.to(this.ggLogo.scale, 0.3, { x: 0.95, y: 0.95 });
        earthpixi.Tween.to(this.ggLogo, 0.3, { x: 485, y: 50 });
    }

    showUser()
    {
        UserMiniUI.instance.showLogout();

        this.createUserBoard();

        this.currentCard = "user";
        earthpixi.Tween.kill(this.backButton);

        this.backButton.visible = true;
        this.backButton.y = -100;
        earthpixi.Tween.to(this.backButton, 1, { y: this.navButtonsY, ease: Expo.easeOut });

        this.titleText.text = "PROFILE";
        const txtX = 1100 - Math.floor(this.titleText.width);

        earthpixi.Tween.to(this.titleText, 1, { x: txtX, delay: 0, ease: Expo.easeInOut });

        this.blueSlide.x = 1024;
        earthpixi.Tween.to(this.blueSlide, 1, { x: txtX - 300, ease: Expo.easeOut });

        this.addChild(this.userSettings);
        this.userSettings.mask = this.cardsMask;
        this.userSettings.visible = true;
        this.userSettings.y = 700;
        if (this.unmaskTween) this.unmaskTween.kill();
        this.unmaskTween = earthpixi.Tween.to(this.userSettings, 1, {
            y: this.contentCardY, delay: 0, ease: Expo.easeOut,
            onComplete: this.unmask, onCompleteParams: [[this.userSettings], true], onCompleteScope: this
        });

        earthpixi.Tween.to(this.ggLogo.scale, 0.3, { x: 0.95, y: 0.95 });
        earthpixi.Tween.to(this.ggLogo, 0.3, { x: 485, y: 50 });
    }

    onSelectGame(evt, data)
    {
        this.localStorageData.gamesPlayed++;
        this.saveLocal();

        //console.log(data);

        se_platform.Navigation.game = data.gameName;
    }

    goToSEWebsite()
    {
        // window.top.open("http://www.specialeffect.org.uk/", "blank");
    }

    saveLocal()
    {
        // window.localStorage.setItem(this.localStorageString, JSON.stringify(this.localStorageData));
    }
}
