export default class MenuSwitchBool extends earthpixi.GameObject
{
    constructor(screen, configObj, prop, accessibleTitle)
    {
        super(screen);

        this.canChangeProperty = true;

        this.bg = new earthpixi.Access.Button(PIXI.utils.TextureCache.switch_bg, PIXI.utils.TextureCache.switch_bg, accessibleTitle);
        // this.bg = new PIXI.Sprite(PIXI.utils.TextureCache["switch_bg"]);
        this.bg.eyeGazeAble = false;
        this.bg.addEvent("select", this.onSelect, this);
        this.addChild(this.bg);

        this.blob = new PIXI.Sprite(PIXI.utils.TextureCache.switch_blob);
        this.blob.x = -5;
        this.blob.y = -2;
        // this.blob.interactive = true;
        // this.blob.buttonMode = true;
        // this.blob.on("pointerdown", this.onSelect, this);
        this.addChild(this.blob);

        this.configObj = configObj;
        this.prop = prop;

        if (typeof (this.configObj[prop]) !== "boolean")
        {
            throw new Error("property not a bool!");
        }

        this.isOn = this.configObj[prop];
        if (this.isOn)
        {
            this.turnOn();
        }
        else
        {
            this.turnOff();
        }
    }

    onSelect(evt)
    {
        // console.log('hit');

        evt.stopPropagation();
        evt.data.originalEvent.preventDefault();

        // console.log(evt);

        if (this.isOn)
        {
            this.isOn = false;
            this.turnOff();
        }
        else
        {
            this.isOn = true;
            this.turnOn();
        }
    }

    turnOn()
    {
        if (this.canChangeProperty)
        {
            this.configObj[this.prop] = true;
        }

        earthpixi.Tween.to(this.bg, 0.2, { colorProps: { tint: 0x006349, format: "number" }, ease: Linear.easeNone });
        earthpixi.Tween.to(this.blob, 0.2, {
            x: 40,
            colorProps: { tint: 0xFFFFFF, format: "number" },
            ease: Linear.easeNone
        });

        this.emit("change", true);
    }

    turnOff()
    {
        if (this.canChangeProperty)
        {
            this.configObj[this.prop] = false;
        }

        earthpixi.Tween.to(this.bg, 0.2, { colorProps: { tint: 0x666666, format: "number" }, ease: Linear.easeNone });
        earthpixi.Tween.to(this.blob, 0.2, {
            x: -5,
            colorProps: { tint: 0x808080, format: "number" },
            ease: Linear.easeNone
        });

        this.emit("change", false);
    }
}
