export default class TextSwitch extends earthpixi.GameObject
{
    constructor(screen, configObj, prop, options)
    {
        super(screen);

        this.configObj = configObj;
        this.prop = prop;
        this.options = options;

        const txtOptions = { font: "16px montserrat", align: "left" };
        let xPos = 0;

        this.txts = [];

        for (let i = 0; i < this.options.length; i++)
        {
            const option = this.options[i];
            const text = new PIXI.extras.BitmapText(option.txt, txtOptions);

            this.addChild(text);
            text.interactive = true;
            text.buttonMode = true;
            text.index = i;
            text.configValue = option.value;
            text.x = xPos;
            text.on("pointerdown", this.onSelect, this);
            text.eyeGazeAble = false;
            this.txts.push(text);
            xPos += text.width + 3;

            if (i !== this.options.length - 1)
            {
                const slash = new PIXI.extras.BitmapText("/", txtOptions);

                slash.x = xPos;
                slash.tint = 0x006837;
                this.addChild(slash);
                xPos += slash.width + 5;
            }
        }

        for (let i = 0; i < this.txts.length; i++)
        {
            if (this.txts[i].configValue === this.configObj[this.prop])
            {
                this.txts[i].tint = 0xFFFF00;
            }
            else
            {
                this.txts[i].tint = 0x006837;
            }
        }
    }

    onSelect(evt)
    {
        const target = evt.data.target || evt.currentTarget;

        for (let i = 0; i < this.options.length; i++)
        {
            const option = this.options[i];

            if (option.value === target.configValue)
            {
                this.txts[i].tint = 0xFFFF00;
            }
            else
            {
                this.txts[i].tint = 0x006837;
            }
        }

        this.configObj[this.prop] = target.configValue;

        this.emit("change", target.configValue);
    }
}
