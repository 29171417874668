/**
 * OptionButton class
 *
 * @extends earthpixi.Access.Button
 */
export default class OptionButton extends earthpixi.Access.Button
{
    /**
     *
     * @param txt
     * @param staticTex
     * @param hoverTex
     * @param accessName
     * @param [repeatable]
     * @param [toggleable]
     */
    constructor(txt, staticTex, hoverTex, accessName, repeatable = false, toggleable = false)
    {
        super(staticTex, hoverTex, accessName, repeatable, toggleable);

        const titleTxt = new PIXI.extras.BitmapText(txt, { font: "35px montserrat", tint: 0xFFFFFF, align: "center" });

        titleTxt.maxWidth = 120;
        se_platform.fitInRect(titleTxt, 20, 15, 150, 130);

        this.glow = new PIXI.Sprite(PIXI.utils.TextureCache.game_button_glow);

        this.glow.visible = false;
        this.addChild(this.glow);
        this.addChild(titleTxt);

        this.on("over", this.onOver, this);
        this.on("out", this.onOut, this);
    }

    onOver()
    {
        this.glow.visible = true;
    }

    onOut()
    {
        this.glow.visible = false;
    }
}
