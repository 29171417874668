/**
 *
 */
export default class ServerSettings
{
        // setting this to true will disable User functionality, just a static site.
        // Gets set to true when server down
        public static offlineBuild: boolean = false;
        public static url: string = "https://io-server-test.fly.dev/";
        // public static useDev: boolean = window && window.location && window.location.hostname === 'localhost'
        // public static url: string = this.useDev ? "http://localhost:80/" : "https://eggtest.fly.dev/" ;
}
