import Text from "../../config/text";
import UserService from "../../io/service/user/UserService";
import SaveGamesScroller from "./SaveGamesScroller";
import UserMiniUI from "./UserMiniUI";

export default class UserSettingsBox extends earthpixi.GameObject
{
    constructor()
    {
        super();

        const bg = this.bg = new PIXI.Sprite(PIXI.utils.TextureCache.green_card);

        bg.interactive = true;
        this.addChild(bg);

        se_platform.IO.checkServer(this.onServerFound, this.onServerError, this);
    }

    onServerError()
    {
        const warn = new PIXI.extras.BitmapText(Text.strings.serverError, {
            font: "35px baloo",
            tint: 0x222133,
            align: "centre"
        });

        warn.maxWidth = 500;
        warn.anchor.set(0.5, 0);
        warn.position.set(400, 180);
        this.addChild(warn);
    }

    onServerFound()
    {
        this.createUserPanel();

        // TODO
        // this.createGamesPanel();
    }

    createUserPanel()
    {
        // left col

        this.leftCol = new PIXI.Container();
        this.leftCol.position.set(20, 40);
        this.addChild(this.leftCol);

        let ySpace = 0;
        const xSpace = 0;
        const defSpacing = 40;

        // title user name
        // this.userNameTxt = new PIXI.extras.BitmapText(se_platform.User.username, { font: '40px baloo', align: 'left', tint: 0x323044 });
        // this.userNameTxt.y = ySpace-75;
        // this.userNameTxt.x = xSpace;
        // //this.leftCol.addChild(this.userNameTxt);

        // edit user name
        let label = new PIXI.extras.BitmapText(Text.strings.username, { font: "30px baloo_small", align: "left" });

        label.y = ySpace;
        label.x = xSpace;
        ySpace += label.height;
        this.leftCol.addChild(label);

        // editing username
        ySpace += defSpacing;
        this.editUserText = new PIXI.extras.BitmapText(se_platform.User.username, {
            font: "24px baloo_small",
            align: "left",
            tint: 0x7F47DD
        });
        this.editUserText.y = ySpace + 20;
        this.editUserText.x = xSpace + 130;
        this.editUserText.anchor.set(0.5, 0.5);
        this.leftCol.addChild(this.editUserText);
        ySpace += this.editUserText.height;

        // row of three buttons for username edit
        ySpace += defSpacing;

        this.genUserButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser, true);
        this.genUserButton.y = ySpace;
        this.genUserButton.x = xSpace;
        this.genUserButton.addEvent("select", this.onGenNewUser, this);
        this.genUserButton.addIcon(PIXI.utils.TextureCache.reload_icon);
        this.leftCol.addChild(this.genUserButton);

        this.cancelNewUserButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser);
        this.cancelNewUserButton.y = ySpace;
        this.cancelNewUserButton.x = xSpace + 115;
        this.cancelNewUserButton.addIcon(PIXI.utils.TextureCache.cancel_icon);
        this.cancelNewUserButton.interactive = false;
        this.cancelNewUserButton.addEvent("select", this.onCancelUser, this);
        this.cancelNewUserButton.alpha = 0.5;
        this.leftCol.addChild(this.cancelNewUserButton);

        this.acecptNewUserButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser);
        this.acecptNewUserButton.y = ySpace;
        this.acecptNewUserButton.x = xSpace + 230;
        this.acecptNewUserButton.addIcon(PIXI.utils.TextureCache.tick_icon_1);
        this.acecptNewUserButton.interactive = false;
        this.acecptNewUserButton.addEvent("select", this.onSaveUser, this);
        this.acecptNewUserButton.alpha = 0.5;
        this.leftCol.addChild(this.acecptNewUserButton);

        // revel secret word
        ySpace += 130;
        label = new PIXI.extras.BitmapText(Text.strings.secretWordTitle, { font: "30px baloo_small", align: "left" });
        label.y = ySpace;
        ySpace += label.height;
        this.leftCol.addChild(label);

        ySpace += 50;

        this.secretWordText = new PIXI.extras.BitmapText(se_platform.User._id, {
            font: "30px montserrat",
            align: "left"
        });
        this.secretWordText.x = xSpace + 100;
        this.secretWordText.y = ySpace + 10;
        this.secretWordText.anchor.set(0.5, 0);
        this.secretWordText.tint = 0xFF4868;
        this.secretWordText.alpha = 0;
        this.leftCol.addChild(this.secretWordText);

        this.revelSecretWordButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser);
        this.revelSecretWordButton.y = ySpace;
        this.revelSecretWordButton.x = xSpace + 40;
        this.revelSecretWordButton.addText("reveal", 20, "baloo_small");
        this.revelSecretWordButton.addEvent("select", this.onRevealSecret, this);
        this.leftCol.addChild(this.revelSecretWordButton);

        ySpace += 20;
        this.copyButton = new PIXI.Sprite(PIXI.utils.TextureCache.copy_clipboard_icon);
        this.copyButton.y = ySpace;
        this.copyButton.x = xSpace + 240;
        this.copyButton.interactive = true;
        this.copyButton.buttonMode = true;
        this.copyButton.on("pointerup", this.onCopyToClipboard, this);

        this.leftCol.addChild(this.copyButton);
    }

    createGamesPanel()
    {
        // right col
        this.rightCol = new PIXI.Container();
        this.rightCol.position.set(400, 40);
        this.addChild(this.rightCol);

        let ySpace = 0;
        let xSpace = 0;
        const defSpacing = 40;

        const label = new PIXI.extras.BitmapText(Text.strings.savedGames, { font: "30px baloo_small", align: "left" });

        this.rightCol.addChild(label);

        ySpace += label.height + defSpacing;

        this.gamesScroller = new SaveGamesScroller();
        this.gamesScroller.y = ySpace;
        this.rightCol.addChild(this.gamesScroller);

        xSpace += this.gamesScroller.width + 10;

        this.scrollUpButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser);
        this.scrollUpButton.y = ySpace - 30;
        this.scrollUpButton.x = xSpace;
        this.scrollUpButton.addIcon(PIXI.utils.TextureCache.pink_arrow_up);
        this.scrollUpButton.addEvent("select", this.scrollGamesUp, this);
        this.rightCol.addChild(this.scrollUpButton);

        this.scrollDownButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser);
        this.scrollDownButton.y = ySpace + 130;
        this.scrollDownButton.x = xSpace;
        this.scrollDownButton.addIcon(PIXI.utils.TextureCache.pink_arrow_up);
        this.scrollDownButton.icon.rotation = PIXI.DEG_TO_RAD * 180;
        this.scrollDownButton.addEvent("select", this.scrollGamesDown, this);
        this.rightCol.addChild(this.scrollDownButton);

        this.selectGameButton = new earthpixi.Access.Button(PIXI.utils.TextureCache.small_menu_button, PIXI.utils.TextureCache.small_menu_button_on, Text.strings.genNewUser);
        this.selectGameButton.y = ySpace + 270;
        this.selectGameButton.x = xSpace;
        this.selectGameButton.addText("Go!", 30, "baloo_small");
        this.selectGameButton.addEvent("select", this.selectSavedGame, this);
        this.rightCol.addChild(this.selectGameButton);
    }

    onGenNewUser()
    {
        //console.log("generate");

        this.genUserButton.interactive = false;
        this.genUserButton.buttonMode = false;
        this.genUserButton.alpha = 0.5;

        se_platform.IO.UserService.genNewUserName(this.onNewUserNameRespose, this, this.onNewUserError);
    }

    onNewUserNameRespose(data)
    {
        this.editUserText.text = data.username;
        this.editUserText.scale.set(1);
        earthpixi.Tween.kill(this.editUserText.scale);
        earthpixi.Tween.to(this.editUserText.scale, 0.5, { x: 1.1, y: 1.1, repeat: -1, yoyo: true });
        // earthpixi.Tween.to(this.editUserText.scale, 0.5, {delay:0.5, x:1, y:1});
        this.genUserButton.interactive = true;
        this.genUserButton.buttonMode = true;
        this.genUserButton.alpha = 1;

        this.acecptNewUserButton.alpha = 1;
        this.acecptNewUserButton.interactive = true;
        this.acecptNewUserButton.buttonMode = true;

        this.cancelNewUserButton.alpha = 1;
        this.cancelNewUserButton.interactive = true;
        this.cancelNewUserButton.buttonMode = true;
    }

    onNewUserError(err)
    {
        console.log(err);
    }

    onSaveUser()
    {
        //console.log("save user");

        se_platform.IO.saveUserName(this.editUserText.text, this.userSaveSuccess, null, this);

        this.resetUserEdit();
    }

    onCancelUser()
    {
        this.editUserText.text = se_platform.User.username;
        this.resetUserEdit();
    }

    userSaveSuccess(user)
    {
        // console.log("success", user);
        se_platform.User.username = user.username;

        se_platform.LocalStorage.save();

        if (UserMiniUI.instance)
        {
            UserMiniUI.instance.userNameTxt.text = se_platform.User.username;
        }
    }

    resetUserEdit()
    {
        this.editUserText.scale.set(1);
        earthpixi.Tween.kill(this.editUserText.scale);

        this.genUserButton.interactive = true;
        this.genUserButton.buttonMode = true;
        this.genUserButton.alpha = 1;

        this.acecptNewUserButton.alpha = 0.5;
        this.acecptNewUserButton.interactive = false;
        this.acecptNewUserButton.buttonMode = false;

        this.cancelNewUserButton.alpha = 0.5;
        this.cancelNewUserButton.interactive = false;
        this.cancelNewUserButton.buttonMode = false;
    }

    onRevealSecret(evt)
    {
        this.revelSecretWordButton.interactive = false;
        this.revelSecretWordButton.buttonMode = false;
        earthpixi.Tween.to(this.revelSecretWordButton, 0.5, { alpha: 0 });
        earthpixi.Tween.to(this.secretWordText, 0.5, { alpha: 1 });
        earthpixi.delayedCall(2, this.hideSecretWord, [], this);
    }

    hideSecretWord()
    {
        this.revelSecretWordButton.interactive = true;
        this.revelSecretWordButton.buttonMode = true;
        earthpixi.Tween.to(this.revelSecretWordButton, 0.5, { alpha: 1 });
        earthpixi.Tween.to(this.secretWordText, 0.5, { alpha: 0 });
    }

    scrollGamesUp()
    {

    }

    scrollGamesDown()
    {

    }

    showHelpBubble(evt)
    {
        if (evt)
        {
            evt.stopPropagation();
        }
        this.removeBubble();

        const target = evt.currentTarget || evt.data.target;
        const bubble = this.helpBubble = new PIXI.Sprite(PIXI.utils.TextureCache.helpBub);

        this.addChild(bubble);
        const pos = evt.data.getLocalPosition(this);

        const txt = new PIXI.extras.BitmapText(target.helpTxt, { font: "14px montserrat", align: "left" });

        txt.tint = 0x006837;
        txt.maxWidth = 120;
        txt.x = 35;
        txt.y = 10;
        bubble.addChild(txt);

        bubble.position.set(pos.x, pos.y - 50);
        bubble.interactive = true;
        bubble.on("pointerup", this.removeBubble, this);
        bubble.on("pointerupoutside", this.removeBubble, this);
    }

    removeBubble(evt)
    {
        if (evt)
        {
            evt.stopPropagation();
        }
        //console.log("remove");

        if (this.helpBubble)
        {
            this.helpBubble.off("pointerup");
            this.helpBubble.off("pointerupoutside");
            this.removeChild(this.helpBubble);
            this.helpBubble = null;
        }
    }

    onCopyToClipboard(evt)
    {
        earthpixi.utils.CopyToClipboard(se_platform.User._id);

        this.copyButton.alpha = 0.5;
        earthpixi.Tween.kill(this.copyButton);
        earthpixi.Tween.to(this.copyButton, 0.5, { alpha: 1 });
    }

    updateText()
    {
    }
}
