export default class GameButtonSquare extends earthpixi.Access.Button
{
    constructor(iconImgUrl, staticTex, hoverTex, accessName, beta = false)
    {
        super(staticTex, hoverTex, accessName, false, false);

        const iconSpr = PIXI.Sprite.fromImage(iconImgUrl);

        this.addChild(iconSpr);

        iconSpr.scale.set(0.63, 0.60);

        iconSpr.x = 3;
        iconSpr.y = 4;

        let mask = new PIXI.Sprite(PIXI.utils.TextureCache.game_button_mask);

        this.addChild(mask);
        // //pixi bug render new texture
        const newTx = earthpixi.renderer.generateTexture(mask, PIXI.settings.SCALE_MODE, earthpixi.resolution);

        this.removeChild(mask);
        mask = new PIXI.Sprite(newTx);

        if (earthpixi.renderer instanceof PIXI.CanvasRenderer)
        {
            const rect = mask.getBounds();

            mask = new PIXI.Graphics();
            mask.beginFill(0xFFFFFF, 1);
            mask.drawRect(rect.x + 20, rect.y + 20, rect.width - 40, rect.height - 40);
            mask.endFill();
        }

        mask.position.set(0, 0);
        iconSpr.mask = mask;
        this.addChild(mask);

        const glow = this.glow = new PIXI.Sprite(PIXI.utils.TextureCache.game_button_glow);

        this.glow.visible = false;
        this.addChild(glow);

        if (beta)
        {
            const betaSticker = new PIXI.Sprite(PIXI.utils.TextureCache.beta);

            betaSticker.position.set(150, 0);
            this.addChild(betaSticker);
        }

        this.on("over", this.onOver, this);
        this.on("out", this.onOut, this);
    }

    onOver()
    {
        this.glow.visible = true;
    }

    onOut()
    {
        this.glow.visible = false;
    }
}
