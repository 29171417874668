/**
 * @enum {string}
 * @property {string} PLAYER1_TURN
 * @property {string} PLAYER2_TURN
 * @property {string} COMPLETE
 * @property {string} WAITING_FOR_PLAYER
 * @property {string} NO_ROOM
 */
enum RoomState {
    /**
     * @type {string}
     */
    PLAYER1_TURN = "p1turn",
    /**
     * @type {string}
     */
    PLAYER2_TURN = "p2turn",
    /**
     * @type {string}
     */
    COMPLETE = "comp",
    /**
     * @type {string}
     */
    PLAYER1_RESIGNED = "p1Resigned",
    /**
     * @type {string}
     */
    PLAYER2_RESIGNED = "p2Resigned",
    /**
     * @type {string}
     */
    WAITING_FOR_PLAYER = "waiting_player",
    /**
     * @type {string}
     */
    NO_ROOM = "no_room",
}

export default RoomState;
