export default {

    skip: "skip",
    back: "back",
    next: "next",
    title: "Hi! \n\nAre you interested in giving us some feedback about Eye Gaze Games? \n\nCheck out the short questions below, or select SKIP to get back to the games. \n\nMuchas gracias!  \nEye Gaze Games Team",
    feedback: "FEEDBACK",
    startQuestions: "Start",
    selectMoreThanOne: "select all that apply",
    selectOne: "select one",
    submit: "Submit",
    thanks: "Thank you for your feedback, \nmuch appreciated!\n\nEye Gaze Games Team",

    q1: "",
    q1_a1: "",

};
