import GameTypes from "./GameTypes";
/**
 * @class
 */
class GameConfigModel
{
    /**
     * @type {number | null}
     */
    public id: number | null = null;
    /**
     * {boolean | null}
     */
    public beta: boolean | null = false;
    /**
     * @type {string | null}
     */
    public gameName: string | null = null;

    /**
     * @type {string | null}
     */
    public icon: string | null = null;

    /**
     * @type {string | null}
     */
    public path: string | null = null;

    /**
     * @type {object | string | null}
     */
    public config: object | string | null = null;

    /**
     * @constructor
     * @param id
     * @param gameName
     * @param icon
     * @param path
     * @param {object|string|null} [config]
     * @param {boolean|null} [beta]
     */
    constructor(id: number, gameName: string, icon: string, path: string, config?: object | string | null, beta?: boolean | null)
    {
        Object.assign(this, { id, gameName, icon, path, config, beta });
    }
}

export default GameConfigModel;
