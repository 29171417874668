import Text from "../../config/text";
import MenuCounter from "./MenuCounter";
import MenuKeyCodeSetter from "./MenuKeyCodeSetter";
import MenuSwitchBool from "./MenuSwitchBool";
import TextSwitch from "./TextSwitch";
import SettingsOnlyScreen from "../screens/SettingsOnlyScreen";

export default class SettingsBox extends earthpixi.GameObject
{

    constructor(screen, wizard = false)
    {
        super(screen);
        console.log('Started Settings Box!')
        // this.interactive = true;

        const bg = this.bg = new PIXI.Sprite(PIXI.utils.TextureCache.green_card);

        bg.interactive = true;
        this.addChild(bg);

        if (wizard)
        {
            this.wizardButton = new earthpixi.Access.Button(
                PIXI.utils.TextureCache.wizard,
                PIXI.utils.TextureCache.wizard,
                "Show Wizard", false, false, 20
            );
            this.wizardButton.position.set(750, 400);
            this.wizardButton.scale.set(1.3);
            // this.settingsButton.eyeGazeAble = false;
            this.wizardButton.addEvent("select", this.showWizard, this);
            this.addChild(this.wizardButton);
        }

        this.infoText = new PIXI.extras.BitmapText(
            Text.strings.infoEyeGazeSwitchOff,
            { font: "17px montserrat", align: "center" }
        );
        this.infoText.maxWidth = 750;
        this.infoText.position.set((bg.width - this.infoText.width - 5) / 2, 55);
        this.addChild(this.infoText);

        // right col
        this.rightCol = new PIXI.Container();
        this.rightCol.position.set(490, 110);
        this.addChild(this.rightCol);

        let label = new PIXI.extras.BitmapText("Switch + Keyboard", { font: "30px baloo_small", align: "left" });

        label.x -= 60;
        this.rightCol.addChild(label);

        let menuSwitch = new MenuSwitchBool(this.screen, earthpixi.Access.settings, "switchActive", Text.strings.enableSwitch);

        menuSwitch.canChangeProperty = false;
        menuSwitch.on("change", this.onSwitchAccessChange, this);
        this.rightCol.addChild(menuSwitch);
        menuSwitch.position.set(225, 12);

        this.switchSettingsContainer = new PIXI.Container();
        this.switchSettingsContainer.alpha = earthpixi.Access.settings.switchActive ? 1 : 0.5;
        this.switchSettingsContainer.interactiveChildren = earthpixi.Access.settings.switchActive;

        this.rightCol.addChild(this.switchSettingsContainer);

        label = new PIXI.extras.BitmapText(Text.strings.switchMode, { font: "16px montserrat", align: "left" });
        label.x = 35;
        label.y = 60;
        this.switchSettingsContainer.addChild(label);

        let helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);

        helpButton.position.set(label.x + label.width + 10, label.y - 3);
        helpButton.helpTxt = Text.strings.help_switchMode;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.switchSettingsContainer.addChild(helpButton);

        let textSwitch = new TextSwitch(this.screen, earthpixi.Access.settings.switchConfig, "switchMode", [
            { txt: Text.strings.switchMode2, value: 2 },
            { txt: Text.strings.switchMode1, value: 1 }
        ]);

        this.switchSettingsContainer.addChild(textSwitch);
        textSwitch.on("change", this.onScanTypeChange, this);
        textSwitch.position.set(label.x, label.y + 30);

        this.scanTimeSettings = new PIXI.Container();
        this.scanTimeSettings.alpha = earthpixi.Access.settings.switchConfig.switchMode === 1 ? 1 : 0.5;
        this.scanTimeSettings.interactiveChildren = earthpixi.Access.settings.switchConfig.switchMode === 1;
        this.switchSettingsContainer.addChild(this.scanTimeSettings);

        label = new PIXI.extras.BitmapText(Text.strings.scanTime, { font: "16px montserrat", align: "left" });
        label.x = 35;
        label.y = 160;
        this.scanTimeSettings.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 10, label.y - 3);
        helpButton.helpTxt = Text.strings.help_scanTime;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.scanTimeSettings.addChild(helpButton);

        textSwitch = new MenuCounter(this.screen, earthpixi.Access.settings.switchConfig, "scanTime", "", Text.strings.seconds, [0.5, 10], 0.1);
        textSwitch.on("change", this.onSettingsChange, this);
        this.scanTimeSettings.addChild(textSwitch);
        textSwitch.position.set(label.x, label.y + 35);

        label = new PIXI.extras.BitmapText(Text.strings.switchMapping, { font: "16px montserrat", align: "left" });
        label.x = 35;
        label.y = 250;
        this.switchSettingsContainer.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 10, label.y - 3);
        helpButton.helpTxt = Text.strings.help_switchMap;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.switchSettingsContainer.addChild(helpButton);

        textSwitch = new MenuKeyCodeSetter(this.screen, earthpixi.Access.settings.switchConfig,
            [
                { txt: Text.strings.oneSwitch, configProp: "switch1KeyCode" },
                { txt: Text.strings.twoSwitch, configProp: "switch2KeyCode" }

            ]);
        textSwitch.on("change", this.onSettingsChange, this);
        this.switchSettingsContainer.addChild(textSwitch);
        textSwitch.position.set(label.x, label.y + 30);

        // left col
        this.leftCol = new PIXI.Container();
        this.leftCol.position.set(40, 110);
        this.addChild(this.leftCol);

        label = new PIXI.extras.BitmapText(Text.strings.eyeGazeMode, { font: "30px baloo_small", align: "left" });
        this.leftCol.addChild(label);

        const eyeGazeActiveSwitch = menuSwitch = new MenuSwitchBool(this.screen, earthpixi.Access.settings, "eyeGazeActive", Text.strings.enableEyeGaze);
        menuSwitch.canChangeProperty = false;
        menuSwitch.on("change", this.onEyeGazeChange, this);
        this.leftCol.addChild(menuSwitch);
        menuSwitch.position.set(225, 12);

        label = this.dwellLabel = new PIXI.extras.BitmapText(Text.strings.dwellClick, {
            font: "30px baloo_small",
            align: "left"
        });
        label.y = 40;
        this.leftCol.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 10, label.y + 8);
        helpButton.helpTxt = Text.strings.help_dwell;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.leftCol.addChild(helpButton);

        menuSwitch = this.dwellClickSwitch = new MenuSwitchBool(this.screen, earthpixi.Access.settings.eyeGazeConfig, "dwellClickActive", Text.strings.enableDwellClick);
        menuSwitch.on("change", this.onDwellClickChange, this);
        this.leftCol.addChild(menuSwitch);
        menuSwitch.position.set(225, 50);

        label.alpha = earthpixi.Access.settings.eyeGazeActive ? 1 : 0.5;
        menuSwitch.alpha = earthpixi.Access.settings.eyeGazeActive ? 1 : 0.5;
        menuSwitch.interactiveChildren = earthpixi.Access.settings.eyeGazeActive;

        this.eyeGazeSettingsContainer = new PIXI.Container();
        this.leftCol.addChild(this.eyeGazeSettingsContainer);

        this.eyeGazeSettingsContainer.alpha = earthpixi.Access.settings.eyeGazeActive && earthpixi.Access.settings.eyeGazeConfig.dwellClickActive ? 1 : 0.5;
        this.eyeGazeSettingsContainer.interactiveChildren = earthpixi.Access.settings.eyeGazeActive && earthpixi.Access.settings.eyeGazeConfig.dwellClickActive;

        label = new PIXI.extras.BitmapText(Text.strings.dwellClickTime, { font: "16px montserrat", align: "left" });
        label.x = 0;
        label.y = 90;
        this.eyeGazeSettingsContainer.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 10, label.y - 3);
        helpButton.helpTxt = Text.strings.help_dwellTime;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.eyeGazeSettingsContainer.addChild(helpButton);

        const dwellTimeChanger = new MenuCounter(this.screen, earthpixi.Access.settings.eyeGazeConfig, "dwellTime", "", Text.strings.seconds, [0.5, 5], 0.1);

        dwellTimeChanger.on("change", this.onSettingsChange, this);
        this.eyeGazeSettingsContainer.addChild(dwellTimeChanger);
        dwellTimeChanger.position.set(label.x, label.y + 30);

        label = new PIXI.extras.BitmapText(Text.strings.dwellAnimation, { font: "15px montserrat", align: "left" });
        label.x = 5;
        label.y = 150;
        this.eyeGazeSettingsContainer.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 140, label.y - 3);
        helpButton.helpTxt = Text.strings.help_dwellAnim;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.eyeGazeSettingsContainer.addChild(helpButton);

        textSwitch = new TextSwitch(this.screen, earthpixi.Access.settings.eyeGazeConfig, "anim", [
            { txt: Text.strings.shrink, value: "default" },
            { txt: Text.strings.pie, value: "pie" }
        ]);
        textSwitch.on("change", this.onDwellAnimChange, this);
        this.eyeGazeSettingsContainer.addChild(textSwitch);
        textSwitch.position.set(label.x + label.width + 10, label.y);

        label = new PIXI.extras.BitmapText(Text.strings.selectMode, { font: "15px montserrat", align: "left" });
        label.x = 5;
        label.y = 180;
        this.eyeGazeSettingsContainer.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 181, label.y - 3);
        helpButton.helpTxt = Text.strings.help_dwellMode;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.eyeGazeSettingsContainer.addChild(helpButton);

        textSwitch = new TextSwitch(this.screen, earthpixi.Access.settings.eyeGazeConfig, "directMode", [
            { txt: Text.strings.bigButtons, value: false },
            { txt: Text.strings.direct, value: true }
        ]);
        textSwitch.on("change", this.onDirectModeChange, this);
        textSwitch.canChangeProperty = false;
        this.eyeGazeSettingsContainer.addChild(textSwitch);
        textSwitch.position.set(label.x + label.width + 10, label.y);

        // repeat mode

        this.repeatModeContainer = new PIXI.Container();
        this.eyeGazeSettingsContainer.addChild(this.repeatModeContainer);

        label = new PIXI.extras.BitmapText(Text.strings.repeatMode, { font: "15px montserrat", align: "left" });
        label.x = 5;
        label.y = 210;
        this.repeatModeContainer.addChild(label);

        helpButton = new PIXI.Sprite(PIXI.utils.TextureCache.help_icon);
        helpButton.position.set(label.x + label.width + 150, label.y - 3);
        helpButton.helpTxt = Text.strings.help_repeat;
        helpButton.on("pointerdown", this.showHelpBubble, this);
        helpButton.eyeGazable = false;
        helpButton.interactive = true;
        helpButton.buttonMode = true;
        this.repeatModeContainer.addChild(helpButton);

        textSwitch = new TextSwitch(this.screen, earthpixi.Access.settings.eyeGazeConfig, "allowRepeat", [
            { txt: Text.strings.allowRepeat, value: true },
            { txt: Text.strings.off, value: false }
        ]);
        textSwitch.on("change", this.onRepeatModeChange, this);
        textSwitch.canChangeProperty = false;
        this.repeatModeContainer.addChild(textSwitch);
        textSwitch.position.set(label.x + label.width + 10, label.y);

        const egTestBG = this.egTest = new PIXI.Sprite(PIXI.utils.TextureCache.mini_button);

        egTestBG.position.set(label.x + 50, 240);
        egTestBG.scale.set(0.8);
        this.eyeGazeSettingsContainer.addChild(egTestBG);

        egTestBG.interactive = true;
        egTestBG.on("pointerover", this.eyeTestOver, this);
        egTestBG.on("pointerout", this.eyeTestOut, this);

        label = new PIXI.extras.BitmapText(Text.strings.test, { font: "16px montserrat", align: "left" });
        label.x = 0;
        label.y = 280;
        this.eyeGazeSettingsContainer.addChild(label);

        this.testPie = new earthpixi.Access.DwellAnimation(35);
        this.testPie.on("select", this.onTestPieActivated, this);
        // this.testPie.show(this.egTest);

        this.updateText();
    }

    showWizard()
    {
        this.screen.onBack();
        this.screen.showOB();
    }

    showHelpBubble(evt)
    {
        if (evt)
        {
            evt.stopPropagation();
        }
        this.removeBubble();

        const target = evt.currentTarget || evt.data.target;
        const bubble = this.helpBubble = new PIXI.Sprite(PIXI.utils.TextureCache.helpBub);

        this.addChild(bubble);
        const pos = evt.data.getLocalPosition(this);

        const txt = new PIXI.extras.BitmapText(target.helpTxt, { font: "14px montserrat", align: "left" });

        txt.tint = 0x006837;
        txt.maxWidth = 130;
        txt.x = 37;
        txt.y = 12;
        bubble.addChild(txt);

        bubble.position.set(pos.x + 10, pos.y - 50);
        bubble.interactive = true;
        // target.once("pointerup", this.removeBubble, this);
        // target.once("pointerupoutside", this.removeBubble, this);
        bubble.on("pointerdown", this.removeBubble, this);
        // bubble.on("pointerup", this.removeBubble, this);
        // bubble.on("pointerupoutside", this.removeBubble, this);
    }

    removeBubble(evt)
    {
        if (evt)
        {
            console.log(evt);
            evt.stopPropagation();
        }

        if (this.helpBubble)
        {
            this.removeChild(this.helpBubble);
            this.helpBubble.destroy({ children: true });
            this.helpBubble = null;
        }
    }

    onSwitchAccessChange(enabled)
    {
        earthpixi.Tween.to(this.switchSettingsContainer, 0.3, { alpha: enabled ? 1 : 0.5 });
        this.switchSettingsContainer.interactiveChildren = enabled;

        earthpixi.Access.switchAccess(enabled);

        this.onSettingsChange();
    }

    onDwellAnimChange()
    {
        this.testPie.destroy();
        this.testPie = new earthpixi.Access.DwellAnimation(35);
        this.testPie.addEvent("select", this.onTestPieActivated, this);
        this.onSettingsChange(false);
    }

    onDirectModeChange(enabled)
    {
        this.onSettingsChange();
        earthpixi.Access.directMode(earthpixi.Access.settings.eyeGazeConfig.directMode);
    }

    onEyeGazeChange(enabled)
    {
        earthpixi.Tween.to(this.eyeGazeSettingsContainer, 0.3, { alpha: enabled ? 1 : 0.5 });
        this.eyeGazeSettingsContainer.interactiveChildren = enabled;

        earthpixi.Tween.to([this.dwellClickSwitch, this.dwellLabel], 0.3, { alpha: enabled ? 1 : 0.5 });
        this.dwellClickSwitch.interactiveChildren = enabled;

        earthpixi.Access.eyeGaze(enabled);
        this.onSettingsChange();
    }

    onDwellClickChange(enabled)
    {
        earthpixi.Tween.to(this.eyeGazeSettingsContainer, 0.3, { alpha: enabled ? 1 : 0.5 });
        earthpixi.Access.settings.eyeGazeConfig.dwellClickActive = enabled;
        this.eyeGazeSettingsContainer.interactiveChildren = enabled;
        this.onSettingsChange();
    }

    onRepeatModeChange()
    {
        this.onSettingsChange();
    }

    onScanTypeChange(type)
    {
        if (type === 2)
        {
            this.scanTimeSettings.alpha = 0.5;
            this.scanTimeSettings.interactiveChildren = false;
        }
        else
        {
            this.scanTimeSettings.alpha = 1;
            this.scanTimeSettings.interactiveChildren = true;
        }
        this.onSettingsChange();
    }

    onSettingsChange(updateText = true)
    {
        this.removeBubble();

        if (updateText)
        {
            this.updateText();
        }

        se_platform.IO.saveSettings();

        if (this.screen.localStorageData)
        {
            this.screen.localStorageData.settingsStored++;
            this.screen.saveLocal();
        }

        if (earthpixi.currentScreen.onSettingsChange)
        {
            earthpixi.currentScreen.onSettingsChange();
        }

        if (SettingsOnlyScreen.onSettingsChange)
        {
            SettingsOnlyScreen.onSettingsChange(se_platform.User.settings);
        }
    }

    onTestPieActivated()
    {
        this.egTest.texture = PIXI.utils.TextureCache.mini_button_down;
        this.testPie.x += 3;
        this.testPie.y += 3;

        if (earthpixi.Access.settings.eyeGazeConfig.allowRepeat)
        {
            this.testPie.show(this.egTest);
            earthpixi.delayedCall(0.5, this.eyeTestUp, [], this);
        }
    }

    eyeTestOver()
    {
        this.testPie.show(this.egTest);
    }

    eyeTestOut()
    {
        this.testPie.hide(this.egTest);
        this.eyeTestUp();
    }

    eyeTestDown()
    {
        this.egTest.texture = PIXI.utils.TextureCache.mini_button_down;
    }

    eyeTestUp()
    {
        this.egTest.texture = PIXI.utils.TextureCache.mini_button;
    }

    updateText()
    {
        const key1 = earthpixi.Keyboard.KeyCodesDict[earthpixi.Access.settings.switchConfig.switch1KeyCode].toUpperCase();
        const key2 = Array.isArray(earthpixi.Access.settings.switchConfig.switch2KeyCode)
            ? earthpixi.Keyboard.KeyCodesDict[earthpixi.Access.settings.switchConfig.switch2KeyCode[0]].toUpperCase()
            : earthpixi.Keyboard.KeyCodesDict[earthpixi.Access.settings.switchConfig.switch2KeyCode].toUpperCase();

        if (!earthpixi.Access.settings.switchActive && !earthpixi.Access.settings.eyeGazeActive)
        {
            this.infoText.text = Text.strings.infoEyeGazeSwitchOff;
        }

        if (earthpixi.Access.settings.switchActive && !earthpixi.Access.settings.eyeGazeActive)
        {
            if (earthpixi.Access.settings.switchConfig.switchMode === 1)
            {
                let str = `${Text.strings.infoSwitchEnabled} ${Text.strings.playable1Switch} ${Text.strings.keyInfo2} `;

                str = str.replace("$scanTime", earthpixi.Access.settings.switchConfig.scanTime.toString());
                str = str.replace("$key1", key1);
                str = str.replace("$key1", key1);
                this.infoText.text = str;
            }
            else
            {
                let str = `${Text.strings.infoSwitchEnabled} ${Text.strings.playable2Switch} ${Text.strings.keyInfo1} `;

                str = str.replace("$scanTime", earthpixi.Access.settings.switchConfig.scanTime.toString());
                str = str.replace("$key1", key1);
                str = str.replace("$key1", key1);
                str = str.replace("$key2", key2);
                this.infoText.text = str;
            }
        }

        if (earthpixi.Access.settings.switchActive && earthpixi.Access.settings.eyeGazeActive)
        {
            if (earthpixi.Access.settings.switchConfig.switchMode === 1)
            {
                let str = `${Text.strings.eyegazeAndSwitch} ${Text.strings.playableEyeAnd1Switch} ${Text.strings.keyInfo2}`;

                str = str.replace("$scanTime", earthpixi.Access.settings.switchConfig.scanTime.toString());
                str = str.replace("$key1", key1);
                str = str.replace("$key1", key1);
                str = str.replace("$key1", key1);
                str = str.replace("$key2", key2);

                this.infoText.text = str;
            }
            else
            {
                let str = `${Text.strings.eyegazeAndSwitch} ${Text.strings.playableEyeAnd2Switch} ${Text.strings.keyInfo1}`;

                str = str.replace("$scanTime", earthpixi.Access.settings.switchConfig.scanTime.toString());
                str = str.replace("$key1", key1);
                str = str.replace("$key1", key1);
                str = str.replace("$key1", key1);
                str = str.replace("$key2", key2);

                this.infoText.text = str;
            }
        }

        if (!earthpixi.Access.settings.switchActive && earthpixi.Access.settings.eyeGazeActive)
        {
            if (earthpixi.Access.settings.eyeGazeConfig.dwellClickActive)
            {
                this.infoText.text = Text.strings.playableEyeGaze.replace("$dwellTime", earthpixi.Access.settings.eyeGazeConfig.dwellTime);
                if (earthpixi.Access.settings.eyeGazeConfig.directMode)
                {
                    this.infoText.text += ` ${Text.strings.directMode}`;
                }
            }
            else
            {
                this.infoText.text = Text.strings.playableEyeGazeNoDwell;
            }
        }

        this.infoText.position.set((this.bg.width - this.infoText.width - 5) / 2, 35 + (40 - (this.infoText.height / 2)));

        earthpixi.Tween.kill(this.infoText);
        this.infoText.alpha = 0;
        earthpixi.Tween.to(this.infoText, 0.5, { alpha: 1 });
    }
}
