import UserModel from "../../model/UserModel";
import APIService from "../APIService";
import ServerSettings from "../../../config/ServerSettings";

export default class UserService extends APIService
{
    public createNewUser(callback: (data: UserModel) => void, callbackContext?: any, error?: (response: Response) => void): void
    {
        this.fetchAPI(`${ServerSettings.url}user/create`, {
            method: "POST",
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback, callbackContext, error);
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    public genNewUserName(callback: (data: object) => void, callbackContext?: any, error?: (response: Response) => void, notFound?: any): void
    {
        this.fetchAPI(`${ServerSettings.url}user/username`, {
            method: "GET",
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback, callbackContext, error, notFound);
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    public switchActiveUser(password:string, callback: (data: object) => void, callbackContext?: any, error?: (response: Response) => void, notFound?: any)
    {
        console.log("switch active user: " + password);

        this.fetchAPI(`${ServerSettings.url}user/${password}`, {
            method: "GET",
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback, callbackContext, error, notFound);
            }, (err) =>
            {
                console.log("error: " + err);
            });
    }

    private onActiveUserSwitched(user:UserModel):void
    {
        se_platform.User.copy(user);
        earthpixi.Access.settings.copy(user.settings);
        se_platform.LocalStorage.save();

        console.log("OnActiveUserSwitched: " + earthpixi.Access.settings.eyeGazeActive + " / " + earthpixi.Access.settings.switchActive);
        //earthpixi.Access.eyeGaze(earthpixi.Access.settings.eyeGazeActive);
        //earthpixi.Access.switchAccess(earthpixi.Access.settings.switchActive);
    }

    private onActiveUserFetchNotFound(): void
    {
        console.log("ActiveUserSwitchFailed: User Not Found!");
    }

    private onActiveUserFetchError(response: Response): void
    {
        console.log("ActiveUserSwitchedFailed: Error: ");
    }

    public getExistingUser(password: string, callback: (data: UserModel) => void, callbackContext?: any, error?: (response: Response) => void, notFound?: any): void
    {
        console.log("get existing user");

        this.fetchAPI(`${ServerSettings.url}user/${password}`, {
            method: "GET",
            Headers: {
                "sec-fetch-mode": "no-cors"
            }
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback, callbackContext, error, notFound);
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    public updateUserSettings(password: string, callback: (obj: UserModel) => void, error?: (response: Response) => void, callbackContext?: any): void
    {
        console.log("update user settings", se_platform.User.settings);

        const jsonData = JSON.stringify(se_platform.User.toJSON());

        this.fetchAPI(`${ServerSettings.url}user/${password}/update`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: jsonData,
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback || this.updateUserSettingsSuccess, callbackContext || UserService);
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    public updateUserName(password: string, username: string, callback: (obj: UserModel) => void, callbackContext?: any, error?: (response: Response) => void): void
    {
        const jsonData = JSON.stringify({ username });

        this.fetchAPI(`${ServerSettings.url}user/${password}/username`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: jsonData,
        })
            .then(APIService.fetchErrorCheck)
            .then((res) =>
            {
                APIService.fetchResponded(res, callback || this.updateUserSettingsSuccess, callbackContext || UserService);
            }, (err) =>
            {
                APIService.fetchRejected(err, error, callbackContext);
            });
    }

    public updateUserSettingsSuccess(data: any)
    {
        console.log("user settings update on server success", data);
    }
}
