import Text from "../../config/text";
import ConfirmOverlay from "../../menu_game/ConfirmOverlay";
import RoomListItem from "./RoomListItem";

export default class RoomsList extends earthpixi.GameObject
{
    constructor(gameId)
    {
        super();

        this._listPos = 0;

        const bg = earthpixi.utils.Draw.DrawRoundedRect({ x: 0, y: 0, width: 310, height: 350 }, 15, 0xffffff);

        bg.tint = 0x60cfc2;

        const mask = new PIXI.Sprite(bg.texture);

        // earthpixi.utils.Draw.DrawRoundedRect({ x: 0, y: 0, width: 256, height: 256 },15, 0xffffff);

        mask.width = bg.width;
        mask.height = bg.height;

        this.addChild(bg);
        this.addChild(mask);

        this.listContainer = new PIXI.Container();
        this.listContainer.mask = mask;
        this.addChild(this.listContainer);

        this.listItems = [];

        this.upButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.small_menu_button,
            PIXI.utils.TextureCache.small_menu_button_on,
            Text.strings.up, true
        );
        this.upButton.addIcon(PIXI.utils.TextureCache.pink_arrow_up);
        this.upButton.y = -30;
        this.upButton.x = bg.width + 15;
        this.upButton.addEvent("select", this.onUp, this);
        this.addChild(this.upButton);

        this.downButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.small_menu_button,
            PIXI.utils.TextureCache.small_menu_button_on,
            Text.strings.down, true
        );
        this.downButton.addIcon(PIXI.utils.TextureCache.pink_arrow_up);
        this.downButton.icon.rotation = PIXI.DEG_TO_RAD * 180;
        this.downButton.y = 100;
        this.downButton.x = bg.width + 15;
        this.downButton.addEvent("select", this.onDown, this);
        this.addChild(this.downButton);

        this.editButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.small_menu_button,
            PIXI.utils.TextureCache.small_menu_button_on,
            Text.strings.edit, false
        );
        this.editButton.addText(Text.strings.edit, 20, "baloo_small");
        this.editButton.y = 250;
        this.editButton.x = bg.width + 15;
        this.editButton.addEvent("select", this.onEdit, this);
        this.addChild(this.editButton);

        // get list of rooms and whos online

        se_platform.IO.RoomService.getMyRooms(gameId, this.gotRooms, this);
    }

    /**
     *
     * @param error
     * @param roomList A List of our players current open game rooms in the db
     * @param roomClientLists A list of who is connected in each room
     */
    gotRooms(error, roomList, roomClientLists)
    {
        if (error) console.log(error);

        // console.log("Room list got rooms", error, roomList, roomClientLists);

        this.fillList(roomList, roomClientLists);
    }

    fillList(roomList, roomClientLists)
    {
        // sort rooms by updated date?

        this.listItems = [];

        let yPos = 0;

        for (let i = 0; i < roomList.length; i++)
        {
            const room = roomList[i];

            const connectedClientInfo = roomClientLists.find(({ roomId }) => roomId === room._id);

            const listItem = new RoomListItem(room, connectedClientInfo.connectedUsers);

            listItem.addEvent("select", this.roomListItemSelected, this);

            listItem.y = yPos;
            this.listItems.push(listItem);
            this.listContainer.addChild(listItem);

            yPos += listItem.height;
        }

        earthpixi.Access.soloAddButtons(this.listContainer);
    }

    roomListItemSelected(evt)
    {
        const selectedListItem = evt.currentTarget;

        for (const listItem of this.listItems)
        {
            listItem.showOn(false);
        }

        selectedListItem.showOn(true);

        if (this.editMode)
        {
            this.confirmOverlay = new ConfirmOverlay(
                Text.strings.deleteGame.replace("$userName", selectedListItem.room.opponent.username),
                () =>
                {
                    this.deleteListItem(selectedListItem);
                    this.editMode = false;
                },
                () =>
                {
                    this.editMode = false;
                },
                this,
                true,
                Text.strings.delete,
                Text.strings.cancel
            );
            earthpixi.stageOverlay.addChild(this.confirmOverlay);
        }
        else
        {
            this.emit("room_selected", selectedListItem.room);
        }
    }

    deleteListItem(itemToDelete)
    {
        let yPos = 0;

        for (let i = 0; i < this.listItems.length; i++)
        {
            const listItem = this.listItems[i];

            if (listItem !== itemToDelete)
            {
                earthpixi.Tween.to(listItem, 0.3, { y: yPos });

                yPos += listItem.height;
            }
        }

        this.listItems.splice(this.listItems.indexOf(itemToDelete), 1);

        itemToDelete.destroy({ children: true });

        se_platform.IO.RoomService.deleteRoom(itemToDelete.room._id);
    }

    get editMode()
    {
        return this._editMode;
    }

    set editMode(bool)
    {
        if (this._editMode === bool) return;

        this._editMode = bool;

        for (const listItem of this.listItems)
        {
            listItem.editMode = this._editMode;
        }
    }

    onEdit()
    {
        this.editMode = !this.editMode;
    }

    onUp()
    {

    }

    onDown()
    {

    }
}
