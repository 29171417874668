import Text from "../../config/text";
import QueryName from "../../navigation/enums/QueryName";
import OptionButton from "../../menu/onboarding/OptionButton";
import RoomsList from "./RoomsList";
/**
 *
 */
export default class OnlineGameBox extends earthpixi.GameObject
{
    /**
     *
     * @param {number} gameId
     */
    constructor(gameId)
    {
        super();

        this.gameId = gameId;

        const bg = this.bg = new PIXI.Sprite(PIXI.utils.TextureCache.green_card);

        bg.interactive = true;
        this.addChild(bg);

        this.leftCol = new PIXI.Container();
        this.leftCol.position.set(20, 40);
        this.addChild(this.leftCol);

        let ySpace = 0;
        const xSpace = 0;
        const defSpacing = 30;

        // Join Game
        // edit user name
        const label = new PIXI.extras.BitmapText(Text.strings.joinGame, { font: "30px baloo_small", align: "left" });

        label.y = ySpace;
        label.x = xSpace;
        this.leftCol.addChild(label);

        ySpace += label.height;

        // editing game code

        ySpace += defSpacing;
        const inputBG = earthpixi.utils.Draw.DrawRoundedRect({ x: 0, y: 0, width: 265, height: 50 }, 15, 0xFFFFFF);

        this.leftCol.addChild(inputBG);
        inputBG.x = xSpace;
        inputBG.y = ySpace;

        const input = this.gameCodeInput = earthpixi.Input.createInput(
            { font: "26px baloo_small", align: "centre" }, Text.strings.enterCode,
            0x7F47DD05, 230, false, false, true,
            true, 90, false, null, 1, 5);

        input.bg.alpha = 0;
        input.x = xSpace + 7;
        input.y = ySpace + 4;
        this.leftCol.addChild(input);
        ySpace += defSpacing;
        // ySpace += defSpacing;

        input.text = se_platform.Navigation.query(QueryName.ROOM_ID) || Text.strings.enterCode;
        input.fieldCheckText.x += 70;
        input.fieldCheckText.y -= 10;
        input.customCheck = this.checkInput;
        earthpixi.Input.onSubmit = this.submitGameCode.bind(this);

        if (navigator.clipboard && navigator.clipboard.readText)
        {
            this.pasteButton = new PIXI.Sprite(PIXI.utils.TextureCache.paste);
            this.pasteButton.y = input.y + 60;
            this.pasteButton.interactive = true;
            this.pasteButton.buttonMode = true;
            this.pasteButton.on("pointerdown", this.onPasteFromClipboard, this);

            this.leftCol.addChild(this.pasteButton);
        }

        this.clearInputButton = new PIXI.Sprite(PIXI.utils.TextureCache.exit_button);
        this.clearInputButton.x = input.x + input.width - 18;
        this.clearInputButton.y = input.y + 8;
        this.clearInputButton.tint = 0x6C6B6C;
        this.clearInputButton.scale.set(0.4);
        this.clearInputButton.interactive = true;
        this.clearInputButton.buttonMode = true;
        this.clearInputButton.on("pointerdown", this.clearInput, this);
        this.leftCol.addChild(this.clearInputButton);

        // room code buttons
        this.submitGameCodeButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.small_menu_button,
            PIXI.utils.TextureCache.small_menu_button_on,
            Text.strings.submit, false
        );
        this.submitGameCodeButton.y = ySpace - 47;
        this.submitGameCodeButton.x = xSpace + 274;
        this.submitGameCodeButton.addEvent("select", this.submitGameCode, this);
        this.submitGameCodeButton.addText(Text.strings.go, 30, "baloo_small");
        this.submitGameCodeButton.setScale(0.8);
        this.leftCol.addChild(this.submitGameCodeButton);

        // room code buttons
        // this.clearCodeButton = new earthpixi.Access.Button(
        //     PIXI.utils.TextureCache.small_menu_button,
        //     PIXI.utils.TextureCache.small_menu_button_on,
        //     Text.strings.clearCode, false
        // );
        // this.clearCodeButton.y = ySpace;
        // this.clearCodeButton.x = xSpace + 30;
        // this.clearCodeButton.addEvent("select", this.onClearCode, this);
        // this.clearCodeButton.addIcon(PIXI.utils.TextureCache.cancel_icon);
        // this.leftCol.addChild(this.clearCodeButton);

        ySpace += 190;

        this.newInfoText = new PIXI.extras.BitmapText(
            Text.strings.newOnlineGameInfo,
            { font: "17px montserrat", align: "left" }
        );
        // this.newInfoText.res
        this.newInfoText.maxWidth = 180;
        this.newInfoText.position.set(xSpace + 10, ySpace);
        this.leftCol.addChild(this.newInfoText);

        // room code buttons
        this.newGameButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.small_menu_button,
            PIXI.utils.TextureCache.small_menu_button_on,
            Text.strings.genNewUser, false
        );
        this.newGameButton.addText(Text.strings.newGame, 30, "baloo");
        this.newGameButton.setScale(1.3);
        this.newGameButton.y = ySpace;
        this.newGameButton.x = xSpace + 200;
        this.newGameButton.addEvent("select", this.createRoom, this);
        this.leftCol.addChild(this.newGameButton);

        // Right column / room list

        this.rightCol = new PIXI.Container();
        this.rightCol.position.set(400, 40);
        this.addChild(this.rightCol);

        const listLabel = new PIXI.extras.BitmapText(Text.strings.yourOnlineGames, { font: "30px baloo_small", align: "left" });

        this.rightCol.addChild(listLabel);

        this.roomsList = new RoomsList(gameId);
        this.roomsList.y = 60;
        this.roomsList.on("room_selected", this.roomSelectedInList, this);
        this.rightCol.addChild(this.roomsList);
    }

    set waiting(waiting)
    {
        this.interactiveChildren = !waiting;
    }

    createRoom(evt)
    {
        this.newGameButton.interactive = false;
        this.newGameButton.alpha = 0.8;
        earthpixi.Tween.to(this.newGameButton, 0.5, { alpha: 0.3, yoyo: true, repeat: -1 });

        // we'll let the game know manually when joined
        se_platform.IO.RoomService.shouldUpdateGame = false;

        se_platform.IO.RoomService.joinRoom(
            null, // pass null and server to create new room
            this.gameId,
            this.joinedRoom,
            null,
            null,
            this
        );
    }

    onClearCode(evt)
    {
        this.gameCodeInput.text = " ";
        this.gameCodeInput.activate();
    }

    roomSelectedInList(roomModel)
    {
        this.gameCodeInput.text = roomModel._id;
        this.submitGameCodeButton.setScale(0.8);
        earthpixi.Tween.to(this.submitGameCodeButton.scale, 0.2, { x: 1, y: 1, repeat: 1, yoyo: true });
    }

    clearInput()
    {
        this.gameCodeInput.text = " ";
    }

    submitGameCode(evt)
    {
        console.log("submit");
        console.log(this.gameCodeInput.checkContent(true));

        if (!this.gameCodeInput.checkContent(true)) return;

        // we'll let the game know manually when joined
        se_platform.IO.RoomService.shouldUpdateGame = false;

        se_platform.IO.RoomService.joinRoom(
            this.gameCodeInput.text,
            this.gameId,
            this.joinedRoom,
            this.roomNotFound,
            this.roomNotAllowed, this
        );
        this.waiting = true;
    }

    /**
     *
     * @param roomModel
     */
    joinedRoom(roomModel)
    {
        this.waiting = false;
        se_platform.Navigation.query(se_platform.QueryName.ROOM_ID, roomModel._id);
        this.submitGameCodeButton.visible = false;
        this.joinedRoomSuccess();
    }

    joinedRoomSuccess(newRoom = true)
    {
        this.leftCol.visible = false;
        this.rightCol.visible = false;

        this.newRoomBox = new PIXI.Container();
        this.newRoomBox.x = 50;

        let message = new PIXI.extras.BitmapText(Text.strings.joinedGame,
            { font: "40px baloo", align: "center", tint: 0x222133 });

        message.position.set(0, 50);
        this.newRoomBox.addChild(message);

        message = new PIXI.extras.BitmapText(Text.strings.roomCodeInfo1,
            { font: "18px montserrat", align: "center", tint: 0x222133 });
        message.position.set(0, 110);
        this.newRoomBox.addChild(message);

        const info = new PIXI.extras.BitmapText(se_platform.Navigation.url,
            { font: "22px baloo", align: "center", tint: 0x222133 });

        // info.position.set(0, 150);
        // this.newRoomBox.addChild(info);

        message = new PIXI.extras.BitmapText(Text.strings.roomCodeInfo2,
            { font: "18px montserrat", align: "center", tint: 0x222133 });
        // message.position.set(0, 200);
        message.position.set(0, 150);
        this.newRoomBox.addChild(message);

        const secretWord = new PIXI.extras.BitmapText(se_platform.IO.RoomService.room._id,
            { font: "40px baloo", align: "center", tint: 0x02513C });

        secretWord.position.set(0, 180);
        this.newRoomBox.addChild(secretWord);

        this.startBtn = new OptionButton(Text.strings.start, PIXI.utils.TextureCache.game_button, PIXI.utils.TextureCache.game_button_on, Text.strings.start);

        this.startBtn.x = 0;
        this.startBtn.y = 290;
        this.startBtn.addEvent("select", this.onStart, this);
        this.newRoomBox.addChild(this.startBtn);

        this.copyButton = new PIXI.Sprite(PIXI.utils.TextureCache.copy_clipboard_icon);
        this.copyButton.y = 240;
        this.copyButton.x = secretWord.width + 20;
        this.copyButton.interactive = true;
        this.copyButton.buttonMode = true;
        this.copyButton.on("pointerup", this.onCopyToClipboard, this);
        this.newRoomBox.addChild(this.copyButton);

        // this.copyAddressButton = new PIXI.Sprite(PIXI.utils.TextureCache.copy_clipboard_icon);
        // this.copyAddressButton.y = 150;
        // this.copyAddressButton.x = info.width + 20;
        // this.copyAddressButton.interactive = true;
        // this.copyAddressButton.buttonMode = true;
        // this.copyAddressButton.on("pointerup", this.onCopyAddressToClipboard, this);
        // this.newRoomBox.addChild(this.copyAddressButton);

        earthpixi.Access.soloButtons([this.parent.exitButton, this.newGameButton, this.startBtn]);

        this.addChild(this.newRoomBox);
    }

    onCopyToClipboard(evt)
    {
        earthpixi.utils.CopyToClipboard(se_platform.IO.RoomService.room._id);

        this.copyButton.alpha = 0.5;
        earthpixi.Tween.kill(this.copyButton);
        earthpixi.Tween.to(this.copyButton, 0.5, { alpha: 1 });
    }

    onPasteFromClipboard()
    {
        // document.execCommand("paste");

        earthpixi.utils.PasteFromClipboard(this.gameCodeInput);

        earthpixi.Tween.kill(this.pasteButton);
        earthpixi.Tween.to(this.pasteButton, 0.5, { alpha: 1 });
    }

    onCopyAddressToClipboard()
    {
        earthpixi.utils.CopyToClipboard(se_platform.Navigation.url);

        this.copyAddressButton.alpha = 0.5;
        earthpixi.Tween.kill(this.copyAddressButton);
        earthpixi.Tween.to(this.copyAddressButton, 0.5, { alpha: 1 });
    }

    onStart()
    {
        this.emit("done");
        se_platform.IO.RoomService.shouldUpdateGame = true;
        se_platform.IO.RoomService.joinedRoom(se_platform.IO.RoomService.room, se_platform.IO.RoomService.opponentInRoom, se_platform.User._id);
    }

    /**
     *
     *
     */
    roomNotAllowed()
    {
        console.log("room doesn't exist,  room not allowed for this user, or room full");
        this.gameCodeInput.text = " ";
        this.gameCodeInput.checkContent();
        this.waiting = false;
    }

    /**
     *
     *
     */
    roomNotFound()
    {
        console.log("room not found");
        this.gameCodeInput.text = " ";
        this.gameCodeInput.checkContent();
        this.waiting = false;
    }

    /**
     *
     * @return {string}
     */
    checkInput()
    {
        return Text.strings.gameNotFound;
    }
}
