/**
 *
 */
export default class LoadingScreen extends earthpixi.LoadingScreen
{
    /**
     *
     * @param {ScreenAsset[]} assetList
     * @param {Class<earthpixi.Screen>} screenClass
     * @param {boolean} [fadeIn]
     * @param {boolean} [clearCache]
     * @param {boolean} [clearAudioCache]
     * @param {boolean} [stopAudio]
     */
    constructor(assetList, screenClass, fadeIn = true, clearCache = true, clearAudioCache = true, stopAudio = true)
    {
        super(assetList, screenClass, fadeIn, clearCache, clearAudioCache, stopAudio);

        // var spr = new PIXI.Sprite(PIXI.Texture.fromImage(earthpixi.config.FW_ROOT + "loading/Default@"+earthpixi.resolution+"x.png"));
        // spr.texture.resolution = earthpixi.resolution;
        // this.addChild(spr);

        earthpixi.config.FIT_MODE = earthpixi.config.FIT_MODES.FIXED_HEIGHT;
        earthpixi.config.STAGE_WIDTH = 1366;
        earthpixi.needsResize = true;

        this.addBG();

        this.addChild(this.loadBar);

        // flag to shoe footer html
        earthpixi.showCW = false;
        earthpixi.resizePreFix();
    }

    addBG()
    {
        let spr = new PIXI.Sprite(PIXI.Texture.fromImage(`${earthpixi.config.FW_ROOT}loading/logo@${earthpixi.resolution}x.png`));

        spr.position.set(465, 320);
        spr.alpha = 0.3;
        this.addChild(spr);

        spr = new PIXI.Sprite(PIXI.Texture.fromImage(`${earthpixi.config.FW_ROOT}loading/sp_logo@${earthpixi.resolution}x.png`));
        spr.position.set(550, 120);
        spr.alpha = 0.5;
        this.addChild(spr);

        spr = new PIXI.Sprite(PIXI.Texture.fromImage(`${earthpixi.config.FW_ROOT}loading/loading_text@${earthpixi.resolution}x.png`));
        spr.position.set(570, 550);
        spr.alpha = 1;
        spr.tint = 0xE2FAFC;
        this.addChild(spr);
    }
}
