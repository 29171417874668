/**
 * @enum {number}
 * @property {number} SOLITAIRE
 * @property {number} CONNECT4
 * @property {number} CHESS
 * @property {number} CHECKERS
 * @property {number} SUDOKU
 * @property {number} SUDOKU_MONSTERS
 * @property {number} MUSIC_PLAYER
 * @property {number} MOLE_MINERS
 */
enum GameID {
    SOLITAIRE = 1,
    CONNECT4 = 2,
    CHESS = 3,
    CHECKERS = 4,
    SUDOKU = 5,
    SUDOKU_MONSTERS = 6,
    MUSIC_PLAYER = 7,
    MOLE_MINERS = 8,
    EYEDRIVE = 9,
    BOOK = 10,
    GAZEY_GOLF = 11,
    SNAKES_AND_LADDERS = 12,
    OTHELLO = 13,
    OPENDRIVE = 14
}

export default GameID;
