import { parseUrl, stringify } from "query-string";
// @ts-ignore
import LoadingScreen from "../menu/screens/LoadingScreen";
// @ts-ignore
import MenuScreen from "../menu/screens/MenuScreen";
import SettingsOnlyScreen from "../menu/screens/SettingsOnlyScreen";
import UserScreen from "../menu/screens/UserScreen";
import URLAddressPart from "./enums/URLAddressPart";
import URLScheme from "./URLScheme";
import { sendMessage } from "../../../../game_entry_files/sendMessage";

// / <reference path='../../../se_games/@types/se_games.d.ts' />

/**
 *
 * Navigation / Hash manager.
 *
 * example url format:
 *
 * eyegazegames.com/#/chess/game/2player?gid=100
 * eyegazegames.com/#/chess/options/?gid=100
 *
 * @class
 *
 */
export default class NavigationManager
{
    private _currentHash: string = "";
    private _onHashChange: EventListener|EventListenerObject;
    private _onPopState: EventListener|EventListenerObject;
    private _queriesDict: any = {};
    private _init: boolean = false;

    private _game: string;
    private _mode: se_platform.GameMode;
    private _location: se_platform.GameLocation;

    /**
     * @type {string}
     */
    get game(): string
    {
        return this._game;
    }

    /**
     * @type {string}
     */
    get url(): string
    {
        return `${window.location.host}/${this._currentHash}`;
    }

    set game(gameName: string)
    {
        if (gameName === this._game)
        {
            return;
        }

        this._game = gameName;
        this._location = se_platform.GameLocation.NONE;
        this._mode = se_platform.GameMode.NONE;

        this.updateHash();
        this.loadCurrentGameType();
    }

    /**
     * @type {se_platform.GameMode}
     */
    get mode(): se_platform.GameMode
    {
        return this._mode;
    }

    set mode(gameMode: se_platform.GameMode)
    {
        // console.log("set mode");
        if (this._mode === gameMode)
        {
            return;
        }

        // console.log("mode changed", gameMode);
        this._mode = gameMode || se_platform.GameMode.NONE;
        this.clearQueries();
        this.updateHash();
    }

    /**
     * @type {se_platform.GameLocation}
     */
    get location(): se_platform.GameLocation
    {
        return this._location;
    }

    set location(gameLocation: se_platform.GameLocation)
    {
        console.log(" - set location", gameLocation);
        if (this._location === gameLocation)
        {
            return;
        }

        // console.log(" - location changed to", gameLocation);
        this._location = gameLocation;

        this.updateHash();
    }

    private _navActive: boolean = false;

    /**
     *
     * @type {boolean}
     */
    get navActive(): boolean
    {
        return this._navActive;
    }

    set navActive(val: boolean)
    {
        this._navActive = val;
    }

    public init()
    {
        if (this._init)
        {
            return;
        }

        this._init = true;
        this._game = "";
        this._location = se_platform.GameLocation.NONE;
        this._mode = se_platform.GameMode.NONE;

        this._onHashChange = this.onHashChange.bind(this) as EventListenerOrEventListenerObject;
        this._onPopState = this.onPopState.bind(this) as EventListenerOrEventListenerObject;
        window.addEventListener("hashchange", this._onHashChange);
        window.addEventListener("popstate", this._onPopState);
        this.onHashChange(null);
    }

    /**
     * Just read queries and navigates to default
     */
    public navigate(game: string)
    {
        this.init();
        
        let targetGameName = ''
        if(game){
            targetGameName = game
        }
        else{
            // getting game to load from url
            targetGameName = window.location.hash.replace('#/', '')
            console.log('got target game name: ', targetGameName)
            if(targetGameName === 'open_drive' || targetGameName === 'eye_drive'){
                targetGameName = targetGameName.replace('_', '')
            }

        }

        console.log('SE_PLATFORM.NAVIGATE > Game was: ', game, ' indexing with targetGameNme is ', targetGameName);
        
        (game === 'moleminers' || targetGameName === 'moleminers') && (targetGameName = 'MOLE_MINERS');
        (game === 'sudokumonsters' || targetGameName === 'sudokumonsters') && (targetGameName = 'SUDOKU_MONSTERS');
        (game === 'snakesandladders' || targetGameName === 'snakesandladders') && (targetGameName = 'SNAKES_AND_LADDERS');
        (game === 'gazeygolf' || targetGameName === 'gazeygolf') && (targetGameName = 'GAZEY_GOLF');
        (game === 'music' || targetGameName === 'music') && (targetGameName = 'MUSIC_PLAYER');
        (game === 'musicplayer' || targetGameName === 'musicplayer') && (targetGameName = 'MUSIC_PLAYER');
        (game === 'bookreader' || targetGameName === 'bookreader') && (targetGameName = 'BOOK');

        let targetGame = targetGameName && (se_platform as any).GameTypes[ targetGameName.toUpperCase() ]
        console.log('got target game: ', targetGame)
        targetGame ? this.setGame(targetGame) : this.setGame()
        this.navActive = true;
    }

    /**
     * Get current value of query string, optionally pass in value to set
     *
     * @param {se_platform.QueryName} name
     * @param {string} [value]
     * @returns {string}
     */
    public query(name: se_platform.QueryName, value: string | any = undefined): string
    {
        if (value !== undefined)
        {
            this._queriesDict[name] = !value ? undefined : value;
            this.updateHash(false);
        }

        return this._queriesDict[name];
    }

    /**
     * Load the Menu screen and clear hash
     *
     * @param {boolean} saveQueries
     */
    public home(saveQueries: boolean = false)
    {
        console.log('going home!')

        this._game = "";
        this._location = se_platform.GameLocation.NONE;
        this._mode = se_platform.GameMode.NONE;

        if (!saveQueries)
        {
            this._queriesDict = {};
        }

        this.updateHash();

        earthpixi.setScreen(new LoadingScreen(
            MenuScreen.assetList, MenuScreen,
        ));
    }

    /**
     *  Load the Settings Only screen and clear hash
     */
    public settings()
    {
        earthpixi.setScreen(new LoadingScreen(
            SettingsOnlyScreen.assetList, SettingsOnlyScreen,
        ));
    }

    public settingsScreen():SettingsOnlyScreen
    {
        return SettingsOnlyScreen.instance;
    }

    public options()
    {
        this.mode = se_platform.GameMode.NONE;
        this.location = se_platform.GameLocation.CHOOSE_OPTIONS;
        this.loadCurrentGameType();
    }

    /**
     * Loads game currently in hash,  or sets the hash and loads the new game provided
     * @param {se_platform.GameModel} [game]
     */
    public setGame(game?: se_platform.GameModel): void
    {
        if (game)
        {
            this.game = game.gameName as string;
        }
        else
        {
            this.loadCurrentGameType();
        }
    }

    /**
     * @private
     */
    public loadCurrentGameType()
    {
        console.log('loading current game type')

        const games = se_platform.GameTypes.array;

        if (se_platform.GameMenu._init)
        {
            se_platform.GameMenu.reset();
            se_platform.GameMenu.hide(true);
        }

        // if (this.game === "settings")
        // {
        //     this.settings();
        //     console.warn("load settings");

        //     return;
        // }

        for (let i = 0; i < games.length; i++)
        {
            const game: se_platform.GameModel = games[i];

            if (this.game === game.gameName)
            {
                if (!se_platform.IO.offline)
                {
                    se_platform.IO.GameService.currentGameTypeID = game.id;
                }
                se_games.loadGame(game);

                return;
            }
        }

        // no game found so go home
        // this.home();
    }

    public onPopState(evt: Event | null): void
    {
        //console.log("pop state", evt);
    }

    public onHashChange(evt: Event | null): void
    {
        // lop off the # string
        const url = window.location.hash.substring(1, window.location.hash.length - 1);

        // console.log("hash change", url, evt);

        if (this._currentHash === url)
        {
            return;
        }
        this._currentHash = url;

        const parsed = parseUrl(url);

        this.parseQueries(parsed.query);
        this.parseAddress(parsed.url);

        if (evt && evt.isTrusted)
        {
            this.navigate();
        }
    }

    public gameChanged(): void
    {
        if (se_platform.User.username === "NewUser")
        {
            earthpixi.setScreen(
                new LoadingScreen(UserScreen.assetList, UserScreen),
            );

            return;
        }

        if (this._navActive && this._game)
        {
            //console.log("game changed", this._game);

            this.loadCurrentGameType();
        }
    }

    public locationChanged(newLocation: string): void
    {
        console.log("location address changed: " + newLocation);
        // @ts-ignore
        if (earthpixi.currentScreen && earthpixi.currentScreen.locationChanged) {
            earthpixi.currentScreen.locationChanged(newLocation);
        }
        else
        if (this._navActive && this._game) {
            this.loadCurrentGameType();
        }
    }

    public modeChanged(newMode: string): void
    {
        console.log("mode address changed: " + newMode);
        sendMessage({action: 'modeChanged', mode: newMode})
        // @ts-ignore
        if (earthpixi.currentScreen && earthpixi.currentScreen.modeChanged)
        {
        // @ts-ignore
            earthpixi.currentScreen.modeChanged(newMode);
        }
        else
        if (this._navActive && this._game)
        {
            this.loadCurrentGameType();
        }
    }

    public queryChanged(data: object): void
    {
        // console.log("queries address changed: ", data);
        // @ts-ignore
        if (earthpixi.currentScreen && earthpixi.currentScreen.queryChanged)
        {
            // @ts-ignore
            earthpixi.currentScreen.queryChanged(data);
        }
    }

    public updateHash(saveHistory = true)
    {
        // console.log("update hash address", this.location, this.mode);
        // const oldHash = window.top.location.hash;
        const oldHash = window.location.hash;

        let str = "#";

        for (let i = 0; i < URLScheme.DATA_ORDER.length; i++)
        {
            switch (URLScheme.DATA_ORDER[i])
            {
                case URLAddressPart.GAME :
                    if (this._game !== "")
                    {
                        str += "/";
                        str += this._game;
                    }
                    break;

                case URLAddressPart.LOCATION :
                    if (this._location !== "")
                    {
                        str += "/";
                        str += this._location;
                    }
                    break;

                case URLAddressPart.MODE :
                    if (this._mode !== "")
                    {
                        str += "/";
                        str += this._mode;
                    }
                    break;
            }
        }

        const qString = stringify(this._queriesDict, {});

        if (qString.length > 0)
        {
            str += `?${qString}`;
        }

        // console.log("updating address", str, oldHash, this._queriesDict);

        if (oldHash === str) { return; }

        this._currentHash = str;

        if (saveHistory)
        {
            history.pushState(undefined, undefined, str);
        }
        else
        {
            history.replaceState(undefined, undefined, str);
        }

        // TODO still getting stuck using back button form MM game / not clearing queries
    }

    /**
     *
     */
    public clearQueries()
    {
        this._queriesDict = {};
        this.updateHash();
    }

    /**
     * Check on properties from the current query string.
     * If any are new or have changed handle this
     *
     * @private
     * @param queries
     */
    public parseQueries(queries: object): void
    {
        // blank object to fill and replace _queriesDict containing only the latest queries
        const currentQueries: any = {};

        for (const [key, entry] of Object.entries(queries))
        {
            // check if query is new,  or property exists already but has changed
            if (
                this._queriesDict[key] === undefined
                || (this._queriesDict[key] && this._queriesDict[key] !== entry)
            )
            {
                this.queryChanged({ key, entry });
            }

            currentQueries[key] = entry;
        }

        this._queriesDict = currentQueries;
    }

    /**
     * Parse address string according to order in URLScheme.DATA_ORDER
     * sets screen, location, mode properties
     * @private
     * @param url
     */
    public parseAddress(url: string): void
    {
        const addressParts = url.split("/").filter((str) => str.length > 0);

        for (let i = 0; i < URLScheme.DATA_ORDER.length; i++)
        {
            switch (URLScheme.DATA_ORDER[i])
            {
                case URLAddressPart.GAME :
                    if (this._game !== addressParts[i])
                    {
                        this._game = addressParts[i];
                        this.gameChanged();
                    }
                    break;

                case URLAddressPart.LOCATION :
                    if (this._location !== addressParts[i])
                    {
                        if (addressParts[i])
                        {
                            this._location = addressParts[i] as GameLocation;
                        }
                        else
                        {
                            this._location = se_platform.GameLocation.NONE;
                        }
                        this.locationChanged(this._location);
                    }
                    break;

                case URLAddressPart.MODE :
                    if (this._mode !== addressParts[i])
                    {
                        if (addressParts[i])
                        {
                            this._mode = addressParts[i] as se_platform.GameMode;
                        }
                        else
                        {
                            this._mode = se_platform.GameMode.NONE;
                        }
                        this.modeChanged(this._mode || "");
                    }
                    break;
            }
        }
    }
}
