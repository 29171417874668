import Text from "../config/text";

export default class MessageOverlay extends earthpixi.GameObject
{
    constructor(message, onOk, scope, darkMode = false, okText = "ok")
    {
        super();

        this.cover = earthpixi.utils.Draw.DrawRect({ x: 0, y: 0, width: 128, height: 128 }, 0xFFFFFFCC);
        this.cover.width = earthpixi.config.STAGE_WIDTH * 4;
        this.cover.x = -earthpixi.config.STAGE_WIDTH * 2;
        this.cover.height = earthpixi.config.STAGE_HEIGHT * 2;
        this.cover.y = -earthpixi.config.STAGE_HEIGHT / 2;
        this.cover.tint = darkMode ? 0xFFFFFF : 0x222133;
        this.cover.interactive = true;

        this.addChild(this.cover);

        this.goBackCheckText = new PIXI.extras.BitmapText(message, {
            font: "50px montserrat",
            tint: darkMode ? 0x222133 : 0xFFFFFF,
            align: "center"
        });

        this.goBackCheckText.maxWidth = 650;

        this.okButton = new earthpixi.Access.Button(
            PIXI.utils.TextureCache.game_button,
            PIXI.utils.TextureCache.game_button_on,
            okText, false, false, 10
        );

        this.okButton.addText(okText, 30, "montserrat");
        this.okButton.position.set(350, 430);
        this.okButton.addEvent("select", onOk, scope);

        earthpixi.utils.Display.position.center(this.goBackCheckText);
        this.goBackCheckText.y = 250;

        earthpixi.utils.Display.position.center(this.okButton);

        this.okButton.y += 220;

        this.addChild(this.goBackCheckText);
        this.addChild(this.okButton);

        // save previous solo'd buttons so can restore after confirmation
        if (earthpixi.Access.soloButtonArr) this.restoreSoloButtons = earthpixi.Access.soloButtonArr;

        earthpixi.Access.soloButtons(this);

        earthpixi.Access.settings.switchConfig.rowSelectorMode = false;
    }

    done()
    {
        if (this.restoreSoloButtons) earthpixi.Access.soloButtons(this.restoreSoloButtons);

        this.destroy({ children: true });
    }
}
